import React, { useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaUser, FaSignOutAlt } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { IoIosSettings } from "react-icons/io";
import { LogoutUser } from '../../utils/token-check';
import { useCookies } from 'react-cookie';
import CustomToaster from '../shared/customToaster';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

export default function Profile(){
    const [isOpen, setIsOpen] = useState(false);
    const [, , removeCookie] = useCookies(['agile_token', 'agile_user']);
    const user = useSelector(state => state.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        try{
            LogoutUser(navigate, dispatch, removeCookie);
            window.location.reload();
            CustomToaster(true, 'User Logout successfully');
        }catch(error){
            console.log(error);
            CustomToaster(false, 'An error occurred while logging out');
        }
    }

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return(
        <div ref={dropdownRef} className='relative'>
            <button id="dropdownDefaultButton" onClick={() => setIsOpen(!isOpen)} className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                {user ? user?.user?.username ? user?.user?.username : 'User' : 'User'} 
                <FaChevronDown className="text-xs ms-2" />
            </button>
            {isOpen && (
                <div id="dropdown" className="absolute top-10 z-50 bg-white divide-y divide-gray-100 rounded-lg shadow">
                    <ul className="py-2 text-md font-semibold text-gray-700 w-full">
                        <li className='px-2'>
                            <Link to="/account/myprofile" className="flex items-center px-4 py-1 hover:bg-primary hover:text-white rounded w-full">
                                <FaUser className='me-2' /> Profile
                            </Link>
                        </li>
                        <li className='px-2'>
                            <button className="flex items-center px-4 py-1 hover:bg-primary hover:text-white rounded w-full">
                                <FaMessage className='me-2' /> Message
                            </button>
                        </li>
                        <li className='px-2'>
                            <button className="flex items-center px-4 py-1 text-nowrap hover:bg-primary hover:text-white rounded w-full">
                                <IoIosSettings className='me-2 text-lg' /> Account Settings
                            </button>
                        </li>
                        <li className='px-2'>
                            <button type='button' onClick={handleLogout} className="flex items-center px-4 py-1 hover:bg-primary hover:text-white rounded w-full">
                                <FaSignOutAlt className='me-2' /> Sign Out
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}
