import React from 'react';
import { FaShoppingCart } from "react-icons/fa";
import { FaGift } from "react-icons/fa6";
import { IoBagHandle } from "react-icons/io5"; 
import { BsArrowRepeat } from "react-icons/bs";
import { IoShieldCheckmark } from "react-icons/io5";
import { RiCoupon2Fill } from "react-icons/ri";
import { IoMdKey } from "react-icons/io";
import { TbMenuDeep, TbTruckDelivery } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import { SiBattledotnet } from "react-icons/si";
import { FaLocationDot } from "react-icons/fa6";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { AiFillProduct } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import Logo from '../../assets/logo/logo-icon.png';

const menuItems = [
    {
        category: 'SERVICES',
        items: [
            { to: '/account/shop', icon: <FaShoppingCart />, label: 'Online Shop' },
            { to: '/account/transferPackages', icon: <IoBagHandle />, label: 'Transfer Products' },
            { to: '/account/transferCoupon', icon: <FaGift />, label: 'Transfer Coupon' },
            { to: '/account/retransferCoupon', icon: <BsArrowRepeat />, label: 'Re-Transfer Coupon' },
            { to: '/account/approveOrder', icon: <IoShieldCheckmark />, label: 'Approve Order' },
            { to: '/account/couponStatus', icon: <RiCoupon2Fill />, label: 'Show Coupon Status' },
            { to: '/account/checkpoints', icon: <IoMdKey />, label: 'Show Coupon Check Points' },
            { to: '/account/myorders', icon: <TbTruckDelivery />, label: 'My Orders' },
        ],
    },
    {
        category: 'USER/COMPANIES ADMINISTRATION',
        items: [
            { to: '/account/users', icon: <FaUser />, label: 'Users' },
            { to: '/account/companies', icon: <SiBattledotnet />, label: 'Companies' },
            { to: '/account/countries', icon: <FaLocationDot />, label: 'Countries' },
        ],
    },
    {
        category: 'PRODUCT ADMINISTRATION',
        items: [
            { to: '/account/units', icon: <SiHomeassistantcommunitystore />, label: 'Units' },
            { to: '/account/productcategories', icon: <BiSolidCategoryAlt />, label: 'Categories' },
            { to: '/account/products', icon: <AiFillProduct />, label: 'Products' },
        ],
    },
    {
        category: 'PACKAGE ADMINISTRATION',
        items: [
            { to: '/account/packages', icon: <FiPackage />, label: 'Packages' },
        ],
    },
    {
        category: 'ORDERS ADMINISTRATION',
        items: [
            { to: '/account/orders', icon: <TbTruckDelivery />, label: 'Orders' },
        ],
    },
];


const Menu = ({ url }) => {
    return (
        <ul className="space-y-2 text-sm font-medium">
            {menuItems.map((section) => (
                <React.Fragment key={section.category}>
                    <li>
                        <p className="ms-3 text-center text-xs text-gray-500">{section.category}</p>
                    </li>
                    {section.items.map((item) => (
                        <li key={item.to}>
                            <Link
                                to={item.to}
                                className={`${url === item.to ? 'bg-[#54a8a8] text-white' : 'text-gray-900'} flex items-center p-2 rounded hover:bg-[#54a8a8] group`}
                            >
                                <span className={`${url === item.to ? 'text-white' : 'text-gray-500 group-hover:text-gray-900'} w-5 h-5 transition duration-75`}>
                                    {item.icon}
                                </span>
                                <span className="ms-3">{item.label}</span>
                            </Link>
                        </li>
                    ))}
                </React.Fragment>
            ))}
        </ul>
    );
};


function ResponsiveSidebar({url, handleSidebar}) {
    return(
        <aside id="sidebar-multi-level-sidebar" className="bg-gray-50 fixed top-0 left-0 z-40 w-64 h-screen transition-transform translate-x-0">
            <div className="flex justify-between items-center px-2 h-14 shadow-md shadow-primary">
                <div className="flex items-center lg:space-x-2 space-x-1">
                    <img src={Logo} className="lg:w-14 w-10 h-full" alt="Logo" />
                    <h1 className="lg:text-2xl text-xl font-bold text-secondary uppercase">
                        XCRM PORTAL
                    </h1>
                </div>
                <button type="button" onClick={handleSidebar} className="lg:hidden block">
                    <TbMenuDeep className='text-primary text-3xl'/>
                </button>
            </div>
            <div className="px-3 py-4 h-[90vh] overflow-y-auto">
                <Menu url={url}/>
            </div>
        </aside>
    )
}

export default function Sidebar({sidebarOpen, handleSidebar}) {
    const url = useLocation().pathname;
    return(
        <>
            <div className="lg:hidden block">
                <div className={`${sidebarOpen ? 'block' : 'hidden'}`}>
                    <ResponsiveSidebar url={url} sidebarOpen={sidebarOpen} handleSidebar={handleSidebar} />
                </div>
            </div>
            <div className="lg:block hidden">
                <ResponsiveSidebar url={url} sidebarOpen={sidebarOpen} handleSidebar={handleSidebar} />
            </div>
        </>
    )
}
