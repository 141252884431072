import React from "react";
import Dropdown from './dropdown';
import { Link } from "react-router-dom";

export default function TopHeader() {
  return (
    <nav style={{backgroundColor: 'rgba(10, 34, 41, 9)'}} className="3xl:px-20 lg:px-10 md:px-10 xs:px-5 px-1 relative z-20">
        <div className="flex flex-wrap sm:justify-end justify-between items-center space-x-6 3xl:px-20 px-4 py-2">
          <Link to="/login" className='flex font-medium transition-all duration-200 delay-200 ease-in-out'>
            <span id="login-btn2" className="bg-[#f9f56e] w-6 h-6 -me-[19px] px-3"></span>
            <span id="login-btn" className="bg-[#f9f56e] px-3 uppercase font-bold">Log in</span>
          </Link>
          <Dropdown />
        </div>
    </nav>
  );
}
