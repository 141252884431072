import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { ImLocation } from "react-icons/im";
import { IoIosMail } from "react-icons/io";

export default function SubFooter() {
    return (
        <div className='lg:flex justify-between xl:px-40 lg:px-32 sm:px-20 xs:px-10 px-5 bg-[#69c0c9] py-5 -mt-2 z-50 relative font-[Myriad Pro] font-bold'>
            <div className='flex items-center lg:mb-0 mb-2'>
                <div className='xs:flex justify-between w-[100%] xs:space-y-0 space-y-2'>
                    <div className='lg:me-10 flex items-center'>
                        <ImLocation className='text-[#1e3d43] text-3xl me-2' />
                        <p className='text-white text-sm uppercase xs:block hidden'>
                            <span className='block'>fggasfaFSDUV 254</span>
                            <span>SHAsdUTD , GREECE</span>
                        </p>    
                        <p className='text-white text-sm uppercase xs:hidden block'>
                            <span className='block'>fggasfaFSDUV 254 SHAsdUTD , GREECE</span>
                        </p>    
                    </div>
                    <div className='flex items-start'>
                        <IoIosMail className='text-[#1e3d43] text-3xl me-2' />
                        <p className='text-white'>support@fitncult.com</p>    
                    </div>
                </div>
            </div>
            <div>
                <p className='text-white'>NewsLetter</p>
                <div className='xs:flex xs:space-y-0 space-y-2'>
                    <input type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm block w-full px-2.5 py-1" placeholder="" />
                    <button className='bg-[#fdf575] xs:ms-2 text-black px-5 w-full'>Subscribe</button>
                </div>
            </div>
        </div>
    )
}
