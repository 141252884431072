import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout';
import axios from 'axios';
import CustomToaster from '../../../components/shared/customToaster';
import { GetCookie } from '../../../utils/cookie';
import { useCookies } from 'react-cookie';
import TableLoader from '../../../components/shared/table-loader';
import { GetApi } from '../../../api/services';

export default function ShowCounponStatus() {
    const [couponDetail, setCouponDetail] = useState({});
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (!search) {
            setCouponDetail({});
            CustomToaster(false, 'Please enter the coupon!');
            return;
        }
        try {
            setLoading(true);
            console.log(search)
            const url = `/api/GetCouponStatus?couponCode=${search}&pageNumber=0&pageSize=10`;
            const res = await GetApi(token, url);
            if (Object.keys(res).length > 0) {
                setCouponDetail(res);
                CustomToaster(true, 'Coupon Detail get successfully');
            }else {
                CustomToaster(false, 'Failed to get coupon detail');
            }
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to get coupon detail');
        }
        finally{
            setLoading(false);
        }
    };


    return (
        <NestedLayout>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="space-y-4 md:space-y-2 px-5 py-4 bg-white">
                    <h1 className="mb-5 text-3xl font-bold text-center text-gray-800">
                        Packages
                    </h1>
                    {loading ? (
                        <TableLoader/>
                    ) : (
                        <>
                            <div className='grid grid-cols-12 gap-2'>
                                <div className='col-span-2'>
                                    <p className='text-lg text-right font-medium text-gray-600'>Coupon Code:</p>                                                 
                                </div>
                                <div className='col-span-4'>
                                <input type="text" value={search} onChange={(e => handleSearch(e))} className="block p-2 ps-3 text-sm text-gray-900 border border-gray-300 rounded w-80 bg-gray-50" />
                                </div>
                                <div className='col-span-6 flex justify-start'>
                                    <button onClick={handleSubmit} className="ms-5 text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-5 py-2" type="button">
                                        Saerch
                                    </button>                  
                                </div>
                            </div>
                            {Object.keys(couponDetail).length <= 0 ? null : (
                                <>
                                    <div className='grid grid-cols-12 gap-2'>
                                        <div className='col-span-2'>
                                            <p className='text-lg text-right font-medium text-gray-600'>Owner:</p>                                                 
                                        </div>
                                        <div className='col-span-6'>
                                            <p className='text-lg font-medium text-gray-600'>{couponDetail?.userFullName}</p>                                                 
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-2'>
                                        <div className='col-span-2'>
                                            <p className='text-lg text-right font-medium text-gray-600'>Activation Status:</p>                                                 
                                        </div>
                                        <div className='col-span-6'>
                                            <p className='text-lg text-left font-medium text-gray-600'>{couponDetail?.activationStatus}</p>                                                 
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </NestedLayout>
    )
}
