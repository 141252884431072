import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomToaster from '../shared/customToaster';

const INIT_CONTACT={
    name: '',
    email: '',
    message: '',
}

export default function ContactForm() {
    const [contact, setContact] = useState(INIT_CONTACT);

    const handleChange = (e) => {
        setContact({
            ...contact,
            [e.target.name]: e.target.value
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation
        const { name, email, message } = contact;

        if (!name || !email || !message) {
            CustomToaster(false, 'Please fill in all fields');
            return;
        }

        try {
            setContact(INIT_CONTACT);
            CustomToaster(true, 'Contact form submitted successfully');
        } catch (error) {
            console.error(error);
            CustomToaster(false, 'An error occurred, please try again');
        }
    };

    return (
        <form className="space-y-4 md:space-y-1" onSubmit={handleSubmit}>
            <div>
                <label htmlFor="name" className="block mb-1 text-sm text-primary dark:text-white">Name:</label>
                <input type="name" name="name" id="name" value={contact.name} onChange={handleChange} className="bg-gray-50 border border-primary text-gray-900 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
            </div>
            
            <div className="pt-2">
                <label htmlFor="email" className="block mb-1 text-sm text-primary dark:text-white">Email address:</label>
                <input type="email" name="email" id="email" value={contact.email} onChange={handleChange} className="bg-gray-50 border border-primary text-gray-900 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
            </div>

            <label for="message" class="block mb-2 text-sm text-primary">Your message</label>
            <textarea id="message" name="message" rows="7" value={contact.message} onChange={handleChange} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border-2 border-primary dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>

            <div>
                <button type="submit" style={{marginTop: '10px'}} className="w-full lg:w-[auto] uppercase font-bold text-white bg-btnColor hover:bg-warning hover:text-btnColor px-5 py-1 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                    Send Message
                </button>
            </div>
        </form>
    )
}
