import React, { useEffect, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

export default function TransferProductDropdown({ resaller, setResaller, setTransferPkg, ResellerData }) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef();
    
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const toggleDropdownOption = (value) => {
        const resellerItem = ResellerData.find((item) => item.name === value);
        setTransferPkg(prevState => ({
            ...prevState,
            userId: resellerItem.userId,
            packages: [{
                ...prevState.packages[0],
                userEmail: resellerItem.email
            }]
        }));
        setResaller(`${resellerItem.username} - ${resellerItem.email}`);
        setIsOpen(false);
        setSearchTerm("");
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    }

    const filteredItems = ResellerData.filter((item) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div ref={dropdownRef}>
            <button onClick={toggleDropdown} className="flex justify-between items-center w-full text-black border-b-2 border-gray-800 bg-transparent hover:bg-gray-50 font-medium text-sm py-2.5 text-center" type="button">
                <span>{resaller}</span> 
                <BiChevronDown/>
            </button>
            {isOpen && (
                <div id="dropdown" className="z-10 absolute bg-white divide-y divide-gray-100 rounded shadow md:w-60 w-full">
                    <input type="text" onInput={handleSearch} className="bg-gray-50 border-b border-gray-600 text-gray-900 text-sm rounded-ss rounded-se block w-full p-2.5" placeholder="Search" required />
                    <ul className="py-2 text-sm text-gray-700">
                        {filteredItems.length > 0 ? (
                            filteredItems.map((item, index) => (
                                <li key={index}>
                                    <button onClick={() => toggleDropdownOption(item.name)} className="block text-start w-full px-4 py-2 hover:bg-gray-100">{item.username} - {item.email}</button>
                                </li>
                            ))
                        ) : (
                            <li>
                                <p className="ps-2 w-full">No Result</p>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}