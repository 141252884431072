import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiNumber0, RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import CreatPackage from '../../../components/forms/package-form';
import CustomToaster from '../../../components/shared/customToaster';
import { GrDatabase } from "react-icons/gr";
import { FaTag } from "react-icons/fa";
import { Page } from '../../../utils/pagination';
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import TableLoader from '../../../components/shared/table-loader';
import { useNavigate } from 'react-router-dom';
import { GetApi, InsertApi, DeleteApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';
import { AreAllFieldsFilled } from '../../../utils/form-validation';

const INIT_PACKAGE = {
    packageID: 0,
    packageName: '',
    pictureFilename: '',
    isActive: true,
    creatorID: null,
    lastUpdaterID: null,
}

export default function Packages() {
    const [search, setSearch] = useState('');
    const [packages, setPackages] = useState(INIT_PACKAGE);
    const [packageList, setPackageList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);
    const navigate = useNavigate();
    
    const fetchPackages = async () => {
        try {
            setLoading(true);
            const url = `/api/GetPackages?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)) {
                setPackageList(res || []);
            } 
            await SmoothDelay();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching orders data: ", error);
            CustomToaster(false, "Error fetching orders data");
        }
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    useEffect(() => {
        if(packageList.length > 0) {
            const data = packageList.filter((data) => data.packageName.toLowerCase().includes(search.toLowerCase()));
            setFilteredData(data);
        }else{
            setFilteredData([]);
        }
    }, [search, packageList]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleForm = () => {
        if(isOpenForm){
            setPackages(INIT_PACKAGE);
        }
        setIsOpenForm(!isOpenForm);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPackages({
            ...packages,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        
        const validatePayload = {
            packageName: packages.packageName,
        }
        if (!AreAllFieldsFilled(validatePayload)) {
            CustomToaster(false, 'Package name is required');
            return;
        }

        if(isEditing && packages.packageID <= 0) {
            CustomToaster(false, 'Package ID is required');
            return;
        }

        try {
            setLoading(true);
            const url = `/api/SavePackage`;
            const res = await InsertApi(token, url, packages);
            console.log(res);
            if(Object.keys(res).length <= 0){
                CustomToaster(false, 'Failed to add/edit package');
                setLoading(false);
                return;
            }
            if (!isEditing) {
                CustomToaster(true, 'Package add successfully');
            } else {
                CustomToaster(true, 'Package edited successfully');
            }
            fetchPackages();
            setPackages(INIT_PACKAGE);
            setIsOpenForm(false);
            setIsEditing(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            CustomToaster(false, 'Failed to add/edit package');
        }
    };

    const handleEdit = (item) => {
        setPackages({
            packageID: item.packageID,
            packageName: item.packageName,
        });
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleDelete = async(id) => {
        try {
            setLoading(true);
            const res = await DeleteApi(token, 'api/DeletePackage', id);
            if(res.status == 200){
                CustomToaster(true, 'Package delete successfully');
            }
            fetchPackages();
            setLoading(false);            
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to delete package');
        }
    };

    const handleNavigation = (value, id) => {
        try{
            if(value){
                navigate(`/account/packagedtls/${id}`);
            }else {
                navigate(`/account/packagepriceoptions/${id}`);   
            }
        }catch(error){
            console.log(error);
        }
    }

    return (
        <NestedLayout>
            {isOpenForm && (
                <CreatPackage data={packages} isEditing={isEditing} handleModal={handleForm} handleSubmit={handleSubmit} handleChange={handleChange}/>
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        Packages
                    </h1>
                    <div className='flex items-center justify-between px-2'>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <FaSearch className='w-4 h-4 text-gray-500' />
                            </div>
                            <input type="text" onChange={(e => handleSearch(e))} className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50" placeholder="Search Here" />
                        </div>

                        <button onClick={handleForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                            ADD<FaPlus className='ms-2' />
                        </button>                  
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Products
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Options
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Edit
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td colSpan={5} className="w-full text-center px-6 py-5">
                                    <TableLoader />
                                </td>
                            </tr>
                        ) : (
                            filteredData.length <= 0 ? (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={5} className="w-full text-center px-6 py-4">
                                        No data to display
                                    </td>
                                </tr>
                            ) : (
                                filteredData.map((data, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-6 py-4">
                                            {data.packageName}
                                        </td>
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleNavigation(true, data.packageID)}>
                                                <GrDatabase />
                                            </button>
                                        </td>
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleNavigation(false, data.packageID)}>
                                                <FaTag />
                                            </button>
                                        </td>
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleEdit(data)}>
                                                <RiPencilFill />
                                            </button>
                                        </td> 
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleDelete(data.id)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={4} className="text-lg px-6 py-4">
                                {filteredData.length} Total
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    )
}
