import React from 'react';
import Slider from "react-slick";
import Layout from '../../layout/index';
import Img1 from '../../assets/images/Asset1.png';
import Img2 from '../../assets/images/Asset4.png';
import Img3 from '../../assets/images/Asset5.png';
import { Link } from 'react-router-dom';
import { ResponsiveDesc } from '../../components/common/description';

const data = [
    {
        img: Img1,
        title: 'Title 1',
        desc: 'Description 1'
    },
    {
        img: Img2,
        title: 'Title 2',
        desc: 'Description 2'
    },
    {
        img: Img3,
        title: 'Title 3',
        desc: 'Description 3'
    }
]

export default function Home() {
    var settings = {
        dots: false,
        lazyLoad: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
    };
    
    return (
        <Layout customClass1="3xl:h-[150vh] 2xl:h-[150vh] xl:h-[185vh] h-[140vh] 3xl:w-[600px] 2xl:w-[500px] xl:w-[350px] lg:w-[350px] w-[300px]" customClass2="3xl:h-[150vh] 2xl:h-[150vh] xl:h-[185vh] h-[140vh] 3xl:w-[625px] 2xl:w-[525px] xl:w-[375px] lg:w-[375px] w-[325px]" customClass3="3xl:h-[150vh] 2xl:h-[150vh] xl:h-[185vh] h-[140vh] 3xl:w-[650px] 2xl:w-[550px] xl:w-[400px] lg:w-[400px] w-[350px]">
            <div className="relative -z-20 overflow-hidden lg:-mt-28 -mt-1">
                <Slider {...settings}>
                    {data.map((item, index) => (
                        <div key={index}>
                            <img src={item.img} alt={item.title} className="w-full 3xl:h-[150vh] 2xl:h-[150vh] xl:h-[160vh] lg:h-[130vh] md:h-[100vh] sm:h-[80vh] h-[55vh]" />
                        </div>
                    ))}
                </Slider>
            </div>
            {/* bg-[#fdf085] opacity-80 */}
            <div id="home-findGym-div" className='absolute flex items-center xl:top-[60%] lg:top-[40%] md:top-[50%] sm:top-[40%] top-[30%] z-40 2xl:w-[800px] 2xl:h-[400px] xl:w-[650px] xl:h-[300px] lg:w-[500px] lg:h-[250px] md:w-[450px] md:h-[200px] sm:w-[300px] sm:h-[170px] xs:w-[250px] xs:h-[150px] w-[180px] h-[140px]' style={{backgroundColor: 'rgba(253, 240, 133, 0.8)'}}>
                <div className='mx-auto 2xl:w-[500px] xl:w-96 lg:w-[300px] md:w-72 sm:w-60 xs:w-40 w-full xs:ps-0 ps-3'>
                    <h2 className='text-white 2xl:text-5xl xl:text-4xl md:text-3xl sm:text-xl xs:text-lg text-md font-bold opacity-100'>Train Your Body</h2>
                    <h2 className='2xl:text-6xl xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-xl xl:font-extrabold font-bold mt-2 uppercase'>Find the Gym Near You</h2>
                    <Link to="/map">
                        <button className='custom-btns flex items-center mt-3 2xl:py-2 2xl:text-lg sm:text-md text-sm bg-primary text-white font-bold px-4 uppercase'>
                            Go To Map 
                            <span className='ms-2 font-normal'>&#10095;&#10095;</span>
                        </button>
                    </Link>
                </div>
            </div>
            <div className='absolute xl:top-[70%] lg:top-[42%] md:top-[50%] sm:top-[40%] top-[30%] right-0 z-40 2xl:me-[300px] xl:me-[15%] lg:me-[10%] md:me-[0%] sm:me-[10%] xs:me-[5%] me-[3%] xl:w-96 md:w-80 sm:w-40 xs:w-36 w-[130px]'>
                <h2 className='text-white xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl xs:text-2xl text-xl 2xl:font-extrabold font-bold mt-5'>Get 24h <br className='2xl:hidden xl:block xs:hidden block' /> FREE TRIAL</h2>
                <Link to="/map">
                    <button className='custom-btns flex items-center mt-3 2xl:py-2 2xl:text-lg sm:text-md text-sm bg-primary text-white xs:font-bold font-medium px-4 uppercase'>
                        Join Now 
                        <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                    </button>
                </Link>
            </div>
            <div className='-mt-4'>
                <ResponsiveDesc/>
            </div>
        </Layout>
    )
}
