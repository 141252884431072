import React, { useState } from 'react'
import Layout from '../../layout/index'
import { CustomDescription, ResponsiveDesc } from '../../components/common/description'
import { FaChevronRight } from "react-icons/fa6";
import { GiCrossMark } from "react-icons/gi";
import { Link } from 'react-router-dom';

export default function Products() {
    const [isOpenDetail, setIsOpenDetail] = useState(0);
    const [listDetailHeading, setListDetialHeading] = useState("");
    const [listDetailMargin, setListDetialMargin] = useState("top-96 right-40");

    const handleOpenDetail = (buttonNo) => {
        setIsOpenDetail(buttonNo);
        switch (buttonNo) {
            case 1:
                setListDetialMargin("2xl:top-64 xl:top-80 lg:top-80 md:top-80 top-0 3xl:right-[430px] 2xl:right-60 xl:right-0 lg:right-0");
                setListDetialHeading("Πρόσβαση στο κλασικό γυμναστήριο.");
                break;
            case 2:
                setListDetialMargin("2xl:top-[350px] xl:top-[400px] lg:top-96 md:top-80 top-0 3xl:right-[430px] 2xl:right-60 xl:right-0 lg:right-0");
                setListDetialHeading("25% Έκπτωση στις υπόλοιπες παροχές.");
                break;
            case 3:
                setListDetialMargin("2xl:top-[420px] lg:top-[430px] md:top-80 top-0 3xl:right-[430px] 2xl:right-60 xl:right-0 lg:right-0");
                setListDetialHeading("Ειδικές τιμές σε πολιτιστικά μέρη.");
                break;
            case 4:
                setListDetialMargin("xl:top-72 lg:top-[200px] md:top-0 top-10 3xl:right-40 2xl:right-0 xl:right-80 lg:right-0");
                setListDetialHeading("Πρόσβαση στο κλασικό γυμναστήριο.");
                break;
            case 5:
                setListDetialMargin("xl:top-96 lg:top-[220px] md:top-0 top-20 3xl:right-40 2xl:right-0 xl:right-80 lg:right-0");
                setListDetialHeading("25% Έκπτωση στις υπόλοιπες παροχές.");
                break;
            case 6:
                setListDetialMargin("xl:top-[420px] lg:top-[250px] md:top-0 top-20 3xl:right-40 2xl:right-0 xl:right-80 lg:right-0");
                setListDetialHeading("Ειδικές τιμές σε πολιτιστικά μέρη.");
                break;
            default:
                setListDetialMargin("top-96 right-32 2xl:right-0");
                break;
        }
    }

    const handleCloseDetail = () => {
        setIsOpenDetail(0);
    }

    const customHeight = "2xl:h-[160vh] xl:h-[200vh] h-[180vh]";
    const customLeftPadding = "3xl:pl-12 2xl:pl-28 xl:pl-8 lg:pl-5 pl-20";
    return (
        <Layout customClass1={`${customHeight} 3xl:w-[600px] 2xl:w-[500px] xl:w-[350px] lg:w-[350px] w-[300px]`} customClass2={`${customHeight} 3xl:w-[625px] 2xl:w-[525px] xl:w-[375px] lg:w-[375px] w-[325px]`} customClass3={`${customHeight} 3xl:w-[650px] 2xl:w-[550px] xl:w-[400px] lg:w-[400px] w-[350px]`}>
            <section id="product-bg-img" className=" bg-cover bg-center 2xl:h-[140vh] xl:h-[180vh] lg:min-h-screen h-full pb-20 relative -mt-16 flex lg:flex-row xl:justify-center lg:justify-end justify-center py-8 xl:pt-60 lg:pt-40 pt-20">
                <div className="container px-5 relative lg:z-20">
                    <div class="flex 2xl:justify-center xl:justify-end justify-center">
                        <div className='xl:w-[700px] xl:flex w-full xl:space-x-4 space-y-4'>
                            <div className='xl:w-[340px] lg:w-[400px] mx-auto lg:space-x-4'>
                                <hr className='h-[80px] -mb-10 bg-warning opacity-90' id="custom-product-header-style" />
                                <div className="bg-white p-6 space-y-2 md:space-y-3 sm:p-6" id="custom-product-card-style">
                                    <h1 className="md:text-5xl font-bold text-xl leading-tight tracking-tight text-primary">
                                        πακετο 15 ημερων
                                    </h1>
                                    <h1 className="md:text-6xl text-xl font-semibold uppercase leading-tight tracking-tight text-black">
                                        € <span className='font-bold'>10,00</span>
                                    </h1>
                                    <div className='space-y-1'>
                                    <button type='button' onClick={() => handleOpenDetail(1)} className={`${isOpenDetail == 1 && "font-bold"} flex items-start text-start text-lg text-primary`}>
                                            <FaChevronRight className='mt-1 me-2'/>
                                            Πρόσβαση στο κλασικό γυμναστήριο.
                                        </button>
                                        <button type='button' onClick={() => handleOpenDetail(2)} className={`${isOpenDetail == 2 && "font-bold"} flex items-start text-start text-lg text-primary`}>
                                            <FaChevronRight className='text-black mt-1 me-2'/>
                                            25% Έκπτωση στις υπόλοιπες παροχές.
                                        </button>
                                        <button type='button' onClick={() => handleOpenDetail(3)} className={`${isOpenDetail == 3 && "font-bold"} flex items-start text-start text-lg text-primary`}>
                                            <FaChevronRight className='mt-1 me-2'/>
                                            Ειδικές τιμές σε πολιτιστικά μέρη.
                                        </button>
                                    </div>
                                    <Link to="/register">
                                        <button className='custom-btns mt-2 bg-black text-white px-4 uppercase'>
                                            Sign Up 
                                            <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                                        </button>
                                    </Link>
                                </div>
                                <hr className='h-[10px] bg-warning opacity-90 ' />
                            </div>
                            <div className='xl:w-[340px] lg:w-[400px] mx-auto lg:space-x-4'>
                                <hr className='h-[80px] -mb-10 bg-warning opacity-90' id="custom-product-header-style" />
                                <div className="bg-white p-4 space-y-2 md:space-y-3 sm:p-6" id="custom-product-card-style">
                                    <h1 className="md:text-5xl font-bold text-xl leading-tight tracking-tight text-primary">
                                        πακετο 15 ημερων
                                    </h1>
                                    <h1 className="mt-2 md:text-6xl text-xl font-semibold uppercase leading-tight tracking-tight text-black">
                                        € <span className='font-bold'>10,00</span>
                                    </h1>
                                    <div className='space-y-2'>
                                        <button type='button' onClick={() => handleOpenDetail(4)} className={`${isOpenDetail == 4 && "font-bold"} flex items-start text-start text-lg text-primary`}>
                                            <FaChevronRight className='mt-1 me-2'/>
                                            Πρόσβαση στο κλασικό γυμναστήριο.
                                        </button>
                                        <button type='button' onClick={() => handleOpenDetail(5)} className={`${isOpenDetail == 5 && "font-bold"} flex items-start text-start text-lg text-primary`}>
                                            <FaChevronRight className='text-black mt-1 me-2'/>
                                            25% Έκπτωση στις υπόλοιπες παροχές.
                                        </button>
                                        <button type='button' onClick={() => handleOpenDetail(6)} className={`${isOpenDetail == 6 && "font-bold"} flex items-start text-start text-lg text-primary`}>
                                            <FaChevronRight className='mt-1 me-2'/>
                                            Ειδικές τιμές σε πολιτιστικά μέρη.
                                        </button>
                                    </div>
                                    <Link to="/register">
                                        <button className='custom-btns mt-2 bg-black text-white px-4 uppercase'>
                                            Sign Up 
                                            <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                                        </button>
                                    </Link>
                                </div>
                                <hr className='h-[10px] bg-warning opacity-90 ' />
                            </div>
                            {isOpenDetail != 0 && (
                                <div className={`absolute ${listDetailMargin} z-50 bg-white lg:bg-opacity-50 bg-opacity-60 xl:p-8 lg:p-5 p-4 2xl:w-[650px] xl:w-[700px] lg:w-[500px] rounded-3xl`}>
                                    <div id="product-detiail-div" className='bg-black bg-opacity-90 text-white px-7 pb-7'>
                                        <div className='flex justify-end pt-5'>
                                            <button type='button' className='bg-transparent' onClick={handleCloseDetail}>
                                                <GiCrossMark className='text-2xl'/>
                                            </button>
                                        </div>
                                        <h1 className='text-[22pt] font-bold'>
                                            {listDetailHeading}
                                        </h1>
                                        <p className='text-[17pt] h-[310px] overflow-auto'>
                                            Με το πακέτο των 15 ημερών του FITnCULT  δικαιούσε
                                            να γυμναστείς για το συγκεκριμένο χρονικό διάστημα
                                            σε όλους τους χώρους του κλασικού γυμναστηρίου που
                                            αφοριύν όργανα και ομαδικά προγράμματα.
                                            Με το πακέτο των 15 ημερών του FITnCULT  δικαιούσε
                                            να γυμναστείς για το συγκεκριμένο χρονικό διάστημα
                                            σε όλους τους χώρους του κλασικού γυμναστηρίου που
                                            αφοριύν όργανα και ομαδικά προγράμματα.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <ResponsiveDesc/>
            <CustomDescription section1Class={`3xl:-mt-[280%] 2xl:-mt-[100%] xl:-mt-[200%] lg:-mt-[160%] ${customLeftPadding}`} section2Class={`3xl:w-[230px] 2xl:w-[350px] lg:w-[250px] w-[200px] 3xl:-mt-[200%] 2xl:-mt-[150%] xl:-mt-[200%] lg:-mt-[240%] ${customLeftPadding}`} />
            {/* <CustomDescription section1Class={`3xl:-mt-[500px] 2xl:-mt-[600px] xl:-mt-[350px] lg:-mt-[400px] ${customLeftPadding}`} section2Class={`3xl:w-[230px] 2xl:w-[350px] lg:w-[250px] w-[200px] 3xl:-mt-[800px] 2xl:-mt-[700px] xl:-mt-[600px] lg:-mt-[700px] ${customLeftPadding}`} /> */}
        </Layout>
    )
}
