import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import CreateCountry from '../../../components/forms/country-form';
import CustomToaster from '../../../components/shared/customToaster';
import { GetCookie } from '../../../utils/cookie';
import { useCookies } from 'react-cookie';
import { Page } from '../../../utils/pagination';
import axios from 'axios';
import TableLoader from '../../../components/shared/table-loader';
import isEmail from 'validator/lib/isEmail';
import { AreAllFieldsFilled } from '../../../utils/form-validation';
import { GetApi, InsertApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';

const tableData = []

const INIT_COUPON = {
  userEmail: '',
  couponID: null,
  quantity: 1,
  packageID: 1,
  packagePriceOptionID: 1
}

export default function ReTransferCoupon() {
    const [couponList, setCouponList] = useState([]);
    const [reTransferCoupon, setReTransferCoupon] = useState(INIT_COUPON);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);
    
    const fetchCoupons = async () => {
      try {
          setLoading(true);
          const url = `/api/GetCouponUncompletedTransfers?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
          const res = await GetApi(token, url);
          const resData = res?.data;
          if(Array.isArray(resData)){
            setCouponList(resData);
          } else{
              CustomToaster(false, "Error fetching coupon data");
          }
      } catch (error) {
          CustomToaster(false, "Error fetching coupon data");
      }
      finally{
          await SmoothDelay();
          setLoading(false);
      }
    };

    useEffect(() => {
        fetchCoupons();
    }, [token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReTransferCoupon({
            ...reTransferCoupon,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const existCoupon = selectedCoupons[selectedCoupons.length - 1];
  
      if (!existCoupon) {
          console.log(selectedCoupons);
          CustomToaster(false, 'No coupon selected');
          return;
      }
      if(!isEmail(reTransferCoupon?.userEmail)){
          CustomToaster(false, 'Please enter valid email');
          return
      }

      const payload = {
          packageID: existCoupon.packageID,
          packagePriceOptionID: existCoupon.packagePriceOptionID,
          couponID: existCoupon.couponId,
          quantity: 1,
          userEmail: reTransferCoupon.userEmail,
      };
  
      if (!payload.userEmail || payload.couponID == null) {
          console.log(payload)
          CustomToaster(false, 'Please again select coupon to transfer');
          return;
      }      
      try {
          setLoading(true);
          const url = `/api/TransferCoupon`;
          const res = await InsertApi(token, url, payload);
          console.log(res);
          if (res > 0) {
            CustomToaster(true, 'Re-transfer coupon successfully');
            setReTransferCoupon(INIT_COUPON);
            fetchCoupons(); 
            setSelectedRows([]);
            setSelectedCoupons([]);
          } else {
            CustomToaster(false, 'Failed to re-transfer coupon');
          }
      } catch (error) {
          CustomToaster(false, 'Failed to re-transfer coupon!');
      } finally {
          setLoading(false);
      }
  };
    

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedRows(couponList.map((_, index) => index));
            setSelectedCoupons(couponList);
            console.log(couponList);
          } else {
            setSelectedRows([]);
            setSelectedCoupons([]);
        }
    };

    const handleSelectRow = (index) => {
      let updatedSelectedRows;
      if (selectedRows.includes(index)) {
        // Remove if already selected
        updatedSelectedRows = selectedRows.filter(row => row !== index);
      } else {
        updatedSelectedRows = [...selectedRows, index];
      }
    
      setSelectedRows(updatedSelectedRows);    
      const updatedSelectedCoupons = updatedSelectedRows.map(rowIndex => couponList[rowIndex]);      
      setSelectedCoupons(updatedSelectedCoupons);
    };

    return (
        <NestedLayout>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white">
                  <h1 className="text-3xl font-bold text-center text-gray-800">
                    Coupons transfered (pending)
                  </h1>
              </div>
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                          <th scope="col" className="p-4">
                              <div className="flex items-center">
                                  <input id="checkbox-all-search" onChange={handleSelectAll} checked={couponList.length > 0 && selectedRows.length === couponList.length} type="checkbox" className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:outline-none"/>
                                  <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                              </div>
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Created By
                          </th>
                          <th scope="col" className="w-28 px-2 py-3">
                            Creation Date
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Coupon Code
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Send To Email
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      {loading ? (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <td colSpan={4} className="text-lg w-full px-6 py-4">
                              <TableLoader/>
                          </td>
                      </tr>
                      ) : (
                        couponList.length <= 0 ? (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td colSpan={4} className="w-full text-center px-6 py-4">
                                    No data to display
                                </td>
                            </tr>
                        ) : (
                          couponList.map((data, index) => (
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="w-4 p-4">
                                    <div className="flex items-center">
                                        <input id="checkbox-table-search-1" checked={selectedRows.includes(index)} onChange={() => handleSelectRow(index)} type="checkbox" className="w-4 h-4 text-primary bg-gray-100 border-gray-300 fill-primary rounded focus:outline-none "/>
                                        <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    {data.createdBy}
                                </td>
                                <td className="px-6 py-4">
                                    {data.dateCreated.split('T')[0]}
                                </td>
                                <td className="px-6 py-4">
                                    {data.couponCode}
                                </td>
                                <td className="px-6 py-4">
                                    {data.emailTo}
                                </td>
                            </tr>
                          ))
                        )
                      )}
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <td colSpan={4} className="text-lg px-6 py-4">
                              {selectedRows.length} Selected / {couponList.length} Total
                          </td>
                      </tr>
                  </tbody>
              </table>
          
              <form action="" onSubmit={handleSubmit} className='p-10'>
                <div className='grid grid-cols-12'>
                  <div className='col-span-2 flex items-center'>
                    <h1 className='text-xl text-gray-500'>Transfer to:</h1>
                  </div>
                  <div className='col-span-10'>
                    <input type="email" name="userEmail" value={reTransferCoupon.userEmail} onChange={(e => handleChange(e))} className="block border-b-2 border-gray-600 w-full p-2 text-sm text-gray-900 focus:outline-none placeholder:text-black placeholder:text-lg" placeholder="Email" />
                  </div>
                </div>
                <button type='submit' className='uppercase bg-pink px-5 py-2 block mx-auto text-white mt-5 rounded font-semibold'>
                  Transfer
                </button>
              </form>
          </div>
        </NestedLayout>
    )
}
