import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import CreateCountry from '../../../components/forms/country-form';
import CustomToaster from '../../../components/shared/customToaster';
import { GetCookie } from '../../../utils/cookie';
import { useCookies } from 'react-cookie';
import { Page } from '../../../utils/pagination';
import TableLoader from '../../../components/shared/table-loader';
import { useSelector } from 'react-redux';
import { AreAllFieldsFilled, areAllFieldsFilled } from '../../../utils/form-validation';
import { SmoothDelay } from '../../../components/shared/delay';
import { GetApi, InsertApi, DeleteApi } from '../../../api/services';

const INIT_COUNTRY = {
    countryID: 0,
    description: "",
    isActive: true,
    dateCreated: "",
    dateUpdated: "",
    creatorID: 0,
    lastUpdaterID: 0
}

export default function Country() {
    const [country, setCountry] = useState(INIT_COUNTRY);
    const [countryList, setCountryList] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);
    const currentUser = useSelector(state => state.user);

    const fetchCountries = async () => {
        try {
            const url = `Countries/api/GetCountries?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const resData = await GetApi(token, url);
            if(Array.isArray(resData)){
                setCountryList(resData);
            } else {
                CustomToaster(false, "Error fetching country data");
            }
        } catch (error) {
            CustomToaster(false, "Error fetching country data");
        }
    };

    useEffect(() => {
        const handleFetch = async () => {
            setLoading(true);
            await fetchCountries();
            await SmoothDelay(500);
            setLoading(false)
        }

        handleFetch();
    }, []);
    
    useEffect(() => {
        const data = countryList.filter((data) =>
            data.description.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(data);
    }, [search, countryList]);


    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleForm = () => {
        setIsOpenForm(!isOpenForm);
        setIsEditing(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCountry({
            ...country,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try{
            setLoading(true);
            const payload ={
                ...country,
                creatorID: currentUser?.user?.userId,
                dateCreated: new Date().toISOString(),
                dateUpdated: new Date().toISOString(),
            }

            if (!AreAllFieldsFilled(payload)) {
                CustomToaster(false, 'Please fill in all fields');
                setLoading(false);
                return;
            }

            const url = `/Countries/api/SaveCountry`;
            const res = await InsertApi(token, url, payload);
            if(res.status == 200){
                if (!isEditing) {
                    CustomToaster(true, 'Country added successfully');
                } else {
                    CustomToaster(true, 'Country edited successfully');
                }
            }
            fetchCountries();
            setIsEditing(false);
            setCountry(INIT_COUNTRY);
            setIsOpenForm(false);
            setLoading(false);
        }catch(error){
            setLoading(false);
            console.log(error);
            CustomToaster('error', 'Error', 'Failed to add country');
        }
    }

    const handleEdit = (item) => {
        setCountry({
            ...country,
            countryID: item.countryID,
            description: item.description,
        });
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedRows(filteredData.map((_, index) => index));
        } else {
            setSelectedRows([]);
        }
    };

    const handleSelectRow = (index) => {
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter(row => row !== index));
        } else {
            setSelectedRows([...selectedRows, index]);
        }
    };

    const handleDelete = async(id) => {
        try {
            setLoading(true);
            const res = await DeleteApi(token, 'Countries/api/DeleteCountry', id);
            if(res?.data == 1){
                fetchCountries();
                CustomToaster(true, 'Country deleted successfully');
            }else {
                CustomToaster(false, 'Failed to delete country');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            CustomToaster(false, 'Failed to delete country');
        }
    };

    const handleDefaultCheckbox = (data) => {
        data.isActive = true;
    }
    return (
        <NestedLayout>
            {isOpenForm && (
                <CreateCountry country={country} isEditing={isEditing} handleModal={handleForm} handleSubmit={handleSubmit} handleChange={handleChange}/>
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        Country
                    </h1>
                    <div className='flex items-center justify-between px-2'>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <FaSearch className='w-4 h-4 text-gray-500' />
                            </div>
                            <input type="text" onChange={(e => handleSearch(e))} className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded w-80 bg-gray-50" placeholder="Search Here" />
                        </div>

                        <button onClick={handleForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                            ADD<FaPlus className='ms-2' />
                        </button>                  
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" onChange={handleSelectAll} checked={filteredData.length > 0 && selectedRows.length === filteredData.length} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Description
                            </th>
                            <th scope="col" className="w-28 px-2 py-3">
                                Is Active
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Edit
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr className="bg-white border-b hover:bg-gray-50">
                                <td colSpan={5} className="px-6 py-4">
                                    <TableLoader/>
                                </td>
                            </tr>
                        ) : (
                            filteredData.length <= 0 ? (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={4} className="w-full text-center px-6 py-4">
                                        No data to display
                                    </td>
                                </tr>
                            ) : (
                                filteredData.map((data, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="w-4 p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-table-search-1" checked={selectedRows.includes(index)} onChange={() => handleSelectRow(index)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.description}
                                        </td>
                                        <td className="w-4 p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-table-search-1" type="checkbox" checked={data.isActive} onChange={() => handleDefaultCheckbox(data)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleEdit(data)}>
                                                <RiPencilFill />
                                            </button>
                                        </td> 
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleDelete(data.id)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={4} className="text-lg px-6 py-4">
                                {selectedRows.length} Selected / {countryList.length} Total
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    )
}
