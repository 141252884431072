import React, { useEffect, useState } from 'react';
import { GiCrossMark } from "react-icons/gi";
import { APIProvider, Map } from '@vis.gl/react-google-maps';

const INIT_LOCATION = {
    lat: '',
    lng: ''
}

export default function CreateCompany({
    categoryList,
    company,
    isEditing,
    setCompany,
    handleModal,
    handleCompanyChange,
    handleSubmit,
}) {
    const [isCatOpen, setIsCatOpen] = useState(false);
    const [catName, setCatName] = useState('');
    useEffect(() => {
        const checkGoogleMaps = setInterval(() => {
            if (window.google && window.google.maps) {
                clearInterval(checkGoogleMaps);
            }
        }, 100);

        return () => clearInterval(checkGoogleMaps);
    }, []);

    const handleLocation = (e) => {
        const location = e.detail.latLng;
        setCompany({
            ...company,
            lat: location.lat,
            lng: location.lng
        });
    };

    // const getLatLng = () => {
    //     const geocoder = new window.google.maps.Geocoder();
    //     if(!company.address || !company.city || !company.postalCode) {
    //         CustomToaster(false, 'Please fill in the address, city and postal code');
    //         return;
    //     }
    //     const fullAddress = `${company.address}, ${company.city}, ${company.postalCode}`;
    //     console.log('fullAddress', fullAddress);
    //     geocoder.geocode({ address: fullAddress }, (results, status) => {
    //         if (status === 'OK') {
    //             const location = results[0].geometry.location;
    //             console.log('location', location);
    //             setCompany({
    //                 ...company,
    //                 lat: location.lat(),
    //                 lng: location.lng()
    //             });
    //         } else {
    //             CustomToaster(false, 'Geocode was not successful for the following reason: ' + status);
    //         }
    //     });
    // };

    useEffect(() => {  
        if(company.productCategoryID != 0){
            const category = categoryList.find(item => item.productCategoryID == company.productCategoryID);
            setCatName(category.description);
        }
    }, [company.productCategoryID]);

    const handleCategoryChange = (item) => {
        setCompany({
            ...company,
            productCategoryID: item.productCategoryID
        });
        setCatName(item.description);
        setIsCatOpen(false);
    }
    return (
        <div
            style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
            className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center"
        >
            <div className="relative p-4 w-full max-w-4xl max-h-full">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow">
                    {/* <!-- Modal header --> */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add New Company
                        </h3>
                        <button
                            type="button"
                            onClick={handleModal}
                            className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="default-modal"
                        >
                            <GiCrossMark className="text-xl" />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="w-full mx-auto" onSubmit={handleSubmit}>
                        {/* <!-- Modal body --> */}
                        <div className="grid grid-cols-12">
                            <div className="col-span-5 p-4 md:p-5 space-y-4 overflow-y-auto max-h-[400px]">
                                <div className="mb-3">
                                    <label
                                        htmlFor="companyName"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        Company Name
                                    </label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        id="companyName"
                                        value={company.companyName}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary   text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="contactName"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        Contact Name
                                    </label>
                                    <input
                                        type="text"
                                        name="contactName"
                                        id="contactName"
                                        value={company.contactName}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="address"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        name="address"
                                        id="address"
                                        value={company.address}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="city"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        value={company.city}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="postalCode"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        PostalCode
                                    </label>
                                    <input
                                        type="text"
                                        name="postalCode"
                                        id="postalCode"
                                        value={company.postalCode}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="country"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        Country
                                    </label>
                                    <input
                                        type="text"
                                        name="country"
                                        id="country"
                                        value={company.country}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="phone"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        Phone
                                    </label>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        value={company.phone}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="fax"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        Fax
                                    </label>
                                    <input
                                        type="text"
                                        name="fax"
                                        id="fax"
                                        value={company.fax}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="homePage"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        HomePage
                                    </label>
                                    <input
                                        type="text"
                                        name="homePage"
                                        id="homePage"
                                        value={company.homePage}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="email"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={company.email}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>

                                <div className="mb-3 relative">
                                    <button onClick={() => setIsCatOpen(!isCatOpen)} className="w-full border border-primary focus:outline-none font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                                        {catName != '' ? catName : 'Select Category'}
                                    </button>

                                    <div id="dropdown" className={`${!isCatOpen && 'hidden'} absolute bottom-12 z-10 bg-white divide-y divide-gray-100 rounded shadow w-full`}>
                                        <ul className="py-2 text-sm text-gray-700">
                                            {categoryList.map((item, index) => (
                                                <li key={index}>
                                                    <button onClick={() => handleCategoryChange(item)} type='button' className="block w-full text-start px-4 py-2 hover:bg-gray-100">
                                                        {item?.description}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-7 p-4 space-y-4 overflow-y-auto max-h-[400px]">
                                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                                    <Map
                                        className="h-80 w-full"
                                        defaultCenter={isEditing ? { lat: company.lat, lng: company.lng } : { lat: 30, lng: 70 }}
                                        defaultZoom={5}
                                        gestureHandling={"greedy"}
                                        disableDefaultUI={true}
                                        options={{
                                            mapTypeId: "roadmap",
                                            styles: [],
                                        }}
                                        onClick={handleLocation}
                                    >
                                    </Map>
                                </APIProvider>
                                <div className="mb-3">
                                    <label
                                        htmlFor="companyName"
                                        className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                        Lat
                                    </label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        id="companyName"
                                        value={company.lat}
                                        onChange={(e) => handleCompanyChange(e)}
                                        className="bg-white border border-primary   text-gray-900 text-sm rounded block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                <label
                                    htmlFor="contactName"
                                    className="block mb-2 text-sm font-medium text-gray-900"
                                >
                                    Lng
                                </label>
                                <input
                                    type="text"
                                    name="contactName"
                                    id="contactName"
                                    value={company.lng}
                                    onChange={(e) => handleCompanyChange(e)}
                                    className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"
                                    placeholder=""
                                    required
                                />
                                </div>
                            </div>
                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
                            <button
                                type="button"
                                onClick={handleModal}
                                className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-red-500 rounded-lg border border-gray-200 hover:bg-red-600"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="text-white bg-primary uppercase hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                {isEditing ? 'Update' : 'ADD'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
