import React, { useEffect, useRef, useState } from 'react'
import { GiCrossMark } from "react-icons/gi";


export default function CreatePackagePriceOpion({unit, setUnit, data, isEditing, handleModal, handleChange, handleSubmit}) {
    const handleCheckboxChange = (e) => {
        setUnit({
            ...unit,
            isDefault: e.target.checked
        })
    };
    const handleUnit = (e) => {
        const Id = e.target.value;
        const item = data.find((item) => item.unitID === Number(Id));
        setUnit({ ...unit, unitDescription: item.description, unitId: item.unitID });
    };
    return (
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow">
                    {/* <!-- Modal header --> */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                          Add New Product Category
                        </h3>
                        <button type="button" onClick={handleModal} className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                            <GiCrossMark className='text-xl' />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="px-4 pt-4 md:px-5 md:pt-5 space-y-4">
                        <form className="w-full mx-auto" onSubmit={handleSubmit}>
                            <div className="flex items-center">
                                <input id="isDefault" type="checkbox" checked={unit.isDefault} onChange={handleCheckboxChange} className="w-4 h-4 text-primary bg-gray-100 rounded border-gray-300 focus:outline-none"/>
                                <label htmlFor="isDefault" className="text-sm cursor-pointer text-gray-600 font-medium ms-2">Is Default?</label>
                            </div>
                            <div className='my-4'>
                            <input type="number" id="price" name='price' value={unit.price} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:outline-none block w-full p-2.5" placeholder="Price" required />
                            </div>
                            <div className="block w-full">
                                <select id="unit" onChange={(e) => handleUnit(e)} className="h-10 border border-gray-300 text-gray-600 text-base rounded block w-full py-2 px-4 focus:outline-none">
                                    {data.map((item, index) => (
                                        <option key={index} value={item.unitID}>{item.description}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
                                <button type="button" onClick={handleModal} className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-red-500 rounded-lg border border-gray-200 hover:bg-red-600">Cancel</button>
                                <button type="submit" className="text-white bg-primary uppercase hover:bg-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                    {isEditing ? 'Update' : 'ADD'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
