import React, { useState } from 'react';
import { FaFacebookF, FaGooglePlusG } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import CustomToaster from '../shared/customToaster';
import axios from 'axios';
import { isEmail } from 'validator';
import { useDispatch } from 'react-redux';
import { addUser } from '../../store/user-slice';
import { GetCookie, StoreCookie } from '../../utils/cookie';
import { useCookies } from 'react-cookie';

const INIT_USER_DATA = {
    usernameOrEmail: '',
    password: ''
}
// Administrator
// Admin@123$$

export default function LoginForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userData, setUserData] = useState(INIT_USER_DATA);
    const [loading , setLoading] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['agile_token', 'agile_user']);

    const UserSync = (token) => {
        const url = `${process.env.REACT_APP_SERVER_URL}/api/SyncUser`;
    
        axios.post(url, {}, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
            const user = response?.data;
    
            if (user) {
                console.log(user)
                setCookie('agile_user', user, { path: '/' }); 
                dispatch(addUser(user));
    
                CustomToaster(true, 'User Sync Successful');
            } else {
                CustomToaster(false, 'Invalid response from server');
            }
        })
        .catch(error => {
            CustomToaster(false, 'User Sync Failed: ' + error.message);
        });
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { usernameOrEmail, password } = userData;
        
        if (!usernameOrEmail || !password) {
            return CustomToaster(false, 'Please fill in all credentials');
        }
        
        if (password.length < 6) {
            return CustomToaster(false, 'Password must be greater than 6 characters');
        }
    
        const payload = isEmail(usernameOrEmail)
            ? { Email: usernameOrEmail, Password: password }
            : { Username: usernameOrEmail, Password: password };
        
        try {
            setLoading(true);
            const url = `${process.env.REACT_APP_SERVER_URL}/api/Auth/GetToken`;
    
            axios.post(url, payload).then(response => {
                const res = response?.data?.payload;
    
                console.log(response);
                if (res) {
                    setCookie('agile_token', res.token, { path: '/' });
    
                    UserSync(res.token);
    
                    CustomToaster(true, 'Login Successful');
                    navigate('/account/users');
                } else {
                    if(response?.data && response?.data?.errorCode == 400){
                        if(response?.data?.errorMessage != null){
                            CustomToaster(false, 'Login Failed: ' + response?.data?.errorMessage);
                        }else{
                            CustomToaster(false, 'Login Failed: Wrong Credentials');
                        }
                    }
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                CustomToaster(false, 'Login Failed: ' + error.message);
            });
        } catch (error) {
            setLoading(false);
            CustomToaster(false, 'Login Failed: ' + error.message);
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value
        });
    }

    return (
        <form className="space-y-4 md:space-y-2" action="#" onSubmit={handleSubmit}>
            <div className='mb-1'>
                <label htmlFor="usernameOrEmail" className="block mb-2 text-md font-medium text-primary dark:text-white">Email:</label>
                <input type="text" name="usernameOrEmail" id="usernameOrEmail" onChange={handleChange} className="bg-gray-50 border-2 border-primary text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>

                <label htmlFor="password" className="block mb-2 mt-1 text-md font-medium text-primary dark:text-white">Password:</label>
                <input type="password" name="password" id="password" onChange={handleChange} className="bg-gray-50 border-2 border-primary text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
            </div>
            <button type="submit" className={`${loading ? "pointer-events-none cursor-not-allowed bg-gray-600" : ""} w-full text-white bg-[#0d2934] hover:bg-warning hover:text-[#0d2934] font-bold text-xl px-5 py-2 text-center uppercase`}>
                Login
            </button>
            
            <hr className='border-[1.5px] border-primary' />

            <div className="flex items-center justify-center pt-2">
                <ul className="max-w-md uppercase space-y-1 list-inside list-disc">
                    <div className="flex">
                        <li className="text-primary marker:text-black"> 
                            <Link to="/register" className='hover:underline'>Register</Link>
                        </li>
                        <li className="ms-4 text-primary marker:text-black">
                            <Link to="/forgotPassword" className='hover:underline'>Forgot Password</Link>                                            
                        </li>
                    </div>
                    <li className="text-primary marker:text-black">
                        <Link to="/resendCode" className='hover:underline'>Resend Confirmation Code</Link>                                            
                    </li>
                </ul>
            </div>
            
            <div className="relative flex pt-2 items-center">
                <div className="flex-grow border-t border-[2px] border-primary"></div>
                <span className="flex-shrink mx-4 text-primary uppercase">OR</span>
                <div className="flex-grow border-t border-[2px] border-primary"></div>
            </div>

            <div className='space-y-2'>
                <button type="button" className='uppercase flex items-center w-full'>
                    <FaFacebookF className='bg-[#2459ac] text-white w-10 h-9 px-2.5 border-[3px] border-white rounded-full -me-2 z-10' />
                    <span id="facebook-btn" className='bg-btnColor text-white w-full font-bold py-1 px-4'>Sign In With Facebook</span>
                </button>
                <button type="button" className='uppercase flex items-center w-full'>
                    <FaGooglePlusG className='bg-btnColor text-white w-10 h-9 px-1.5 border-[3px] border-white rounded-full -me-2 z-10' />
                    <span id="google-btn" className='bg-btnColor text-white w-full font-bold py-1 px-4'>Sign In With Google</span>
                </button>
            </div>

            <p className="text-sm text-primary">
                Don’t have an account? <Link to="/register" className="text-md font-bold text-black underline">Create An Account</Link>
            </p>
        </form>
    )
}
