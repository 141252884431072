import MapImg1 from '../../assets/images/map1.svg';
import MapImg2 from '../../assets/images/map2.svg';
import MapImg3 from '../../assets/images/map3.svg';
import MapImg4 from '../../assets/images/map4.svg';
import { FaLocationDot, FaChevronRight } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FaWifi, FaRegSnowflake } from "react-icons/fa";
import { LuParkingCircle } from "react-icons/lu";

const imgData = [
    {id: 1, img: MapImg3},
    {id: 2, img: MapImg4},
    {id: 3, img: MapImg3},
    {id: 4, img: MapImg4},
    {id: 5, img: MapImg3},
    {id: 6, img: MapImg4},
  ]
  

export default function MapCard(){
    return(
      <div id="map-card" className='bg-[#0f1f1f] w-[380px] py-10 px-4 grid grid-cols-12 sm:gap-0 gap-2'>
          <div className='sm:col-span-8 col-span-12'>
              <h1 className='text-warning uppercase text-lg leading-tight font-bold'>
                alterlife <span className='font-normal'>(halandri)</span> gym
              </h1>
              <ul class="mt-2 max-w-md space-y-1 text-white text-xs list-none list-inside">
                <li className='flex items-center'>
                  <FaLocationDot className='me-1'/>
                  Λ. Πεντέλης 107 Χαλάνδρι, Ελλάδα
                </li>
                <li className='flex items-center'>
                  <IoCall className='me-1'/>
                  210 6828470
                </li>
                <li className='flex items-center'>
                  <IoIosMail className='me-1'/>
                  absolutechalandri@alterlife.gr
                </li>
            </ul>
          </div>
          <div className='sm:col-span-4 col-span-12'>
              <img src={MapImg1} className="w-full object-cover h-[130px]" alt="" />
          </div>
          <div className='col-span-12'> 
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 mt-3">
              {imgData.map((img) => (
                <div key={img.id}>
                  <img class="h-auto w-full rounded object-fill" src={img.img} alt=""/>
                </div>
              ))}
            </div>
          </div>
          <div className='col-span-12'> 
            <hr className='h-[2px] bg-white opacity-50 mt-1 mb-2' />
            <div class="grid grid-cols-12 gap-1 mt-2">
              <div className='sm:col-span-7 col-span-12'>
  
                <div className='sm:border-r sm:border-white py-2 uppercase'>
                  <h1 className='text-warning mb-2'>Παροχεσ:</h1>
                  <ul className='text-white space-y-1 list-none list-inside'>
                    <li className='flex items-center'>
                      <FaChevronRight className='me-1 w-1.5' />Strength machines 
                    </li>
                    <li className='flex items-center'>
                      <FaChevronRight className='me-1 w-1.5' />Cardio 
                    </li>
                    <li className='flex items-center'>
                      <FaChevronRight className='me-1 w-1.5' />Group training
                    </li>
                    <li className='flex items-center'>
                      <FaChevronRight className='me-1 w-1.5' />Group Personal (boutique) 
                    </li>
                    <li className='flex items-center'>
                      <FaChevronRight className='me-1 w-1.5' />Pool
                    </li>
                    <li className='flex items-center'>
                      <FaChevronRight className='me-1 w-1.5' />Crossfit
                    </li>
                    <li className='flex items-center'>
                      <FaChevronRight className='me-1 w-1.5' />Spinning
                    </li>
                  </ul>
                </div>
                
                <div className='sm:border-r sm:border-t sm:border-white py-2'>
                  <h1 className='text-warning font-bold mb-2'>1,000 τ.μ.</h1>
                  <ul className='text-white space-y-1 list-none list-inside'>
                    <li className='flex items-center'>
                      <FaWifi className='me-1 w-3' />Free Wifi 
                    </li>
                    <li className='flex items-center'>
                      <LuParkingCircle className='me-1 w-3' />Free Parking 
                    </li>
                    <li className='flex items-center'>
                      <FaRegSnowflake className='me-1 w-3' />Air Condition
                    </li>
                  </ul>
                </div>
                
                <div className='sm:border-r sm:border-t sm:border-white py-2'>
                  <h1 className='text-warning uppercase mb-1'>Opening Hours:</h1>
                  <ul className='text-white text-[11px] space-y-1 list-none list-inside'>
                    <li className=''>
                      Δευτέρα – Παρασκευή: 06:30 – 23:30 
                    </li>
                    <li className=''>
                      Σάββατο: 08:00 – 21:00 
                    </li>
                    <li className=''>
                      Κυριακή: 10:00 – 18:00
                    </li>
                  </ul>
                </div>
  
                <Link to="/register" className="text-warning uppercase font-medium flex items-center mt-3">
                  Signup
                  <FaChevronRight className='ms-1 w-1.5' /><FaChevronRight className='w-1.5' />
                </Link>
  
              </div>
              <div className='col-span-5 pt-5'>
                <p className='text-xs ps-1 text-white'>
                  Συνδύασε τη γυμναστική
                  σου με τη διασκέδαση. 
                  Επέλεξε το πακέτο που 
                  σε ενδιαφέρει και
                  κέρδισε έκπτωσεις σε 
                  πολιτιστικούς
                  χώρους και δρόμενα.
                </p>
              </div>
            </div>
          </div>
      </div>
    );
  }