import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaTrash } from "react-icons/fa";
import CustomToaster from '../../../components/shared/customToaster';
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import { Page } from '../../../utils/pagination';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TableLoader from '../../../components/shared/table-loader';
import CreatePackagePriceOpion from '../../../components/forms/package-price-option-form';
import { GetApi, InsertApi, DeleteApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';
import { AreAllFieldsFilled } from '../../../utils/form-validation';

const INIT_UNIT = {
    packagePriceOptionID: 0,
    price: 0,
    isActive: false,
    packageID: 0,
    unitID: 0,
    unitDescription: null,
    isDefault: false
}

export default function ProductSupplier() {
    const [unit, setUnit] = useState(INIT_UNIT);
    const [unitList, setUnitList] = useState([]);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [packageDetail, setPackageDetail] = useState([]);
    const [packageName, setPackageName] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const [cookie] = useCookies();
    const token = GetCookie(cookie);
    const { id } = useParams();

    const fetchPackages = async () => {
        try {
            const url = `/api/GetPackages?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)) {
                const pkgName = res.find(item => item.packageID == id).packageName;
                setPackageName(pkgName || '');
            } 
        } catch (error) {
            CustomToaster(false, "Error fetching orders data");
        }
    };

    const fetchUnits = async () => {
        try {
            const url = `/api/GetUnits?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                setUnitList(res);
                setUnit({
                    ...unit,
                    unitDescription: res[0].description,
                    unitID: res[0].unitID
                });
            } 
        } catch (error) {
            CustomToaster(false, "Error fetching products data");
        }
    };

    const fetchPackagePriceOption = async () => {
        try {
            const url = `/api/GetPackagePriceOptions?packageID=${id}&pageNumber=${Page.pageNumber}&pageSize=${1000}`;
            const res = await GetApi(token, url);
            console.log(res)
            if(Array.isArray(res)){
                setPackageDetail(res);
            }
        } catch (error) {
            CustomToaster(false, "Error fetching products data");
        }
    };

    const handleFetch = async() => {
        setLoading(true);
        await fetchUnits();
        await fetchPackagePriceOption();
        setLoading(false);
    }

    useEffect(() => {
        fetchPackages();
        handleFetch();
    }, [token]);

    const handleForm = () => {
        if(isOpenForm){
            setUnit(INIT_UNIT);
        }
        setIsOpenForm(!isOpenForm);
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUnit({
            ...unit,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const url = `/api/SavePackagePriceOption`;
            const payload = {
                ...unit,
                isActive: true,
                packageID: Number(id),
            };
            console.log(payload)
            if (!AreAllFieldsFilled(payload)) {
                CustomToaster(false, 'Please fill in all fields');
                setLoading(false);
                return;
            }
            const res = await InsertApi(token, url, payload);
            if(Object.keys(res).length <= 0){
                if (!isEditing) {
                    CustomToaster(false, 'Failed to add product');
                } else {
                    CustomToaster(false, 'Failed to update successfully');
                }
                setLoading(false);
                return;
            }
            if (!isEditing) {
                CustomToaster(true, 'Product added successfully');
            } else {
                CustomToaster(true, 'Product edited successfully');
            }
            handleFetch();   
            setUnit(INIT_UNIT);
            setIsOpenForm(false);
            setIsEditing(false);
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to add/edit product');
        } finally{
            setLoading(false);
        }
    };

    const handleEdit = (item) => {
        setUnit({
            isActive: item.isActive,
            isDefault: item.isDefault,
            packageID: item.packageID,
            packagePriceOptionID: item.packagePriceOptionID,
            price: item.price,
            unitDescription: item.unitDescription,
            unitID: item.unitID
        });
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleDelete = async(id) => {
        try {
            const res = await DeleteApi(token, 'api/DeletePackagePriceOption', id);
            if(res?.data == 1){
                fetchPackagePriceOption();
                CustomToaster(true, 'Product deleted successfully');
            }else {
                CustomToaster(false, 'Failed to delete product');
            }
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to delete product');
        }
    };

    return (
        <NestedLayout>
            {isOpenForm && (
                <CreatePackagePriceOpion unit={unit} data={unitList} setUnit={setUnit} isEditing={isEditing} handleModal={handleForm} handleSubmit={handleSubmit} handleChange={handleChange} />
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        Suppliers for product '{packageName}'
                    </h1>
                    <div className='flex items-center justify-end px-2'>
                        <button onClick={handleForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                            ADD<FaPlus className='ms-2' />
                        </button>                  
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Is Default?</th>
                            <th scope="col" className="px-6 py-3">Unit</th>
                            <th scope="col" className="px-6 py-3">Price</th>
                            <th scope="col" className="px-6 py-3">Edit</th>
                            <th scope="col" className="px-6 py-3">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td colSpan={5} className="text-lg px-6 py-4">
                                    <TableLoader/>
                                </td>
                            </tr>
                        ) : (
                            packageDetail.length <= 0 ? (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={5} className="w-full text-center px-6 py-4">
                                        No data to display
                                    </td>
                                </tr>
                            ) : (
                                packageDetail.map((data, index) => (
                                    <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                        <td className="px-6 py-4">
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" value="" className="sr-only peer" checked={data.isDefault} onChange={handleCheckboxChange}/>
                                                <div className="w-9 h-5 bg-gray-200 hover:bg-gray-300 peer-focus:outline-0 peer-focus:ring-transparent rounded-full peer transition-all ease-in-out duration-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-primary hover:peer-checked:bg-primary"></div>
                                            </label> 
                                        </td>                                    
                                        <td className="px-6 py-4">{data.unitDescription}</td>                                    
                                        <td className="px-6 py-4">{data.price}</td>                                    
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleEdit(data)}>
                                                <RiPencilFill />
                                            </button>
                                        </td> 
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleDelete(data.id)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={5} className="text-lg px-6 py-4">
                                {packageDetail.length} Total
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    );
}
