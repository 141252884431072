import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaChevronRight } from "react-icons/fa";
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import { Page } from '../../../utils/pagination';
import axios from 'axios';
import CustomToaster from '../../../components/shared/customToaster';
import TableLoader from '../../../components/shared/table-loader';
import { GetApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';

const OrderStatusEnum = {
    0: 'Pending',
    1: 'Completed',
    2: 'Rejected'
};  

export default function Orders() {
    const [loading, setLoading] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);

    const fetchMyOrders = async () => {
        try {
            setLoading(true);
            const url = `/api/GetOrders?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            const resData = res?.data;
            console.log(resData);
            if(Array.isArray(resData)) {
                setOrderList(resData);
            } 
        } catch (error) {
            CustomToaster(false, "Error fetching orders data");
        }finally{
            await SmoothDelay();
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMyOrders();
    }, [token]);

    const handleOpenDetail = (index) => {
        setSelectedRow(selectedRow === index ? null : index);
    };

    const getOrderStatusDescription = (status) => {
        return OrderStatusEnum[status] || 'Unknown';
    };
      
    return (
        <NestedLayout>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        My Orders
                    </h1>               
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Details
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Order Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Total Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Order Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td colSpan={4} className="w-full text-center px-6 py-5">
                                    <TableLoader/>
                                </td>
                            </tr>
                        ) : (
                            orderList.length <= 0 ? (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={4} className="w-full text-center px-6 py-4">
                                        No data to display
                                    </td>
                                </tr>
                            ) : (
                                orderList.map((data, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="px-6 py-4">
                                                <button type="button" onClick={() => handleOpenDetail(index)}>
                                                    <FaChevronRight />
                                                </button>
                                            </td>
                                            <td className="px-6 py-4">
                                                {/* {data.orderDate.split("T")[0]} */}
                                                {new Date(data.orderDate).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                })}
                                            </td>
                                            <td className="px-6 py-4">
                                                {data.totalPrice}
                                            </td>
                                            <td className="px-6 py-4">
                                                {getOrderStatusDescription(data.ordersStatus)}
                                            </td>
                                        </tr>
                                        {selectedRow === index && (
                                            <tr className="bg-gray-100 dark:bg-gray-700">
                                                <td colSpan={4} className="px-6 py-4">
                                                    {data.ordersDtls.map((detail, index) => (
                                                        <ul key={index} className="flex text-gray-500 bg-white border-b rounded">
                                                            <li className="px-6 py-4">
                                                                <p className='font-bold text-lg'>Package</p>
                                                                <p>{detail.packageName}</p>
                                                            </li>
                                                            <li className="px-6 py-4">
                                                                <p className='font-bold text-lg'>Quantity</p>
                                                                <p>{detail.quantity}</p>
                                                            </li>
                                                            <li className="px-6 py-4">
                                                                <p className='font-bold text-lg'>Price</p>
                                                                <p>{detail.price}</p>
                                                            </li>
                                                        </ul>
                                                    ))}
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))
                            )
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={4} className="text-lg px-6 py-4">
                                {orderList.length} Total
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    )
}
