import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: []
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
    removeCart: (state, action) => {
        if (action.payload) {
            state.items = state.items.filter(item => item.name !== action.payload); // Use name instead of group
        } else {
            state.items = [];
        }
    },    
    clearCart: (state) => {
      state.items = [];
    }
  }
});

export const { addItem, removeCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;