export const Page = {
    // selectItemsPerPage: number[] = [5, 10, 25, 100];
    //The number of elements in the page
    pageSize: 10,
    //The total number of elements
    totalElements: 0,
    //The total number of pages
    totalPages: 0,
    //The current page number
    pageNumber: 0,
    // query string
    query: "",
    // order by string
    orderBy: "",
};