import axios from "axios";

export const DeleteApi = async(id, module, token) => {
    try {
        const url = `${process.env.REACT_APP_SERVER_URL}/${module}`;
        const res = await axios.post(url, id, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return res;
    } catch (error) {
        console.log(error);
        throw error;
    }
};