import React from 'react'
import Layout from '../../layout/index';
import Logo2 from '../../assets/logo/black-logo.png';
import {CustomDescription, Description, ResponsiveDesc} from '../../components/common/description';

export default function About() {
    const customHeight = "2xl:h-[120vh] xl:h-[150vh] lg:h-[190vh] h-[150vh]";
    const customLeftPadding = "3xl:pl-12 2xl:pl-28 xl:pl-8 lg:pl-5 pl-20";
    // xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]
    return (
        <Layout customClass1={`${customHeight} 3xl:w-[600px] 2xl:w-[500px] xl:w-[350px] lg:w-[350px] w-[300px]`} customClass2={`${customHeight} 3xl:w-[625px] 2xl:w-[525px] xl:w-[375px] lg:w-[375px] w-[325px]`} customClass3={`${customHeight} 3xl:w-[650px] 2xl:w-[550px] xl:w-[400px] lg:w-[400px] w-[350px]`}>
            <section id="product-bg-img" className="bg-cover bg-center 2xl:h-[120vh] min-h-screen h-full pb-20 relative md:-mt-16 lg:ps-96 md:px-0 px-10 flex xl:flex-col lg:flex-row items-center justify-center py-8 lg:pt-20">
                <div className="relative s:z-20 shadow dark:border md:mt-20 3xl:max-w-3xl xl:max-w-2xl md:max-w-xl w-full xl:p-0">
                    <hr className='h-[80px] -mb-[41px] bg-warning opacity-80' id="custom-header-style" />
                    <div className="bg-white p-6 space-y-4 md:space-y-6 sm:p-8 3xl:px-12" id="custom-login-div">
                        <div className='flex justify-end mt-5'>
                            <img src={Logo2} className='lg:w-28 md:w-24 sm:w-20 w-16 object-cover' alt="" />
                        </div>
                        <div className=''>
                            <h1 className="3xl:text-[26pt] font-medium text-xl leading-tight tracking-tight text-primary md:text-2xl">
                                Take Your Fitness to the Next Level
                            </h1>
                        </div>
                        <p className='sm:text-[15px] text-sm font-bold'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                        </p>
                        <p className='sm:text-[15px] text-sm font-bold'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                            laboris nisi ut aliquip ex ea commodo consequat.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            labore et dolore magna aliqua. Ut enim ad minim veniam.
                        </p>
                        <p className='sm:text-[15px] text-sm font-bold'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                            laboris nisi ut aliquip ex ea commodo consequat.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            labore et dolore magna aliqua. Ut enim ad minim veniam.
                        </p>
                        <p className='sm:text-[15px] text-sm font-bold'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                            laboris nisi ut aliquip ex ea commodo consequat.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.                        
                        </p>
                    </div>
                    <hr className='h-[30px] bg-warning opacity-80 ' />
                </div>
            </section>
            <ResponsiveDesc/>
            <CustomDescription section1Class={`3xl:-mt-[500px] 2xl:-mt-[550px] xl:-mt-[350px] lg:-mt-[350px] ${customLeftPadding}`} section2Class={`3xl:w-[230px] 2xl:w-[350px] xl:w-[250px] w-[200px] 3xl:-mt-[700px] 2xl:-mt-[700px] xl:-mt-[600px] lg:-mt-[600px] ${customLeftPadding}`} />
        </Layout>
    )
}
