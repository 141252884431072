import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import CustomToaster from '../../../components/shared/customToaster';
import CreateProduct from '../../../components/forms/product-form';
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import { Page } from '../../../utils/pagination';
import axios from 'axios';
import { GrDatabase } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import TableLoader from '../../../components/shared/table-loader';
import { AreAllFieldsFilled } from '../../../utils/form-validation';
import { InsertApi, DeleteApi, GetApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';

const INIT_PRODUCTS = {
    productID: '',
    productName: "",
    productDescription: "",
    reorderLevel: null,
    discontinued: '',
    productCategoryID: '',
    categoryName: null,
    pictureFilename: null,
    isActive: true,
}

export default function Products() {
    const [products, setProducts] = useState(INIT_PRODUCTS);
    const [productCategory, setProductCategory] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [productCategoryId, setProductCategoryId] = useState(null);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);

    const fetchProducts = async () => {
        try {
            const url = `/api/GetProducts?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                setProductsList(res);
                const data = res.filter((data) =>
                    data.productCategory?.productCategoryName.toLowerCase().includes(search.toLowerCase()) || data.productName.toLowerCase().includes(search.toLowerCase()))
                setFilteredData(data);
            } 
        } catch (error) {
            CustomToaster(false, "Error fetching products data");
        }
    };

    const fetchProductCategory = async () => {
        try {
            const url = `/api/GetProductcategories?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                setProductCategory(res);
            } 
        } catch (error) {
            CustomToaster(false, "Error fetching products category data");
        }
    };

    const handleFetch = async () => {
        setLoading(true);
        await fetchProducts();
        await fetchProductCategory();
        await SmoothDelay();
        setLoading(false);
    };

    useEffect(() => {
        handleFetch();
    }, [token]);
    
    useEffect(() => {
        const data = productsList.filter((data) =>
            data.productCategory?.productCategoryName.toLowerCase().includes(search.toLowerCase()) || data.productName.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(data);
    }, [search]);
    
    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleForm = () => {
        setIsEditing(false);
        setProducts(INIT_PRODUCTS);
        setIsOpenForm(!isOpenForm);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'productCategoryName'){
            const category = productCategory.find((category) => category.productCategoryName === value);
            setProductCategoryId(category?.productCategoryID);
            console.log(category?.productCategoryID);
        }
        setProducts({
            ...products,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        const validateData = {
            productDescription: products.productDescription,
            productName: products.productName,
            productCategoryID: productCategoryId
        }; 
        if (!AreAllFieldsFilled(validateData)) {
            console.log(validateData, products)
            CustomToaster(false, 'Please fill in all fields');
            return;
        }
        try {
            setLoading(true);
            const url = `${process.env.REACT_APP_SERVER_URL}/api/SaveProduct`;
            const payload = {
                productID: isEditing ? products.productID : 0,
                productName: products.productName,
                productDescription: products.productDescription,
                reorderLevel: products.reorderLevel,
                discontinued: false,
                productCategoryID: productCategoryId,
                categoryName: products.categoryName,
                pictureFilename: products.pictureFilename,
                isActive: true,
            };
            const res = await InsertApi(token, url, payload);
            if (Object.keys(res).length > 0) {
                if (isEditing) {
                    CustomToaster(true, 'Product edited successfully');
                }else{
                    CustomToaster(true, 'Product added successfully');
                }
                fetchProducts();
                setIsEditing(false);
                setProducts(INIT_PRODUCTS);
                setIsOpenForm(false);
            }
            else{
                CustomToaster(false, 'Failed to add/edit product');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            CustomToaster(false, 'Failed to add/edit product');
        }
    };

    const handleEdit = (product) => {
        const cat = productCategory.find((category) => category.productCategoryID === product.productCategoryID).productCategoryName;
        const updatedProduct = {
            ...product,
            productCategoryName: cat
        };
        console.log(updatedProduct);
        setProductCategoryId(product.productCategoryID);
        setProducts(updatedProduct);
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleDelete = async(id) => {
        try {
            setLoading(true);
            const res = await DeleteApi(token, 'api/DeleteProduct', id);
            if (res.status === 200) {
                fetchProducts();
                CustomToaster(true, 'Product deleted successfully');
            }else {
                CustomToaster(false, 'Failed to delete product');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            CustomToaster(false, 'Failed to delete product');
        }
    };

    return (
        <NestedLayout>
            {isOpenForm && (
                <CreateProduct isEditing={isEditing} loading={loading} setProductCategoryId={setProductCategoryId} productCategory={productCategory} data={products} handleModal={handleForm} handleSubmit={handleSubmit} handleChange={handleChange} />
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        Product Categories
                    </h1>
                    <div className='xs:flex sm:space-y-0 space-y-2 items-center justify-between px-2'>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <FaSearch className='w-4 h-4 text-gray-500' />
                            </div>
                            <input
                                type="text"
                                onChange={handleSearch}
                                className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded sm:w-80 w-full bg-gray-50"
                                placeholder="Search Here"
                            />
                        </div>

                        <button onClick={handleForm} className="inline-flex xs:w-auto w-full justify-center items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm xs:px-3 py-1.5" type="button">
                            ADD<FaPlus className='ms-2' />
                        </button>                  
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Name</th>
                            <th scope="col" className="px-6 py-3">Category</th>
                            <th scope="col" className="px-6 py-3">Suppliers</th>
                            <th scope="col" className="px-6 py-3">Edit</th>
                            <th scope="col" className="px-6 py-3">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr className="bg-white border-b hover:bg-gray-50">
                                <td colSpan={4} className="py-5 w-full">
                                    <TableLoader/>
                                </td>
                            </tr>
                            ) : (
                            filteredData.length <= 0 ? (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={4} className="w-full text-center px-6 py-4">
                                        No data to display
                                    </td>
                                </tr>
                            ) : (
                                filteredData.map((data, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-6 py-4">{data.productName}</td>
                                        <td className="px-6 py-4">{data.productCategory?.productCategoryName}</td>
                                        <td className="px-6 py-4">
                                            <Link to={`/account/productsuppliers/${data.productID}`}>
                                                <GrDatabase />
                                            </Link>
                                        </td>
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleEdit(data)}>
                                                <RiPencilFill />
                                            </button>
                                        </td> 
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleDelete(data.id)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={4} className="text-lg px-6 py-4">
                                {filteredData.length} Total
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    );
}
