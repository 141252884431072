import Navbar from "../components/common/navbar";
import Footer from "../components/common/footer";
import {LeftBlack1, LeftBlack2, LeftBlack3} from "../components/svg/left-black";

export default function Layout({ children, customClass1, customClass2, customClass3 }) {
  return (
    <>
        <LeftBlack1 customClass1={customClass1}/>
        <LeftBlack2 customClass2={customClass2}/>
        <LeftBlack3 customClass3={customClass3}/>
        <div className="font-[Myriad Pro]">
          <Navbar/>
          <main>
            { children }
          </main>
          <Footer/>
        </div>
    </>
  )
}
