import { ClearCookie, GetCookie, GetCookieUser } from "./cookie";
import { addUser, clearUser } from "../store/user-slice";
import { jwtDecode } from "jwt-decode";

export const LogoutUser = (navigate, dispatch, removeCookie) => {
    ClearCookie(removeCookie);
    dispatch(clearUser());
    navigate('/login');
};

const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }
  
    try {
      const decodedToken = jwtDecode(token); // Decode the token to get its payload
      const currentTime = Date.now() / 1000; // Current time in seconds

      if (decodedToken.exp && decodedToken.exp < currentTime) {
        return true;
      }
  
      return false;
    } catch (error) {
      console.error('Invalid token or decode error', error);
      return true; // Treat decode failure as expired
    }
  };

export const CheckTokenAndLogout = (cookies, navigate, dispatch, removeCookie) => {
    const token =  GetCookie(cookies);
    const res =  GetCookieUser(cookies);
    if (isTokenExpired(token)) {
        removeCookie('agile_user', { path: '/' });
        removeCookie('agile_token', { path: '/' });
        dispatch(clearUser());
        navigate('/login');
    }else {
      dispatch(addUser(res));
    }
};