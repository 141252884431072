import React from 'react'
import { GiCrossMark } from "react-icons/gi";
import ProfileImg from '../../assets/images/profile_image.jpg'

const userLevels = ['User', 'Supplier', 'Resaller'];

export default function CreateUser({companies, handleModal, handleUserChange, user, selectCompany, isEditing, handleSubmit}) {
    return (
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow">
                    {/* <!-- Modal header --> */}
                    <div className="flex items-center justify-between py-2 px-4 md:px-5 md:py-3 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Add New User
                        </h3>
                        <button type="button" onClick={handleModal} className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                            <GiCrossMark className='text-xl' />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <form className="w-full mx-auto" action='#' onSubmit={handleSubmit}>
                        <div className="p-4 md:p-5 space-y-4 overflow-auto h-[60vh] lg:h-[70vh]">
                            <div className="mb-5">
                                <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-gray-900">Firstname</label>
                                <input type="text" name='firstname' id="firstname" value={user.firstname} onChange={(e) => handleUserChange(e)} className="bg-white border border-primary   text-gray-900 text-sm rounded block w-full p-2.5"  required />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="surname" className="block mb-2 text-sm font-medium text-gray-900">Surname</label>
                                <input type="text" name='surname' id="surname" value={user.surname} onChange={(e) => handleUserChange(e)} className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"  required />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">User Name</label>
                                <input type="text" name='username' id="username" value={user.username} onChange={(e) => handleUserChange(e)} className="bg-white border border-primary   text-gray-900 text-sm rounded block w-full p-2.5"  required />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                <input type="email" name='email' id="email" value={user.email} onChange={(e) => handleUserChange(e)} className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"  required />
                            </div>

                            <fieldset className='mb-5'>
                                <label htmlFor="gender" className="block mb-2 text-sm font-medium text-gray-900">Select your country</label>
                                <select id="gender" name='gender' value={user.gender} onChange={(e) => handleUserChange(e)} className="bg-gray-50 border border-primary text-gray-900 text-sm rounded block w-full p-2.5 " required>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </fieldset>
                            <div className="relative mb-5">
                                <label htmlFor="birthDate" className="block mb-2 text-sm font-medium text-gray-900">Date Of Birth</label>
                                <input type="date" id='birthDate' name='birthDate' value={user.birthDate.split('T')[0]} onChange={(e) => handleUserChange(e)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full ps-3 p-2.5 " required/>
                            </div>

                            <div className="mb-5">
                                <label htmlFor="mobilePhone" className="block mb-2 text-sm font-medium text-gray-900">Mobile No</label>
                                <input type="text" name='mobilePhone' id="mobilePhone"  value={user.mobilePhone} onChange={(e) => handleUserChange(e)} className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5" required />
                            </div>
                            <fieldset className='mb-5'>
                                <label htmlFor="userlevel" className="block mb-2 text-sm font-medium text-gray-900">User Level</label>
                                <select id="userlevel" name='userlevel' value={user.userlevel} onChange={(e) => handleUserChange(e)} className="bg-gray-50 border border-primary text-gray-900 text-sm rounded block w-full p-2.5 ">
                                    {userLevels.map((level, index) => (
                                        <option key={index} value={level}>{level}</option>
                                    ))}
                                </select>
                            </fieldset>
                            <div className="mb-5">
                                <label htmlFor="picture" className="block mb-2 text-sm font-medium text-gray-900">Profile Url</label>
                                <input type="text" name='picture' id="picture" value={user.picture} onChange={(e) => handleUserChange(e)} className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"  required />
                            </div>
                            <fieldset className='mb-5'>
                                <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900">Company</label>
                                <select id="company" name="companyName" value={selectCompany}  onChange={(e) => handleUserChange(e)} className="bg-gray-50 border border-primary text-gray-900 text-sm rounded block w-full p-2.5 ">
                                    {companies.map((company, index) => (
                                        <option key={index} value={company.companyName}>{company.companyName}</option>
                                    ))}
                                </select>
                            </fieldset>
                            <div className="mb-5">
                                <label htmlFor="discount" className="block mb-2 text-sm font-medium text-gray-900">Default Discount</label>
                                <input type="number" name='defaultDiscount' value={user.defaultDiscount} onChange={(e) => handleUserChange(e)} id="discount" className="bg-white border border-primary text-gray-900 text-sm rounded block w-full p-2.5"  required />
                            </div>
                        </div>

                        <div className="flex items-center justify-end p-3 md:px-5 md:py-3 border-t border-gray-200 rounded-b">
                            <button type="button" onClick={handleModal} className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-red-500 rounded-lg border border-gray-200 hover:bg-red-600">Cancel</button>
                            <button type="submit" className="text-white bg-primary hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                {isEditing ? 'Update' : 'ADD'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
