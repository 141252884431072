import React, { useState } from 'react'
import Logo2 from '../../assets/logo/logo-icon.png';
import CustomToaster from '../../components/shared/customToaster';
import { Link } from 'react-router-dom';
import { FaUser, FaUsers } from 'react-icons/fa';
import Navbar from '../../components/common/navbar';
import Footer from '../../components/common/footer';

const INIT_USER_DATA = {
    email: '',
}

export default function ForgotPassword() {
    const [user, setUser] = useState(INIT_USER_DATA);
    const [loading , setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try{
            if(!user.email){
                CustomToaster(false, 'Please enter email!');
                return;
            }
            CustomToaster(true, 'Forgot Password Successfully');
        }catch(error){
            console.log(error.message);
            CustomToaster(false, 'Forgot Password failed');
        }
    }
  return (
    <>
        <Navbar/>
        <section id="login-bg-img" className="bg-cover bg-center 2xl:h-[150vh] xl:h-[150vh] lg:min-h-screen h-full pb-20 relative -mt-16 flex items-center justify-center px-6 py-8 pt-20">
            <div className="relative z-20 w-full shadow dark:border md:mt-20 xl:max-w-md sm:max-w-sm xl:p-0">
                <hr className='h-[80px] -mb-10 bg-warning opacity-80' id="custom-header-style" />
                <div className="bg-white p-6 space-y-4 md:space-y-6 sm:p-8" id="custom-login-div">
                    <div className='text-center'>
                        <img src={Logo2} className='w-24 mx-auto object-cover' alt="" />
                        <h1 className=" mt-2 text-xl font-bold uppercase leading-tight tracking-tight text-primary md:text-2xl dark:text-white">
                            Forgot Password
                        </h1>
                    </div>

                    <form className="space-y-4 md:space-y-2" action="#" onSubmit={handleSubmit}>
                        <div className='mb-1'>
                            <label htmlFor="email" className="block mb-2 text-md font-medium text-primary dark:text-white">Email:</label>
                            <input type="text" name="email" id="email" value={user.email} onChange={handleChange} className="bg-gray-50 border-2 border-primary text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
                        </div>
                        <button type="submit" className={`${loading ? "pointer-events-none cursor-not-allowed bg-gray-600" : ""} w-full text-white bg-[#0d2934] hover:bg-warning hover:text-[#0d2934] font-bold text-xl px-5 py-2 text-center uppercase`}>
                            Next
                        </button>
                        
                        <hr className='border-[1.5px] border-primary' />

                        <div className="flex items-center justify-center pt-2">
                            <ul className="max-w-md uppercase space-y-1 list-inside list-none">
                                <div className="flex">
                                    <li className="flex items-center space-x-2 text-primary marker:text-black"> 
                                        <FaUsers className='text-xl' />
                                        <Link to="/register" className='hover:underline'>Register</Link>
                                    </li>
                                    <li className="flex items-center space-x-2 ms-4 text-primary marker:text-black">
                                        <FaUser />
                                        <Link to="/login" className='hover:underline'>Login</Link>                                            
                                    </li>
                                </div>
                            </ul>
                        </div>
                        
                    </form>

                </div>
                <hr className='h-[30px] bg-warning opacity-80 ' />
            </div>
        </section>
        <Footer/>
    </>
  )
}
