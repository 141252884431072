import React from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { useSelector } from 'react-redux'
import ProfileImg from '../../../assets/images/profile.png'

export default function Profile() {
    const user = useSelector(state => state.user);
    console.log(user?.user);
    return (
        <NestedLayout>
            <div className='flex justify-center'>
                <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow">
                    <div class="flex flex-col items-center pb-10 pt-10">
                        {user?.user?.picture ? (
                            <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src={user?.user?.picture} alt="Bonnie image"/>
                        ) : (
                            <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src={ProfileImg} alt="Bonnie image"/>
                        )}
                        <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                            {user?.user?.username ? user?.user?.username : 'User'}    
                        </h5>
                        <span class="text-sm text-gray-500">
                            {user?.user?.email ? user?.user?.email : 'Email'}
                        </span>
                        <span class="text-sm text-gray-500 dark:text-gray-400">
                            {user?.user?.discriminator ? user?.user?.discriminator : 'Passion'}
                        </span>
                    </div>
                </div>
            </div>

        </NestedLayout>
    )
}
