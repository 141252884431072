import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./user-slice";
import cartSlice from "./cart-slice";

const rootReducer = combineReducers({
    user: userReducer,
    cart: cartSlice
});  

export const store = configureStore({
  reducer: rootReducer,
});
