import React from 'react'
import Layout from '../../layout/index';
import ContactForm from '../../components/forms/contact-form'
import Logo2 from '../../assets/logo/black-logo.png';
import { Description, ResponsiveDesc, ResponsiveDescription } from '../../components/common/description';

export default function Contact() {
    return (
        <Layout customClass1="2xl:h-[180vh] xl:h-[185vh] h-[180vh] 3xl:w-[600px] 2xl:w-[500px] xl:w-[350px] lg:w-[350px] w-[300px]" customClass2="2xl:h-[180vh] xl:h-[185vh] h-[180vh] 3xl:w-[625px] 2xl:w-[525px] xl:w-[375px] lg:w-[375px] w-[325px]" customClass3=" 2xl:h-[180vh] xl:h-[185vh] h-[180vh] 3xl:w-[650px] 2xl:w-[550px] xl:w-[400px] lg:w-[400px] w-[350px]">
            <section id="login-bg-img" className="bg-cover bg-center 2xl:h-[150vh] xl:h-[150vh] lg:min-h-screen h-full pb-20 relative -mt-16 flex xl:flex-col lg:flex-row flex-col items-center xl:justify-center lg:justify-end justify-center px-6 py-8 sm:pt-20 pt-20">
                <div className="relative z-20 w-full shadow dark:border md:mt-20 3xl:max-w-2xl 2xl:max-w-lg xl:max-w-md sm:max-w-sm xl:p-0">
                    <hr className='h-[80px] -mb-10 bg-warning opacity-80' id="custom-header-style" />
                    <div className="bg-white p-6 space-y-4 md:space-y-6 sm:p-8" id="custom-login-div">
                        <div className='text-center'>
                            <img src={Logo2} className='w-24 mx-auto object-cover' alt="" />
                            <h1 className="text-xl leading-tight tracking-tight text-primary md:text-2xl dark:text-white">
                                Get in touch
                            </h1>
                        </div>
                        <p className='text-[15px] font-bold'>gfdhehccn gasd oiopivxjv kndOIU[OID GBJHGCYUTC 
                            HDFAUYFAUHF KJPXGISPG KOQIjskduishr.</p>
                        <ContactForm/>
                    </div>
                    <hr className='h-[30px] bg-warning opacity-80 ' />
                </div>
                {/* <ResponsiveDescription/> */}
            </section>
            <ResponsiveDesc/>
            <Description/>
        </Layout>
    )
}
