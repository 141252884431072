export const StoreCookie = (token, setCookie, removeCookie) => {
    removeCookie('agile_token', { path: '/' });

    setCookie('agile_token', token, { path: '/',  maxAge: 0.5 * 24 * 60 * 60 });
};

export const StoreCookieUser = (userData, setCookie, removeCookie) => {
    removeCookie('agile_user', { path: '/' }); 
    setCookie('agile_user', JSON.stringify(userData), { path: '/', maxAge: 0.5 * 24 * 60 * 60 });
};

export const ClearCookie = (removeCookie) => {
    removeCookie('agile_user', { path: '/' });
    removeCookie('agile_token', { path: '/' });
};

export const GetCookie = (cookies) => {
    return cookies.agile_token;
};

export const GetCookieUser = (cookies) => {
    const userCookie = cookies.agile_user;
    try {
        if (Object.keys(userCookie).length === 0) {
            return null;
        }else{
            return userCookie;
        }
    } catch (error) {
        return null;
    }
};
