export function ResponsiveDescription(){
    return(
        <div className='lg:hidden sm:flex justify-between items-center space-y-4 w-full mt-10'>
            <div className='sm:w-[400px] w-full lg:bg-transparent bg-black rounded-lg lg:p-0 p-5'>
                <h2 className='text-white lg:text-4xl text-4xl font-bold'>Fit and Cultured</h2>
                <p className='text-primary'>
                    Συνδύασε τη γυμναστική σου 
                    με τη διασκέδαση. Επέλεξε το
                    πακέτο που σε ενδιαφέρει και
                    κέρδισε έκπτωσεις σε πολιτιστικούς
                    χώρους και δρόμενα.
                </p>
                <button className='mt-3 bg-primary rounded text-white px-4 uppercase'>
                    Learn More 
                    <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                </button>
            </div>
            <div className='bg-black rounded-lg lg:p-0 p-5'>
                <h2 className='text-white lg:text-4xl text-4xl font-bold'>Get 24h</h2>
                <h2 className='text-white lg:text-4xl text-4xl font-bold mt-2'>FREE TRIAL</h2>
                <button className='mt-3 bg-primary rounded text-white px-4 uppercase'>
                    Join Now 
                    {/* &rsaquo; &rsaquo; */}
                    <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                </button>
            </div>
        </div>
    )
}

export function Description(){
    return(
        <div className='lg:absolute lg:block hidden z-50 3xl:left-32 left-10'>
            <div className='3xl:-mt-[550px] 2xl:-mt-[550px] xl:-mt-[350px] lg:-mt-[350px] lg:left-auto lg:relative xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]'>
                <h2 className='text-white 2xl:text-6xl lg:text-4xl text-4xl font-bold mt-5'>Get 24h</h2>
                <h2 className='text-white 2xl:text-6xl lg:text-4xl text-4xl font-bold mt-2'>FREE TRIAL</h2>
                <button className='custom-btns mt-3 bg-primary text-white px-4 uppercase'>
                    Join Now
                    <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                </button>
            </div>
            <div className='3xl:w-[230px] 2xl:w-[250px] xl:w-[200px] w-[200px] 3xl:-mt-[700px] 2xl:-mt-[700px] xl:-mt-[500px] lg:-mt-[600px] lg:bg-transparent bg-black rounded-lg lg:p-0 p-5 xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]'>
                <h2 className='text-white 3xl:text-5xl lg:text-4xl text-4xl mt-5'>Fit and Cultured</h2>
                <p className='text-primary'>
                    Συνδύασε τη γυμναστική σου 
                    με τη διασκέδαση. Επέλεξε το
                    πακέτο που σε ενδιαφέρει και
                    κέρδισε έκπτωσεις σε πολιτιστικούς
                    χώρους και δρόμενα.
                </p>
                <button className='custom-btns mt-3 bg-primary text-white px-4 uppercase'>
                    Learn More 
                    <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                    {/* &rsaquo; &rsaquo; */}
                </button>
            </div>
        </div>
    )
}


export function CustomDescription({section1Class, section2Class}) {
    return(
        <div className='lg:absolute lg:block hidden z-40 3xl:left-32 left-10'>
            <div className={`${section1Class} lg:left-auto lg:relative`}>
                <h2 className='text-white 2xl:text-6xl lg:text-4xl text-4xl font-bold mt-5'>Get 24h</h2>
                <h2 className='text-white 2xl:text-6xl lg:text-4xl text-4xl font-bold mt-2'>FREE TRIAL</h2>
                <button className='custom-btns mt-3 bg-primary text-white px-4 uppercase'>
                    Join Now
                    <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                </button>
            </div>
            <div className={`${section2Class} lg:bg-transparent bg-black rounded-lg lg:p-0 p-5`}>
                <h2 className='text-white 3xl:text-5xl lg:text-4xl text-4xl mt-5'>Fit and Cultured</h2>
                <p className='text-primary'>
                    Συνδύασε τη γυμναστική σου 
                    με τη διασκέδαση. Επέλεξε το
                    πακέτο που σε ενδιαφέρει και
                    κέρδισε έκπτωσεις σε πολιτιστικούς
                    χώρους και δρόμενα.
                </p>
                <button className='custom-btns mt-3 bg-primary text-white px-4 uppercase'>
                    Learn More 
                    <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                    {/* &rsaquo; &rsaquo; */}
                </button>
            </div>
        </div>
    )
}


export function ResponsiveDesc(){
    return(
        <div className='lg:hidden sm:flex justify-between items-center w-full'>
                <div className='md:h-48 sm:h-52 sm:w-4/6 w-full lg:bg-transparent bg-black lg:p-0 p-5'>
                    <h2 className='text-white lg:text-4xl text-4xl font-bold'>Fit and Cultured</h2>
                    <p className='text-primary'>
                        Συνδύασε τη γυμναστική σου 
                        με τη διασκέδαση. Επέλεξε το
                        πακέτο που σε ενδιαφέρει και
                        κέρδισε έκπτωσεις σε πολιτιστικούς
                        χώρους και δρόμενα.
                    </p>
                    <button className='mt-3 bg-primary rounded text-white px-4 uppercase'>
                        Learn More 
                        <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                    </button>
                </div>
                <div className='bg-black md:h-48 sm:h-52 sm:w-3/6 lg:p-0 px-5 pt-6 pb-10'>
                    <h2 className='text-white lg:text-4xl text-4xl font-bold'>Get 24h</h2>
                    <h2 className='text-white lg:text-4xl text-4xl font-bold mt-2'>FREE TRIAL</h2>
                    <button className='mt-3 bg-primary rounded text-white px-4 uppercase'>
                        Join Now 
                        {/* &rsaquo; &rsaquo; */}
                        <span className='xs:ms-2 ms-1 font-normal'>&#10095;&#10095;</span>
                    </button>
                </div>
            </div>
    )
}