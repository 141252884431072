import React, { useEffect, useState, useRef, useCallback } from 'react';
import NestedLayout from '../../../layout/nested-layout';
import TransferProductDropdown from '../../../components/dashboard/dropdown/transfer-product-dropdown';
import { GetCookie } from '../../../utils/cookie';
import { useCookies } from 'react-cookie';
import { Page } from '../../../utils/pagination';
import axios from 'axios';
import CustomToaster from '../../../components/shared/customToaster';
import TableLoader from '../../../components/shared/table-loader';
import { AreAllFieldsFilled } from '../../../utils/form-validation';
import { GetApi, InsertApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';

// Initial transfer package structure
const INIT_TRANSFER_PKG = {
    packages: [{
        packageID: 0,
        selectedPriceOptionId: 0,
        quantity: 0,
        userEmail: ""
    }],
    userId: 0,
    transactionId: 0,
    paymentToken: ""
};

export default function TransferProduct() {
    const [transferPkg, setTransferPkg] = useState(INIT_TRANSFER_PKG);
    const [availablePkg, setAvailablePkg] = useState([]);
    const [resallers, setResallers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectPackage, setSelectPackage] = useState('');
    const [resaller, setResaller] = useState('Resaller to transfer');
    const [cookie] = useCookies();
    const token = GetCookie(cookie);

    const fetchAvailablePackage = useCallback(async () => {
        try {
            const url = `/api/GetAvailablePackages?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if (Array.isArray(res)) {
                setAvailablePkg(res);
            } else {
                CustomToaster(false, "Error fetching available package data");
            }
        } catch (error) {
            CustomToaster(false, "Error fetching package data");
        }
    }, [token]);
    
    const fetchResallers = useCallback(async () => {
        try {
            const url = `/api/GetResallers?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            const resData = res?.data;
            if (Array.isArray(resData)) {
                setResallers(resData);
            } else {
                CustomToaster(false, "Error fetching resallers data");
            }
        } catch (error) {
            CustomToaster(false, "Error fetching resaller data");
        }
    }, [token]);
    
    const handleFetch = async () => {
        setLoading(true);
        try {
            await Promise.all([fetchAvailablePackage(), fetchResallers()]);
        } catch (error) {
            CustomToaster(false, "Error during data fetching");
        } finally {
            await SmoothDelay();
            setLoading(false);
        }
    };
    
    useEffect(() => {
        handleFetch();
    }, [fetchAvailablePackage, fetchResallers]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();    
        try {
            const payload = {
              packages: [{
                packageID: transferPkg.packages[0].packageID,
                selectedPriceOptionId: transferPkg.packages[0].selectedPriceOptionId,
                quantity: transferPkg.packages[0].quantity,
                userEmail: transferPkg.packages[0].userEmail
              }],
              userId: transferPkg.userId,
              transactionId: transferPkg.transactionId,
              paymentToken: transferPkg.paymentToken
            };
            const {paymentToken, packages, ...rest} = payload;
            console.log(payload, availablePkg);

            if(!AreAllFieldsFilled(rest) || !AreAllFieldsFilled(packages[0]) || packages[0]?.packageID <= 0) {
              CustomToaster(false, 'Please fill all required fields.');
              return;
            }

            const url = `/api/TransferPackage`;
            const res = await InsertApi(token, url, payload);
            console.log(res);
            if (res == 0) {
                CustomToaster(true, 'Package transferred successfully');
                resetTransferPkg();
            }else{
                CustomToaster(false, 'Failed to transfer package');
                resetTransferPkg();
            }
            setResaller('Resaller to transfer');
            setTransferPkg(INIT_TRANSFER_PKG);
        } catch (error) {
          console.error("Error transferring package:", error);
          CustomToaster(false, 'Failed to transfer package');
        }
    };

    const resetTransferPkg = () => {
      setTransferPkg(INIT_TRANSFER_PKG);
      setSelectPackage('');
    };

    const handleChange = (e) => {
      const { name, value } = e.target;

      setTransferPkg((prevPkg) => {
      const updatedPackages = { ...prevPkg.packages[0] };

      if (name === "quantity") {
          if (!value) {
          CustomToaster(false, "Please enter quantity");
          return prevPkg;
          }
          updatedPackages.quantity = Number(value);
      }

      if (name === "reseller") {
          const resellerItem = resallers.find(item => item.userLevel === value);
          return resellerItem ? { ...prevPkg, userId: resellerItem.userId } : prevPkg;
      }

      if (name === "package") {
          const pkgItem = availablePkg.find(item => item.packageID === Number(value));
          if (!pkgItem) {
            CustomToaster(false, "Package not found");
            return;
          }
          setTransferPkg((prevPkg) => ({
            ...prevPkg,
            packages: [{
                ...prevPkg.packages[0],
                packageID: pkgItem.packageID,
                selectedPriceOptionId: pkgItem.selectedPriceOptionId
            }]
          }));
          updatedPackages.packageID = pkgItem.packageID;
          updatedPackages.selectedPriceOptionId = pkgItem.selectedPriceOptionId;
          setSelectPackage(pkgItem.packageID);
      }

      return { ...prevPkg, packages: [updatedPackages] };
      });
    };

  return (
    <NestedLayout>
      <form onSubmit={handleSubmit}>
        <div className='bg-gray-50 py-10 px-5 rounded'>
          <h1 className='text-gray-700 text-center text-4xl font-bold'>Transfer packages to supplier</h1>
          {loading ? (
            <div className='my-5'>
              <TableLoader />
            </div>
          ) : (
            <>
              {/* Reseller Dropdown */}
              <div className='grid grid-cols-12 gap-2 mt-5'>
                <div className='col-span-2 flex items-center'>
                  <h1 className='text-gray-500 font-semibold'>Transfer package to:</h1>
                </div>
                <div className='col-span-3'>
                  <select name="reseller" onChange={handleChange} className="bg-gray-50 focus:outline-none border-b-2 border-gray-700 text-gray-900 text-sm block w-full p-2.5">
                    {resallers.map((item, index) => (
                      <option key={index} value={item.userLevel}>{item.userLevel}</option>
                    ))}
                  </select>
                </div>
                <div className='col-span-7 relative'>
                  <TransferProductDropdown resaller={resaller} setResaller={setResaller} setTransferPkg={setTransferPkg} ResellerData={resallers} />
                </div>
              </div>

              {/* Package Dropdown */}
              <div className='grid grid-cols-12 gap-2 mt-5'>
                <div className='col-span-2 flex items-center'>
                  <h1 className='text-gray-500 font-semibold'>Select package:</h1>
                </div>
                <div className='col-span-8 pt-2.5'>
                  <select value={selectPackage} name="package" onChange={handleChange} className="bg-gray-50 focus:outline-none border-b-2 border-gray-700 text-gray-900 text-sm block w-full p-2.5">
                    <option value="">Package/Option</option>
                    {availablePkg.map((item, index) => (
                      <option key={index} value={item.packageID}>{item.packageName} - {item.packageUnitDescription}</option>
                    ))}
                  </select>
                </div>
                <div className='col-span-2'>
                  <div className="relative">
                    <input type="number" id="floating_filled" name="quantity" value={transferPkg.packages[0]?.quantity} onChange={handleChange} className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 border-0 border-b-2 border-gray-800 appearance-none focus:outline-none" placeholder="" />
                    <label htmlFor="floating_filled" className="absolute text-sm text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Qunatity</label>
                  </div>
                </div>
                <div className='col-span-12 text-center mt-5'>
                  <button type='submit' className='bg-pink text-white rounded px-5 py-2 uppercase'>Transfer</button>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </NestedLayout>
  );
}
