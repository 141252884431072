import React, { useState } from 'react'
import Sidebar from '../components/dashboard/sidebar';
import Navbar from '../components/dashboard/navbar';

export default function NestedLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  }
  return (
        <>
            <Sidebar sidebarOpen={sidebarOpen} handleSidebar={handleSidebar} />
            <div className="p-4 lg:ml-64">
                <Navbar handleSidebar={handleSidebar} sidebarOpen={sidebarOpen} />
                <main>
                  {children}
                </main>
            </div>
        </>
      )
}
