import React from 'react';
import TopHeader from './top-header';
import NavHeader from './nav-header';
import Logo1 from '../../assets/logo/logo1.png';
import Logo2 from '../../assets/logo/logo2.png';

export default function Navbar() {
  return (
    <>
      <TopHeader />
      <div className='lg:flex hidden absolute 3xl:left-32 left-16 z-20'>
        <img src={Logo1} className='3xl:w-9 w-7 me-3' alt="Logo" />
        <img src={Logo2} className='w-16' alt="" />
      </div>
      <NavHeader />
    </>
  )
}
