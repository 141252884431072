import axios from "axios";

// Create an Axios instance for all API requests
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const setHeaders = (token) => ({
    Authorization: `Bearer ${token}`
});

export const GetApi = async (token, apiUrl) => {
    try {
        const response = await axiosInstance.get(apiUrl, {
            headers: setHeaders(token),
        });
        return response.data || null;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};

export const InsertApi = async (token, apiUrl, data) => {
    try {
        const response = await axiosInstance.post(apiUrl, data, {
            headers: setHeaders(token),
        });
        return response.data || null;
    } catch (error) {
        console.error('Error posting data:', error);
        return null;
    }
};

export const DeleteApi = async (token, apiUrl, id) => {
    try {
        const url = `${process.env.REACT_APP_SERVER_URL}/${apiUrl}`;
        const response = await axiosInstance.post(url, id, {
            headers: setHeaders(token),
        });
        return response;
    } catch (error) {
        console.error('Error deleting data:', error);
        throw error;
    }
};
