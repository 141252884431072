import React, { useEffect, useState } from 'react';
import NestedLayout from '../../../layout/nested-layout';
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import { Page } from '../../../utils/pagination';
import axios from 'axios';
import CustomToaster from '../../../components/shared/customToaster';
import { GetApi, InsertApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';
import TableLoader from '../../../components/shared/table-loader';
import isEmail from 'validator/lib/isEmail';

const INIT_TRANSFER_COUPON = {
    packageID: 0,
    quantity: 0,
    selectedPriceOptionId: 0,
    couponID: 1,
    userEmail: ''
};

export default function TransferCoupon() {
    const [coupon, setCoupon] = useState(INIT_TRANSFER_COUPON);
    const [selectedValue, setSelectedValue] = useState(""); // Initialize as empty
    const [availablePkg, setAvailablePkg] = useState([]);
    const [loading, setLoading] = useState(false);
    const [delay, setDelay] = useState(false);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);

    const fetchPackage = async () => {
        try {
            setLoading(true);
            const url = `/api/GetAvailablePackages?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if (Array.isArray(res)) {
                setAvailablePkg(res);
                await SmoothDelay();
            } else{
                CustomToaster(false, "Error fetching packages data");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            CustomToaster(false, "Error fetching packages data");
        }
    };

    useEffect(() => {
        fetchPackage();
    }, [token]);

    const handleSelect = (e) => {
        const value = e.target.value;
        setSelectedValue(value);

        if (value !== "") {
            const selectedId = parseInt(value, 10); // Ensure we parse the value correctly
            const selectedPackage = availablePkg.find(pkg => pkg.packageID === selectedId);

            // Check if selectedPackage is found
            if (selectedPackage) {
                console.log(selectedPackage);
                setCoupon({
                    ...coupon,
                    packageID: selectedPackage.packageID,
                    quantity: selectedPackage.quantity,
                    selectedPriceOptionId: selectedPackage.selectedPriceOptionId,
                });
            }
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setCoupon({ ...coupon, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!isEmail(coupon.userEmail)) {
            CustomToaster(false, 'Please enter a valid email address.');
            return;
        }
        if (coupon.packageID == 0) {
            CustomToaster(false, 'Please select package.');
            return;
        }
        try {
            setDelay(true);
            const url = `${process.env.REACT_APP_SERVER_URL}/api/TransferCoupon`;
            const res = await InsertApi(token, url, coupon);
            if (res == null) {
                CustomToaster(false, "You don't have this package available");
                return;
            }
            CustomToaster(true, 'Coupon transfer added successfully');
            setCoupon(INIT_TRANSFER_COUPON); 
            setSelectedValue("");
        } catch (error) {
            CustomToaster(false, 'Failed to add/edit product');
        }finally{
            await SmoothDelay();
            setDelay(false);
        }
    };

    return (
        <NestedLayout>
            {loading ? (
                <div className='h-[50vh] flex justify-center items-center'>
                    <TableLoader />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className='bg-gray-50 py-10 px-5 rounded'>
                        <h1 className='text-gray-700 text-center text-4xl font-bold'>Transfer packages to supplier</h1>
                        <div className='grid grid-cols-12 gap-2 mt-5'>
                            <div className='col-span-2 flex items-center'>
                                <h1 className='text-gray-500 font-semibold'>Transfer package to:</h1>
                            </div>
                            <div className='col-span-10'>
                                <div className="flex items-center justify-center">
                                    <div className="relative w-full">
                                        <input id="email" name="userEmail" type="email" value={coupon.userEmail} onChange={handleChange}
                                            className="w-full block ps-3 border-b-[2px] border-black py-1 focus:border-b-2 focus:border-black transition-colors focus:outline-none peer bg-inherit" />
                                        <label htmlFor="email" className="ps-3 absolute left-0 top-1 cursor-text peer-focus:text-xs peer-focus:-top-4 transition-all peer-focus:text-black">
                                            {coupon.userEmail === "" ? "Email" : ""}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-12 gap-2 mt-5'>
                            <div className='col-span-2 flex items-center'>
                                <h1 className='text-gray-500 font-semibold'>Select package:</h1>
                            </div>
                            <div className='col-span-10'>
                                <select value={selectedValue} onChange={handleSelect} className="bg-gray-50 border-b-2 border-black focus:outline-none text-gray-900 text-sm block w-full p-2.5 ">
                                    <option value="">Select Package</option>
                                    {availablePkg.map((pkg, index) => (
                                        <option key={index} value={pkg.packageID}>{pkg.packageName} - {pkg.packageUnitDescription}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 text-center mt-5'>
                        <button type='submit' disabled={delay} className={`${delay && 'cursor-not-allowed'} bg-pink text-white rounded px-5 py-2 uppercase`}>
                            Transfer
                        </button>
                    </div>
                </form>
            )}
        </NestedLayout>
    );
}
