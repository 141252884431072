import { toast } from 'react-hot-toast';

const CustomToaster = (type, message, duration = 1000) => {
    toast.remove();

    const toastConfig = {
        duration,
        position: 'top-center',
        style: {
            background: type === true ? '#54a8a8' : 'red',
            color: '#fff',
        },
        iconTheme: {
            primary: '#fff',
            secondary: type === true ? '#54a8a8' : 'red',
        },
    };

    if (type === true) {
        toast.success(message, toastConfig);
    } else if (type === false) {
        toast.error(message, toastConfig);
    }
};

export default CustomToaster;
