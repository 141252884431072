import React from 'react';
import { Navigate } from 'react-router-dom';
import { GetCookie } from './cookie';

const isAuthenticated = (cookies) => {
    const token = GetCookie(cookies);
    return token !== null;
};

const ProtectedRoute = ({ children, cookies }) => {
    return isAuthenticated(cookies) ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;