import React, { useEffect, useState } from 'react';
import Layout from '../../layout/index';
import LogoIcon from '../../assets/logo/logo1.png';
import { APIProvider, Map, Marker, InfoWindow } from '@vis.gl/react-google-maps';
import MapCard from '../../components/map/map-card';
import { CreateCustomPin } from '../../components/map/custom-pin';
import { ResponsiveDesc } from '../../components/common/description';

export default function CustomMap() {
  const [isGoogleMapsReady, setGoogleMapsReady] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  const locations = [
      { id: 1, lat: 31.5204, lng: 74.3587 },
      { id: 2, lat: 33.6995, lng: 73.0363 },
      { id: 3, lat: 24.8607, lng: 67.0011 } 
  ];

  useEffect(() => {
    const checkGoogleMaps = setInterval(() => {
      if (window.google && window.google.maps) {
        setGoogleMapsReady(true);
        clearInterval(checkGoogleMaps);
      }
    }, 100);
    
    return () => clearInterval(checkGoogleMaps);
  }, []);

  const handleMarkerClick = (location) => {
    console.log(location);
    setActiveMarker(location);
  };

  const handleInfoWindowClose = () => {
    setActiveMarker(null);
  };
  return (
    <Layout customClass1="2xl:h-[140vh] xl:h-[185vh] h-[170vh] 3xl:w-[600px] 2xl:w-[500px] xl:w-[350px] lg:w-[350px] w-[300px]" customClass2="2xl:h-[140vh] xl:h-[185vh] h-[170vh] 3xl:w-[625px] 2xl:w-[525px] xl:w-[375px] lg:w-[375px] w-[325px]" customClass3=" 2xl:h-[140vh] xl:h-[185vh] h-[170vh] 3xl:w-[650px] 2xl:w-[550px] xl:w-[400px] lg:w-[400px] w-[350px]">
      <div className='overflow-x-hidden'>
        
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <Map
          // style={{ width: '100vw', height: '120vh' }}
          className='xl:h-[130vh] lg:h-[120vh] h-[100vh] xl:w-[100vw]'
          defaultCenter={{ lat: 30, lng: 70 }} // Center on a location
          defaultZoom={5}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
          options={{
            mapTypeId: 'roadmap',
            styles: []
          }}
          >
          {isGoogleMapsReady && locations.map(location => (
              <Marker
              key={location.id}
              position={{ lat: location.lat, lng: location.lng }}
              icon={{
                  url: CreateCustomPin(LogoIcon),  // Use the custom pin function
                  scaledSize: { width: 70, height: 70 },  // Use an object for size
                  origin: { x: 0, y: 0 },                  // Use an object for origin
                  anchor: { x: 25, y: 50 }                  // Use an object for anchor
                  //   scaledSize: new window.google.maps.Size(50, 50),
                  //   origin: new window.google.maps.Point(0, 0),
                  //   anchor: new window.google.maps.Point(25, 50)
              }}
              onClick={() => handleMarkerClick(location)}
            />
          ))}
          {activeMarker && (
            <InfoWindow
              position={{ lat: activeMarker.lat, lng: activeMarker.lng }}
              onCloseClick={handleInfoWindowClose}
            >
              <MapCard />
            </InfoWindow>
          )}
        </Map>
      </APIProvider>

      </div>
      <div className='lg:absolute lg:block hidden z-20 3xl:left-32 left-10'>
          <div className='3xl:-mt-[80%] 2xl:-mt-[80%] xl:-mt-[80%] lg:-mt-[80%] lg:left-auto lg:relative xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]'>
          {/* <div className='3xl:-mt-[400px] 2xl:-mt-[400px] xl:-mt-[350px] lg:-mt-[350px] lg:left-auto lg:relative xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]'> */}
              <h2 className='text-white 2xl:text-6xl lg:text-4xl text-4xl font-bold mt-5'>Get 24h</h2>
              <h2 className='text-white 2xl:text-6xl lg:text-4xl text-4xl font-bold mt-2'>FREE TRIAL</h2>
              <button className='custom-btns mt-3 bg-primary text-white px-4 uppercase'>Join Now &rsaquo; &rsaquo;</button>
          </div>
          <div className='3xl:w-[230px] 2xl:w-[250px] xl:w-[200px] w-[200px] 3xl:-mt-[140%] 2xl:-mt-[130%] xl:-mt-[130%] lg:-mt-[150%] lg:bg-transparent bg-black rounded-lg lg:p-0 p-5 xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]'>
          {/* <div className='3xl:w-[230px] 2xl:w-[250px] xl:w-[200px] w-[200px] 3xl:-mt-[600px] 2xl:-mt-[600px] xl:-mt-[600px] lg:-mt-[600px] lg:bg-transparent bg-black rounded-lg lg:p-0 p-5 xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]'> */}
              <h2 className='text-white 3xl:text-5xl lg:text-4xl text-4xl mt-5'>Fit and Cultured</h2>
              <p className='text-primary'>
                  Συνδύασε τη γυμναστική σου 
                  με τη διασκέδαση. Επέλεξε το
                  πακέτο που σε ενδιαφέρει και
                  κέρδισε έκπτωσεις σε πολιτιστικούς
                  χώρους και δρόμενα.
              </p>
              <button className='custom-btns mt-3 bg-primary text-white px-4 uppercase'>Learn More &rsaquo; &rsaquo;</button>
          </div>
      </div>
      <ResponsiveDesc/>
      {/* <div className='lg:hidden absolute md:-mt-[300px] px-16 2xl:-mt-[400px] xl:-mt-[350px] lg:-mt-[350px] z-[100] sm:flex justify-between items-center space-y-4 w-full mt-10'>
        <div className='sm:w-[400px] w-full lg:bg-transparent bg-black rounded-lg lg:p-0 p-5'>
            <h2 className='text-white lg:text-4xl text-4xl font-bold'>Fit and Cultured</h2>
            <p className='text-primary'>
                Συνδύασε τη γυμναστική σου 
                με τη διασκέδαση. Επέλεξε το
                πακέτο που σε ενδιαφέρει και
                κέρδισε έκπτωσεις σε πολιτιστικούς
                χώρους και δρόμενα.
            </p>
            <button className='mt-3 bg-primary rounded text-white px-4 uppercase'>Learn More &rsaquo; &rsaquo;</button>
        </div>
        <div className='bg-black rounded-lg lg:p-0 p-5'>
            <h2 className='text-white lg:text-4xl text-4xl font-bold'>Get 24h</h2>
            <h2 className='text-white lg:text-4xl text-4xl font-bold mt-2'>FREE TRIAL</h2>
            <button className='mt-3 bg-primary rounded text-white px-4 uppercase'>Join Now &rsaquo; &rsaquo;</button>
        </div>
    </div> */}
    </Layout>
  );
}
