import React, { useEffect, useRef, useState } from "react";
import { SlOptionsVertical } from 'react-icons/sl'
import { FaPrint } from 'react-icons/fa';
import { FaFilePdf } from 'react-icons/fa6';
import { IoMdMail } from 'react-icons/io';
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";

const PrintComponent = React.forwardRef((props, ref) => (
    <div ref={ref} style={{ padding: "20px", backgroundColor: "#f4f4f4" }}>
      <h1>React Print Example</h1>
      <p>This content will be printed.</p>
    </div>
  ));
  

export default function Invoice({cartList, totalCost, subTotal, discount}){
    const [isOpen, setIsOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const componentRef = useRef(null);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleOption = () => {
        setIsOpen(!isOpen);
    }

    // const generatePDF = () => {
    //     const doc = new jsPDF();
    
    //     doc.text("Hello world!", 10, 10);
    //     doc.text("This is a PDF generated in React.", 10, 20);
    
    //     doc.save("invoice.pdf");
    // };

    const generatePDF = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        
        // Title
        doc.setFontSize(20);
        doc.text("Invoice", pageWidth / 2, 10, { align: "center" });
        
        // Add Invoice From
        doc.setFontSize(12);
        doc.text("Invoice From: MaterialWrap", 10, 30);
        doc.text("Invoice To: Dribbble Inc.", 10, 40);
        
        // Table Header
        doc.setFontSize(12);
        const headers = ["Description", "Quantity", "Unit Price", "Total"];
        const startX = 10;
        let startY = 50;

        // Draw table headers
        headers.forEach((header, index) => {
            doc.text(header, startX + (index * 40), startY);
        });

        // Draw table rows
        startY += 10; // Move down for the data rows
        cartList.forEach(item => {
            const { description, quantity, unitPrice, total } = item;
            doc.text(description, startX, startY);
            doc.text(quantity.toString(), startX + 40, startY);
            doc.text(`€${parseFloat(unitPrice).toFixed(2)}`, startX + 80, startY);
            doc.text(`€${parseFloat(total).toFixed(2)}`, startX + 120, startY);
            startY += 10; // Move down for the next row
        });

        // Subtotal, Discount, and Total
        startY += 10; // Move down for totals
        doc.text(`Subtotal: €${subTotal.toFixed(2)}`, startX, startY);
        doc.text(`Discount: €${discount.toFixed(2)}`, startX, startY + 10);
        doc.text(`Total Due: €${totalCost.toFixed(2)}`, startX, startY + 20);

        // Save the PDF
        doc.save("invoice.pdf");
    };

    const handlePrint = () => {
        const pdfBlob = generateAndPrintPDF();
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url); // Save the URL for potential future use

        const printWindow = window.open(url);
        setTimeout(() => {
            printWindow.print(); // Invoke the print dialog
        }, 500); // Adjust the timeout as needed
    };

    const generateAndPrintPDF = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();

        // Title
        doc.setFontSize(20);
        doc.text("Invoice", pageWidth / 2, 10, { align: "center" });

        // Add Invoice From
        doc.setFontSize(12);
        doc.text("Invoice From: MaterialWrap", 10, 30);
        doc.text("Invoice To: Dribbble Inc.", 10, 40);

        // Table Header
        doc.setFontSize(12);
        const headers = ["Description", "Quantity", "Unit Price", "Total"];
        const startX = 10;
        let startY = 50;

        // Draw table headers
        headers.forEach((header, index) => {
            doc.text(header, startX + (index * 40), startY);
        });

        // Draw table rows
        startY += 10; // Move down for the data rows
        cartList.forEach(item => {
            const { description, quantity, unitPrice, total } = item;
            doc.text(description, startX, startY);
            doc.text(quantity.toString(), startX + 40, startY);
            doc.text(`€${parseFloat(unitPrice).toFixed(2)}`, startX + 80, startY);
            doc.text(`€${parseFloat(total).toFixed(2)}`, startX + 120, startY);
            startY += 10; // Move down for the next row
        });

        // Subtotal, Discount, and Total
        startY += 10; // Move down for totals
        doc.text(`Subtotal: €${subTotal.toFixed(2)}`, startX, startY);
        doc.text(`Discount: €${discount.toFixed(2)}`, startX, startY + 10);
        doc.text(`Total Due: €${totalCost.toFixed(2)}`, startX, startY + 20);

        // Create Blob and open in a new window
        return doc.output("blob");
    };
    return(
        <div ref={componentRef} className='relative'>
            <button type='button' onClick={handleOption}>
                <SlOptionsVertical/>
            </button>
            <div id="dropdown" class={`${isOpen ? 'block' : 'hidden'} absolute top-8 right-0 z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700`}>
                <ul class="py-2 px-3 font-medium text-gray-600">
                    <li>
                        <button class="flex items-center rounded space-x-1 w-full p-2 uppercase hover:bg-pink hover:text-white transition-all ease-in-out duration-150 delay-150">
                            <IoMdMail className='text-md' />
                            <span>Email Invoice</span>
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={handlePrint} class="flex items-center rounded space-x-1 w-full p-2 uppercase hover:bg-pink hover:text-white transition-all ease-in-out duration-150 delay-150">
                            <FaPrint className='text-md' />
                            <span>Print Invoice</span>
                        </button>
                    </li>
                    <li>
                    <button type="button" onClick={generatePDF} class="flex items-center rounded space-x-1 w-full p-2 uppercase hover:bg-pink hover:text-white transition-all ease-in-out duration-150 delay-150">
                        <FaFilePdf className='text-md' />
                            <span>Pdf</span>
                        </button>
                    </li>
                </ul>
            </div>    
        </div>
    )
}