import React, { useEffect, useRef, useState } from "react";
import { FaCartPlus } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { removeCart } from "../../../store/cart-slice";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

function CartDropdown({ dropdownData, isOpen }) {
    const dispatch = useDispatch();
    
    const handleClearCart = (group) => {
        console.log(group);
        dispatch(removeCart(group));
    };

    const packageCounts = dropdownData.reduce((acc, item) => {
        if (!acc[item.name]) {
            acc[item.name] = { ...item, count: 0 };
        }
        acc[item.name].count += 1;
        return acc;
    }, {});

    const totalPrice = Object.values(packageCounts).reduce((total, pkg) => {
        return total + pkg.price * pkg.count;
    }, 0);
    return (
        <>
            {isOpen && (
                <div className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow sm:w-[400px] w-96 right-0">
                    <ul className="py-2 text-sm text-gray-700 px-5 space-y-2">
                        {Object.keys(packageCounts).map((pkg, index) => {
                            const { name, price, count } = packageCounts[pkg];
                            return (
                                <li key={index} className="flex items-center justify-between">
                                    <p className="sm:text-lg text-sm text-gray-500">
                                        {name} X {count}
                                    </p>
                                    <p className="sm:text-lg text-sm text-gray-500">{price} €</p>
                                    <button type="button" onClick={() => handleClearCart(name)} className="bg-pink text-white block text-start rounded px-2 py-1">
                                        <FaTrash className="" />
                                    </button>
                                </li>
                            );
                        })}
                        <li className={`flex items-center justify-between ${packageCounts.length > 0 && "mt-3"}`}>
                            <p className="sm:text-lg text-sm text-gray-500">Total</p>
                            <p className="sm:text-lg text-sm text-gray-500">{totalPrice} €</p>
                            {Object.keys(packageCounts).length <= 0 ? (
                                <button type="button" className="bg-gray-600 cursor-not-allowed text-white block text-start rounded px-4 py-1 uppercase">
                                    Checkout
                                </button>    
                            ) : (
                                <Link to="/account/cart" className="bg-pink text-white block text-start rounded px-4 py-1 uppercase">
                                    Checkout
                                </Link>
                            )}
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
}

export default function Cart() {
    const [isOpen, setIsOpen] = useState(false);
    const items = useSelector((state) => state.cart.items);

    const toggleDropdown = () => {
        setIsOpen(!isOpen); // Toggle dropdown
    };

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <>
            <div ref={dropdownRef} className="relative py-2">
                <button type='button' onClick={toggleDropdown} className=''>
                    <div className="top-0 absolute left-5">
                        <span className="flex h-2 w-2 items-center justify-center rounded-full bg-black p-2.5 text-xs text-white">
                            {items.length}
                        </span>
                    </div>
                    <FaCartPlus className='file: text-white h-6 w-6'/>
                </button>
                <CartDropdown dropdownData={items} isOpen={isOpen} />
            </div>
        </>
    )
}
