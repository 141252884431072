import React from 'react'
import { GiCrossMark } from "react-icons/gi";
import { AreAllFieldsFilled } from '../../utils/form-validation';

export default function CreateCountry({isEditing, handleModal, handleChange, country, handleSubmit}) {
    return (
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow">
                    {/* <!-- Modal header --> */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Add New Country
                        </h3>
                        <button type="button" onClick={handleModal} className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                            <GiCrossMark className='text-xl' />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="px-4 pt-4 md:px-5 md:pt-5 space-y-4">
                        <form className="w-full mx-auto" onSubmit={handleSubmit}>
                            <div className="mb-5">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
                                <input type="text" name='description' id="description" value={country.description} onChange={(e) => handleChange(e)} className="bg-white border border-primary   text-gray-900 text-sm rounded block w-full p-2.5" placeholder="" />
                            </div>
                            <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
                                <button type="button" onClick={handleModal} className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-red-500 rounded border border-gray-200 hover:bg-red-600">Cancel</button>
                                
                                <button type="submit" className={`text-white bg-primary hover:bg-black font-bold uppercase focus:ring-4 focus:outline-none rounded text-sm px-5 py-2.5 text-center`}>
                                    {isEditing ? 'Update' : 'Add'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
