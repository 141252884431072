import React, { useEffect, useRef, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi';
import { GiCrossMark } from "react-icons/gi";

export default function CreateProductDetail({data, loading, isEditing, setProduct, product, selectedValue, setSelectedValue, handleModal, handleSubmit}) {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    
    const handleDropdownOption = (item) => {
        setProduct({
            ...product,
            productID: item.id,
            productDescription: item.productDescription
        });
        setSelectedValue(item);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow">
                    {/* <!-- Modal header --> */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                          Add New Product Category
                        </h3>
                        <button type="button" onClick={handleModal} className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                            <GiCrossMark className='text-xl' />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="px-4 pt-4 md:px-5 md:pt-5 space-y-4">
                        <form className="w-full mx-auto" onSubmit={handleSubmit}>
                            <div ref={dropdownRef}>
                                <button
                                    onClick={toggleDropdown}
                                    className="flex justify-between items-center w-full text-gray-900 border-b-2 border-gray-900 font-medium text-sm px-5 py-2.5 text-center"
                                    type="button"
                                >
                                    <span>{Object.keys(selectedValue).length === 0 ? "Product Supplier" : selectedValue.productName}</span>
                                    <BiChevronDown className="text-xl ms-2" />
                                </button>
                                {isOpen && (
                                    <div className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                            {data.map((item) => (
                                                <li key={item.id}>
                                                    <button onClick={() => handleDropdownOption(item)}
                                                        className="block w-full text-start px-4 py-2 hover:bg-gray-100">
                                                        {item.productName}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
                                <button type="button" onClick={handleModal} className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-red-500 rounded-lg border border-gray-200 hover:bg-red-600">Cancel</button>
                                <button type="submit" disabled={loading} className={`${loading && 'cursor-not-allowed'} text-white bg-primary uppercase hover:bg-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
                                    {isEditing ? 'Update' : 'ADD'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
