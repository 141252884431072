import React, { useEffect, useState } from 'react'
import { GiCrossMark } from 'react-icons/gi';
import { IoIosCloudUpload } from 'react-icons/io';
import CustomToaster from '../../shared/customToaster';
import axios from 'axios';
import TableLoader from '../../shared/table-loader';
import UploadingImg from '../../../assets/images/defaultcompany.jpg' 

const photoIndexList = ['Main', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

export default function GalleryModal({ token, company, setIsGalleryOpen }) {
    const [uploadImg, setUploadImg] = useState(null);
    const [currentImg, setCurrentImg] = useState(null);
    const [base64Img, setBase64Img] = useState(null);
    const [loading, setLoading] = useState(false);
    const [photoIndex, setPhotoIndex] = useState('Main');

    const handleUploadImg = async(base64) => {
        try{
            let url = '';
            if(photoIndex == 'Main'){
                url = `${process.env.REACT_APP_SERVER_URL}/api/UploadPhoto?companyID=${company?.companyID}`;
                console.log('Without Index add: ', url);
            }else{
                let index = parseInt(photoIndex);
                if(index > 0 || index < 9){
                    url = `${process.env.REACT_APP_SERVER_URL}/api/SetMainPhoto?companyID=${company?.companyID}&index=${photoIndex}`;
                }else{
                    CustomToaster(false, 'Invalid photo index');
                }
            }
            const formData = new FormData();
            formData.append('file', base64);

            const res = await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            const img = res?.data;
            if (img) {
                setUploadImg(img);
                setCurrentImg(img);
                CustomToaster(true, 'Image uploaded successfully');
            } else {
                CustomToaster(false, 'An error occurred while uploading image');
            }
        }catch(error){
            console.log(error);
            CustomToaster(false, 'An error occurred while uploading image');
        }

    }

    const handleImgChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if(!company){
            CustomToaster(false, 'Company not found');
            return;
        }
        try{
            setLoading(true);
            handleUploadImg(file);
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64 = reader.result;
                setBase64Img(base64);
            };
            reader.readAsDataURL(file);
            setLoading(false);
        }catch(error){
            setLoading(false);
            console.log(error);
            CustomToaster(false, 'An error occurred while uploading image');
        }

    };

    const handlePhotoIndex = (e) => {
        const index = e.target.value;
        setPhotoIndex(index);
        handleCurrentImg(index);
    }

    const handleCurrentImg = (index) => {
        if(index == 'Main'){
            setCurrentImg(company?.pictureUri);
        }else{
            const selectImg = company?.galleryPhotos?.find((img) => img.galleryIndex == index);
            setCurrentImg(selectImg?.pictureUri);
        }
    }

    useEffect(() => {
        if(company){
            handleCurrentImg(photoIndex);
        }
    }, [company]);

    const handleGallery = (value) => {
        setIsGalleryOpen(value);
        setCurrentImg(null);
        setBase64Img(null);
    }
    return (
      <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center">
        <div className="relative p-4 w-full max-w-lg max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="bg-primary flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {company?.companyName}
              </h3>
              <button
                type="button"
                onClick={() => handleGallery(false)}
                className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <GiCrossMark className="text-xl" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
                {loading ? (
                    <TableLoader/>
                ) : (
                    <>
                        <div className='mb-2'>
                            <label for="photoIndex" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload image:</label>
                            <select id="photoIndex" value={photoIndex} onChange={handlePhotoIndex} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                {photoIndexList.map((index, i) => (
                                    <option selected key={i} >{index}</option>
                                ))}
                            </select>
                        </div>

                        <div className='grid grid-cols-2 gap-3'>
                            <label for="uploadFile1" className="bg-white text-gray-500 font-semibold text-base rounded w-full h-40 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto font-[sans-serif]">
                                <IoIosCloudUpload className="w-20 mb-2 fill-gray-500" />
                                    Upload file

                                <input type="file" id='uploadFile1'  accept="image/*" onChange={handleImgChange} className="hidden" />
                                <p className="text-xs font-medium text-gray-400 mt-2 text-center">PNG, JPG and other format are Allowed.</p>
                            </label>
                            {base64Img ? (
                                <div className='mb-2 flex justify-center w-full h-40'>
                                    <img src={base64Img} className='h-40 w-full rounded' alt="Upload Image" />
                                </div>
                            ) : (
                                <div className='mb-2 flex justify-center w-full h-40'>
                                    <img src={UploadingImg} className='h-40 w-full rounded' alt="Upload Image" />
                                </div>
                            )}
                            
                        </div>

                        {currentImg && (
                            <div className='mb-2 flex justify-center w-full h-60'>
                                <img src={currentImg} className='h-60 w-full rounded-lg' alt="Upload Image" />
                            </div>
                        )}
                    </>
                )}

            </div>
            <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
              <button
                type="button"
                onClick={() => handleGallery(false)}
                className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-pink rounded-lg border border-gray-200 hover:bg-pink-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
} 
