import React from 'react'
import { GiCrossMark } from "react-icons/gi";

export default function CreateProduct({isEditing, loading, productCategory, handleModal, handleChange, data, handleSubmit}) {
    return (
        <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
            <div className="relative p-4 w-full max-w-lg max-h-full">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow">
                    {/* <!-- Modal header --> */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Add New Product Category
                        </h3>
                        <button type="button" onClick={handleModal} className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                            <GiCrossMark className='text-xl' />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="px-4 pt-4 md:px-5 md:pt-5 space-y-4">
                        <form className="w-full mx-auto" onSubmit={handleSubmit}>
                            <div className="mb-5">
                                <label htmlFor="productName" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                                <input type="text" name='productName' id="productName" value={data.productName} onChange={(e) => handleChange(e)} className="bg-white border border-primary   text-gray-900 text-sm rounded block w-full p-2.5" placeholder="" required />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="productCategoryName" className="block mb-2 text-sm font-medium text-gray-900">Product Category</label>
                                <select id="productCategoryName" name='productCategoryName' value={data.productCategoryName} onChange={handleChange} className="bg-gray-50 border border-primary text-gray-900 text-sm rounded block w-full p-2.5" required>
                                    <option value="">Select Option</option>
                                    {productCategory.map((category, index) => (
                                        <option key={index} value={category?.productCategoryName}>{category?.productCategoryName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-5">
                                <label htmlFor="productDescription" className="block mb-2 text-sm font-medium text-gray-900">Product Description</label>
                                <textarea id="productDescription"  name='productDescription' rows="4" value={data.productDescription} onChange={(e) => handleChange(e)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded border border-primary " placeholder="" required></textarea>
                            </div>

                            <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
                                <button type="button" onClick={handleModal} className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-red-500 rounded-lg border border-gray-200 hover:bg-red-600">Cancel</button>
                                <button type="submit" disabled={loading} className={`${loading && 'cursor-not-allowed'} text-white bg-primary hover:bg-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
                                    {isEditing ? 'UPDATE' : 'ADD'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
