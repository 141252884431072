import { useEffect, useState } from 'react';
import './styles/App.css';
import Home from './pages/user/home';
import Map from './pages/user/map';
import Product from './pages/user/products';
import Contact from './pages/user/contact';
import Login from './pages/user/login';
import ForgotPassword from './pages/user/forgot-password';
import About from './pages/user/about';
import Loader from "./components/shared/loader";
import NotFound from "./pages/not-found";
import { Toaster } from 'react-hot-toast';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Register from './pages/user/register';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import User from './pages/Admin/UserAdministration/user';
import ProtectedRoute from './utils/protected-routes';
import Company from './pages/Admin/UserAdministration/company';
import OnlineShop from './pages/Admin/Services/online-shop';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CheckTokenAndLogout } from './utils/token-check';
import TransferProduct from './pages/Admin/Services/transfer-product';
import TransferCoupon from './pages/Admin/Services/transfer-coupon';
import ReTransferCoupon from './pages/Admin/Services/re-transfer-coupon';
import ApproveOrder from './pages/Admin/Services/approve-order';
import MyOrder from './pages/Admin/Services/my-orders';
import ShowCouponCheckPoints from './pages/Admin/Services/show-coupon-check-points';
import ShowCouponStatus from './pages/Admin/Services/show-coupon-status';
import Country from './pages/Admin/UserAdministration/country';
import Unit from './pages/Admin/ProductAdministrator/units';
import Category from './pages/Admin/ProductAdministrator/category';
import Products from './pages/Admin/ProductAdministrator/product';
import ProductSupplier from './pages/Admin/ProductAdministrator/product-supplier';
import Packages from './pages/Admin/PackageAdministrator/packages';
import Orders from './pages/Admin/OrderAdministrator/orders';
import PackagePriceOption from './pages/Admin/PackageAdministrator/package-price-option';
import PackageDetail from './pages/Admin/PackageAdministrator/package-detail';
import ResendCode from './pages/user/resend-code';
import Cart from './pages/Admin/Checkout/cart';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import SecureHome from './pages/Admin/secure-home';
import Profile from './pages/Admin/UserAdministration/profile';

function App() {
  const [cookies, , removeCookie] = useCookies(['agile_user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    CheckTokenAndLogout(cookies, navigate, dispatch, removeCookie);
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);
  
  if (!isLoaded) {
    return <Loader/>
  }

  return (
    <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID }}> 
      <Toaster />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/map" exact element={<Map />} />
        <Route path="/products" exact element={<Product />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/about" exact element={<About />} />

        {/* Admin Routes Start */}
        {/* Services */}
        <Route path="/account/securehome" exact element={<ProtectedRoute cookies={cookies}><SecureHome /></ProtectedRoute>} />

        {/* Services */}
        <Route path="/account/cart" exact element={<ProtectedRoute cookies={cookies}><Cart /></ProtectedRoute>} />
        <Route path="/account/shop" exact element={<ProtectedRoute cookies={cookies}><OnlineShop /></ProtectedRoute>} />
        <Route path="/account/transferPackages" exact element={<ProtectedRoute cookies={cookies}><TransferProduct /></ProtectedRoute>} />
        <Route path="/account/transferCoupon" exact element={<ProtectedRoute cookies={cookies}><TransferCoupon /></ProtectedRoute>} />
        <Route path="/account/retransferCoupon" exact element={<ProtectedRoute cookies={cookies}><ReTransferCoupon /></ProtectedRoute>} />
        <Route path="/account/approveOrder" exact element={<ProtectedRoute cookies={cookies}><ApproveOrder /></ProtectedRoute>} />
        <Route path="/account/couponStatus" exact element={<ProtectedRoute cookies={cookies}><ShowCouponStatus /></ProtectedRoute>} />
        <Route path="/account/checkpoints" exact element={<ProtectedRoute cookies={cookies}><ShowCouponCheckPoints /></ProtectedRoute>} />
        <Route path="/account/myorders" exact element={<ProtectedRoute cookies={cookies}><MyOrder /></ProtectedRoute>} />

        {/* User Adminsitrator */}
        <Route path="/account/myprofile" exact element={<ProtectedRoute cookies={cookies}><Profile /></ProtectedRoute>} />
        <Route path="/account/users" exact element={<ProtectedRoute cookies={cookies}><User /></ProtectedRoute>} />
        <Route path="/account/companies" exact element={<ProtectedRoute cookies={cookies}><Company /></ProtectedRoute>} />
        <Route path="/account/countries" exact element={<ProtectedRoute cookies={cookies}><Country /></ProtectedRoute>} />

        {/* Product Adminsitrator */}
        <Route path="/account/units" exact element={<ProtectedRoute cookies={cookies}><Unit /></ProtectedRoute>} />
        <Route path="/account/productcategories" exact element={<ProtectedRoute cookies={cookies}><Category /></ProtectedRoute>} />
        
        <Route path="/account/products" exact element={<ProtectedRoute cookies={cookies}><Products /></ProtectedRoute>} />
        <Route path="/account/productsuppliers/:id" exact element={<ProtectedRoute cookies={cookies}><ProductSupplier /></ProtectedRoute>} />

        {/* Package Adminsitrator */}
        <Route path="/account/packages" exact element={<ProtectedRoute cookies={cookies}><Packages /></ProtectedRoute>} />
        <Route path="/account/packagedtls/:id" exact element={<ProtectedRoute cookies={cookies}><PackageDetail /></ProtectedRoute>} />
        <Route path="/account/packagepriceoptions/:id" exact element={<ProtectedRoute cookies={cookies}><PackagePriceOption /></ProtectedRoute>} />

        {/* Orders Adminsitrator */}
        <Route path="/account/orders" exact element={<ProtectedRoute cookies={cookies}><Orders /></ProtectedRoute>} />

        {/* Admin Routes End */}

        {/* Auth Routes */}
        <Route path="/login" exact element={<Login />} />
        <Route path="/register" exact element={<Register />} />
        <Route path="/forgotPassword" exact element={<ForgotPassword />} />
        <Route path="/resendCode" exact element={<ResendCode />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </PayPalScriptProvider>
  );
}

export default App;
