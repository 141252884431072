import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import CustomToaster from '../../../components/shared/customToaster';
import CreateProduct from '../../../components/forms/product-form';
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import { Page } from '../../../utils/pagination';
import axios from 'axios';
import { GrDatabase } from 'react-icons/gr';
import { Link, useParams } from 'react-router-dom';
import CreateProductSupplier from '../../../components/forms/product-supplier-form';
import { DeleteApi } from '../../../api/deleteApi';
import TableLoader from '../../../components/shared/table-loader';
import { GetApi, InsertApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';
import { AreAllFieldsFilled } from '../../../utils/form-validation';

const INIT_PRODUCTS = {
    companyName: '',
}

export default function ProductSupplier() {
    const [products, setProducts] = useState(INIT_PRODUCTS);
    const [productsList, setProductsList] = useState([]);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [productName, setProductName] = useState('');
    const [selectedValue, setSelectedValue] = useState({});
    const [loading, setLoading] = useState(false);

    const [cookie] = useCookies();
    const token = GetCookie(cookie);
    const { id } = useParams();

    const fetchProducts = async () => {
        try {
            const url = `/api/GetProducts?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                const prodName = res.find((data) => data.id == id);
                if(Object.keys(prodName).length > 0){
                    setProductName(prodName?.productName);
                }else{
                    CustomToaster(false, 'Product not found');
                }
            } 
        } catch (error) {
            CustomToaster(false, "Error fetching products data");
        }
    };

    const fetchProductSupplier = async () => {
        try {
            const url = `/api/GetProductSuppliers?productID=${id}&pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                setProductsList(res);
            } 
        } catch (error) {
            console.error("Error fetching products data: ", error);
            CustomToaster(false, "Error fetching products data");
        }
    };
    const fetchCompany = async () => {
        try {
            const url = `/api/GetCompanies?pageNumber=${Page.pageNumber}&pageSize=${1000}`;
            const res = await GetApi(token, url);
            const resData = res?.data;
            if(Array.isArray(resData)){
                setCompanyList(resData);
            }
        } catch (error) {
            console.error("Error fetching products data: ", error);
            CustomToaster(false, "Error fetching products data");
        }
    };

    const handleFetch = async() => {
        setLoading(true);
        await fetchProductSupplier();
        await fetchCompany();
        await SmoothDelay();
        setLoading(false);
    }

    useEffect(() => {
        fetchProducts();
        handleFetch();
    }, [id]);

    const handleForm = () => {
        setIsOpenForm(!isOpenForm);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(value)
        setProducts({
            ...products,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const payload = {
                companyId: selectedValue.companyID,
                companyName: selectedValue.companyName,
                companyProductId: isEditing ? products.companyProductId : 0,
                productId: isEditing ? products.productId : id,
            };

            if (!AreAllFieldsFilled(payload)) {
                CustomToaster(false, 'Please fill in all fields');
                return;
            }
            setLoading(true);
            
            const url = `/api/SaveProductSupplier`;
            const res = await InsertApi(token, url, payload);
            if(Object.keys(res).length <= 0){
                CustomToaster(false, 'Failed to add/edit product');
                return;
            }
            if (isEditing) {
                CustomToaster(true, 'Product edited successfully');
            }else{
                CustomToaster(true, 'Product added successfully');
            }
            handleFetch();
            setProducts(INIT_PRODUCTS);
            setSelectedValue({});
            setIsOpenForm(false);
            setIsEditing(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            CustomToaster(false, 'Failed to add/edit product');
        }
    };

    const handleEdit = (product) => {
        console.log("Product is: ", product);
        setProducts(product);
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleDelete = async(id) => {
        try {
            const res = await DeleteApi(id, 'api/DeleteProductSupplier', token);
            if(res?.data == 1){
                CustomToaster(true, 'Product deleted successfully');
                handleFetch();
            }else {
                CustomToaster(false, 'Failed to delete product');
            }
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to delete product');
        }
    };

    return (
        <NestedLayout>
            {isOpenForm && (
                <CreateProductSupplier data={companyList} loading={loading} isEditing={isEditing} selectedValue={selectedValue} setSelectedValue={setSelectedValue} handleModal={handleForm} handleSubmit={handleSubmit} handleChange={handleChange} />
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        Suppliers for product '{productName}'
                    </h1>
                    <div className='flex items-center justify-end px-2'>
                        <button onClick={handleForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                            ADD<FaPlus className='ms-2' />
                        </button>                  
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Supplier Name</th>
                            <th scope="col" className="px-6 py-3">Edit</th>
                            <th scope="col" className="px-6 py-3">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr className="bg-white border-b hover:bg-gray-50">
                                <td colSpan={3} className="py-5 w-full">
                                    <TableLoader/>
                                </td>
                            </tr>
                        ) : (
                            productsList.length <= 0 ? (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={4} className="w-full text-center px-6 py-4">
                                        No data to display
                                    </td>
                                </tr>
                            ) : (
                                productsList.map((data, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-6 py-4">{data.companyName}</td>                                    
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleEdit(data)}>
                                                <RiPencilFill />
                                            </button>
                                        </td> 
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleDelete(data.id)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )  
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={3} className="text-lg px-6 py-4">
                                {productsList.length} Total
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    );
}
