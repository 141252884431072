import React from 'react'
import { FaFacebookF, FaGooglePlusG, FaInstagram, FaTwitter } from 'react-icons/fa6'
import { Link, useLocation } from 'react-router-dom'
import SubFooter from './sub-footer';

export default function Footer() {
    const url = useLocation().pathname;
    return (
        <footer className="bg-success text-gray-200 relative z-20">
            <SubFooter/>
            <div className="mx-auto w-full max-w-screen-2xl p-4 pt-6 lg:pt-8">
                <div className="grid grid-cols-12 xl:mx-20 mx-5">
                    <div className="md:col-span-4 col-span-12 mb-6 md:mb-0 px-2">
                        <h6 className='text-primary text-lg font-bold'>About</h6>
                        <p className='text-sm'>It is a long established fact that a reader will be distracted
                            by the readable content of a page when looking at its layout.
                            The point of using Lorem Ipsum is that it has a more-or-less 
                            normal distribution of letters, as opposed.
                        </p>
                    </div>
                    <div className='md:col-span-2 sm:col-span-4 col-span-12 md:border-l-2 md:border-primary md:border-dashed md:px-5 sm:px-3 sm:mb-0 mb-5'>
                        <ul className="uppercase space-y-3 font-medium text-sm">
                            <li>
                                <Link to="/" className={`${url == "/" ? "text-primary" : "text-gray-200"} py-1 px-2 w-[100%] pb-1 uppercase transition-all duration-300 delay-300 ease-in-out`}>HOME</Link>
                            </li>
                            <li>
                                <Link to="/map" className={`${url == "/map" ? "text-primary" : "text-gray-200"} py-1 px-2 w-[100%] pb-1 uppercase transition-all duration-300 delay-300 ease-in-out`}>MAP</Link>
                            </li>
                            <li>
                                <Link to="/products" className={`${url == "/products" ? "text-primary" : "text-gray-200"} py-1 px-2 w-[100%] pb-1 uppercase transition-all duration-300 delay-300 ease-in-out`}>PRODUCTS</Link>
                            </li>
                            <li>
                                <Link to="/contact" className={`${url == "/contact" ? "text-primary" : "text-gray-200"} py-1 px-2 w-[100%] pb-1 uppercase transition-all duration-300 delay-300 ease-in-out`}>CONTACT</Link>
                            </li>
                            <li>
                                <Link to="/login" className={`${url == "/login" ? "text-primary" : "text-gray-200"} py-1 px-2 w-[100%] pb-1 uppercase transition-all duration-300 delay-300 ease-in-out`}>LOGIN</Link>
                            </li>
                            <li>
                                <Link to="/about" className={`${url == "/about" ? "text-primary" : "text-gray-200"} py-1 px-2 w-[100%] pb-1 uppercase transition-all duration-300 delay-300 ease-in-out`}>ABOUT</Link>
                            </li>
                        </ul>
                    </div>
                    <div className='md:col-span-3 sm:col-span-4 col-span-12 md:border-l-2 md:border-primary md:border-dashed md:px-5 sm:px-3 sm:mb-0 mb-5'>
                        <h2 className="text-lg font-bold text-primary uppercase">Offers</h2>
                        <p className='text-sm'>It is a long established fact that a reader will be distracted by the readable</p>
                    </div>
                    <div className='md:col-span-3 sm:col-span-4 col-span-12 md:border-l-2 md:border-primary md:border-dashed sm:px-5 sm:mb-0 mb-5w-[100%]'>
                        <div className=' flex space-x-2 justify-center'>
                            <a href="#" className='bg-gradient-to-br from-[#90a8b9] via-[#446f8a] to-[#206274] p-2 rounded-full'>
                                <FaGooglePlusG className='w-5 h-5 text-white'/>
                            </a>
                            <a href="#" className='bg-gradient-to-br from-[#90a8b9] via-[#446f8a] to-[#206274] p-2 rounded-full'>
                                <FaFacebookF className='w-5 h-5 text-white'/>
                            </a>
                            <a href="#" className='bg-gradient-to-br from-[#90a8b9] via-[#446f8a] to-[#206274] p-2 rounded-full'>
                                <FaTwitter className='w-5 h-5 text-white'/>
                            </a>
                            <a href="#" className='bg-gradient-to-br from-[#90a8b9] via-[#446f8a] to-[#206274] p-2 rounded-full'>
                                <FaInstagram className='w-5 h-5 text-white'/>
                            </a>
                        </div>
                    </div>

                </div>

            </div>
            <div className="text-center mt-5 mb-0 bg-[#333333] py-2">
                <span className="text-sm text-gray-400 sm:text-center">Copyright © 2024. All Rights Reserved.
                </span>
            </div>
        </footer>
    )
}
