import React from 'react'

export default function Loader() {
    return (
        <div className="bg-gray-800 flex justify-center items-center flex-row gap-2 h-[100vh] border">
            <div className="w-4 h-4 rounded-full bg-[#54a8a8] animate-bounce"></div>
            <div className="w-4 h-4 rounded-full bg-[#54a8a8] animate-bounce [animation-delay:-.3s]"></div>
            <div className="w-4 h-4 rounded-full bg-[#54a8a8] animate-bounce [animation-delay:-.5s]"></div>
        </div>
    )
}
