import React from 'react';

export function LeftBlack1({customClass1}) {
  return (
    // <div id="left-layout1" className='hidden lg:block relative z-10 2xl:h-[200vh] xl:h-[185vh] h-[170vh] 3xl:w-[600px] 2xl:w-[500px] xl:w-[350px] lg:w-[350px] w-[300px] bg-black'>
    <div id="left-layout1" className={`${customClass1} hidden lg:block relative z-10 bg-black`}>
    </div>
  );
};

export function LeftBlack2({customClass2}) {
    return (
      // <div id="left-layout1" className='hidden lg:block relative z-10 2xl:h-[200vh] xl:h-[185vh] h-[170vh] 3xl:w-[625px] 2xl:w-[525px] xl:w-[375px] lg:w-[375px] w-[325px] bg-black opacity-75'>
      <div id="left-layout1" className={`${customClass2} hidden lg:block relative z-10 bg-black opacity-75`}>
      </div>
    );
};


export function LeftBlack3({customClass3}) {
    return (
      <div id="left-layout1" className={`${customClass3} hidden lg:block relative z-10 bg-black opacity-60`}>
      </div>
    );
};
