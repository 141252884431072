import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaTrash } from "react-icons/fa";
import CustomToaster from '../../../components/shared/customToaster';
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import { Page } from '../../../utils/pagination';
import { useParams } from 'react-router-dom';
import TableLoader from '../../../components/shared/table-loader';
import CreateProductDetail from '../../../components/forms/package-detail-form';
import { GetApi, InsertApi, DeleteApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';
import { AreAllFieldsFilled } from '../../../utils/form-validation';

const INIT_PRODUCTS = {
    isActive: true,
    packageDtlID: 0,
    packageID: 0,
    productDescription: '',
    productID: 0,
};

export default function ProductSupplier() {
    const [product, setProduct] = useState(INIT_PRODUCTS);
    const [productsList, setProductsList] = useState([]);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [packageDetail, setPackageDetail] = useState([]);
    const [packageName, setPackageName] = useState('');
    const [selectedValue, setSelectedValue] = useState({});

    const [cookie] = useCookies();
    const token = GetCookie(cookie);
    const { id } = useParams();

    const fetchPackages = async () => {
        try {
            setLoading(true);
            const url = `/api/GetPackages?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)) {
                const pkgName = res.find(item => item.packageID == id).packageName;
                setPackageName(pkgName || []);
            } 
            await SmoothDelay();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            CustomToaster(false, "Error fetching orders data");
        }
    };

    const fetchProducts = async () => {
        try {
            const url = `/api/GetProducts?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                setProductsList(res);
            } 
        } catch (error) {
            console.error("Error fetching products data: ", error);
            CustomToaster(false, "Error fetching products data");
        }
    };

    const fetchPackageDetail = async () => {
        try {
            const url = `/api/GetPackageDtls?packageID=${id}&pageNumber=${Page.pageNumber}&pageSize=${1000}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                setPackageDetail(res);
            }
        } catch (error) {
            console.error("Error fetching products data: ", error);
            CustomToaster(false, "Error fetching products data");
        }
    };

    const handleFetch = async() => {
        setLoading(true);
        await fetchProducts();
        await fetchPackageDetail();
        await SmoothDelay();
        setLoading(false);
    }

    useEffect(() => {
        fetchPackages();
        handleFetch();
    }, [token]);

    const handleForm = () => {
        if(isOpenForm){
            setProduct(INIT_PRODUCTS);
        }
        setIsOpenForm(!isOpenForm);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({
            ...product,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            console.log("Product is: ", product);
            setLoading(true);
            const url = `${process.env.REACT_APP_SERVER_URL}/api/SavePackageDtl`;
            const payload = {
                ...product,
                packageID: id,
            };

            if (!AreAllFieldsFilled(payload)) {
                CustomToaster(false, 'Package name is required');
                setLoading(false);
                return;
            }
            const res = await InsertApi(token, url, payload);
            if(Object.keys(res).length <= 0){
                CustomToaster(false, 'Failed to add/edit product');
                setLoading(false);
                return;
            }
            if (!isEditing) {
                CustomToaster(true, 'Product added successfully');
            } else {
                CustomToaster(true, 'Product edited successfully');
            }
            handleFetch();
            setProduct(INIT_PRODUCTS);
            setIsOpenForm(false);
            setIsEditing(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            CustomToaster(false, 'Failed to add/edit product');
        }
    };

    const handleEdit = (item) => {
        const prodItem = item.product;
        setSelectedValue(prodItem);
        setProduct({
            isActive: item?.isActive,
            productID: item?.productID,
            productDescription: item?.productDescription,
            packageDtlID: item?.packageDtlID,
            packageID: item?.packageID
        });
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleDelete = async(id) => {
        try {
            const res = await DeleteApi(token, 'api/DeletePackageDtl', id);
            if(res?.data == 1){
                fetchPackageDetail();
                CustomToaster(true, 'Product deleted successfully');
            }else {
                CustomToaster(false, 'Failed to delete product');
            }
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to delete product');
        }
    };

    return (
        <NestedLayout>
            {isOpenForm && (
                <CreateProductDetail data={productsList} loading={loading} isEditing={isEditing} setProduct={setProduct} product={product} setSelectedValue={setSelectedValue} selectedValue={selectedValue} handleModal={handleForm} handleSubmit={handleSubmit} handleChange={handleChange} />
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        Suppliers for product '{packageName}'
                    </h1>
                    <div className='flex items-center justify-end px-2'>
                        <button onClick={handleForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                            ADD<FaPlus className='ms-2' />
                        </button>                  
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Product</th>
                            <th scope="col" className="px-6 py-3">Edit</th>
                            <th scope="col" className="px-6 py-3">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td colSpan={3} className="text-lg px-6 py-4">
                                    <TableLoader/>
                                </td>
                            </tr>
                        ) : (
                            packageDetail.length <= 0 ? (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={3} className="w-full text-center px-6 py-4">
                                        No data to display
                                    </td>
                                </tr>
                            ) : (
                                packageDetail.map((data, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-6 py-4">{data.productDescription}</td>                                    
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleEdit(data)}>
                                                <RiPencilFill />
                                            </button>
                                        </td> 
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleDelete(data.id)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={3} className="text-lg px-6 py-4">
                                {packageDetail.length} Total
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    );
}
