import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import CreatUnit from '../../../components/forms/unit-form';
import CustomToaster from '../../../components/shared/customToaster';
import { GetCookie } from '../../../utils/cookie';
import { useCookies } from 'react-cookie';
import { Page } from '../../../utils/pagination';
import { GetApi, InsertApi, DeleteApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';
import { AreAllFieldsFilled } from '../../../utils/form-validation';

const INIT_UNIT = {
    description: '',
    unitID: 0,
    number: 0,
    unitType: '',
}

export default function Unit() {
    const [search, setSearch] = useState('');
    const [unit, setUnit] = useState(INIT_UNIT);
    const [unitList, setUnitList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);
    
    const fetchUnits = async () => {
        try {
            setLoading(true);
            const url = `/api/GetUnits?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                setUnitList(res);
                const data = res.filter((data) =>
                    data.description.toLowerCase().includes(search.toLowerCase()));
                setFilteredData(data);
            } 
            await SmoothDelay();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            CustomToaster(false, "Error fetching unit data");
        }
    };

    useEffect(() => {
        fetchUnits();
    }, [token]);
    
    useEffect(() => {
        const data = unitList.filter((data) =>
            data.description.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(data);
    }, [search]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleForm = () => {
        if(isOpenForm){
            setUnit(INIT_UNIT);
        }
        setIsOpenForm(!isOpenForm);
    }

    const handleAdd = (e) => {
        const { name, value } = e.target;
        setUnit(prevUnit => ({ ...prevUnit, [name]: value }));
    }
    // SaveUnit
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            if (!AreAllFieldsFilled(unit)) {
                CustomToaster(false, 'Please fill in all fields');
                setLoading(false);
                return;
            }

            const url = '/api/SaveUnit';
            const res = await InsertApi(token, url, unit);
            console.log(res)
            if(Object.keys(res).length > 0){
                if (isEditing) {
                    CustomToaster(true, 'Unit edited successfully');
                } else {
                    CustomToaster(true, 'Unit added successfully');
                }
                fetchUnits();
                setUnit(INIT_UNIT);
                setIsOpenForm(false);
                setIsEditing(false);
            }else{
                CustomToaster(false, 'Failed to add/edit unit');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            CustomToaster(false, 'Failed to add/edit unit');
        }
    };

    const handleEdit = (item) => {
        setUnit({
            unitID: item.id,
            description: item.description,
            unitNumber: item.number,
            unitType: item.unitType
        });
        // setUnit(item);
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleDelete = async (id) => {
        try {
            const res = await DeleteApi(token, 'api/DeleteUnit', id);
            if(res?.data == 1){
                fetchUnits();
                CustomToaster(true, 'unit deleted successfully');
            }else {
                CustomToaster(false, 'Failed to delete unit');
            }
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to delete unit');
        }
    };

    return (
        <NestedLayout>
            {isOpenForm && (
                <CreatUnit unit={unit} isEditing={isEditing} handleModal={handleForm} handleSubmit={handleSubmit} handleChange={handleAdd}/>
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        Unit
                    </h1>
                    <div className='flex items-center justify-between px-2'>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <FaSearch className='w-4 h-4 text-gray-500' />
                            </div>
                            <input type="text" onChange={(e => handleSearch(e))} className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50" placeholder="Search Here" />
                        </div>

                        <button onClick={handleForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                            ADD<FaPlus className='ms-2' />
                        </button>                  
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Description
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Unit Number
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Unit Type
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Edit
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.length <= 0 ? (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td colSpan={4} className="w-full text-center px-6 py-4">
                                    No data to display
                                </td>
                            </tr>
                        ) : (
                            filteredData.map((data, index) => (
                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="px-6 py-4">
                                        {data.description}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data.number}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data.unitType}
                                    </td>
                                    <td className="px-6 py-4">
                                        <button type='button' onClick={() => handleEdit(data)}>
                                            <RiPencilFill />
                                        </button>
                                    </td> 
                                    <td className="px-6 py-4">
                                        <button type='button' onClick={() => handleDelete(data.id)}>
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={4} className="text-lg px-6 py-4">
                                {filteredData.length} Total
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    )
}
