import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import CreatCategory from '../../../components/forms/category-form';
import CustomToaster from '../../../components/shared/customToaster';
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import { Page } from '../../../utils/pagination';
import TableLoader from '../../../components/shared/table-loader';
import { GetApi, InsertApi, DeleteApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';
import { AreAllFieldsFilled } from '../../../utils/form-validation';

const INIT_CATEGORY = {
    productCategoryName: '',
    description: '',
}

export default function ProductCategory() {
    const [category, setCategory] = useState(INIT_CATEGORY);
    const [editCategory, setEditCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [search, setSearch] = useState('');
    const [isOpenForm, setIsOpenForm] = useState(false);    
    const [tableData, setTableData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [cookie] = useCookies();
    const token = GetCookie(cookie);

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const url = `/api/GetProductcategories?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            if(Array.isArray(res)){
                setCategoryList(res);
                const data = res.filter((data) =>
                    data.productCategoryName.toLowerCase().includes(search.toLowerCase()) || data.description.toLowerCase().includes(search.toLowerCase()))
                setFilteredData(data);
            } 
            await SmoothDelay();
            setLoading(false);
        } catch (error) {
            console.error("Error fetching category data: ", error);
            CustomToaster(false, "Error fetching category data");
        }
    };

    useEffect(() => {
        fetchCategories();
    }, [token]);
    
    useEffect(() => {
        const data = categoryList.filter((data) =>
            data.productCategoryName.toLowerCase().includes(search.toLowerCase()) || data.description.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(data);
    }, [search]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleForm = () => {
        if(isOpenForm){
            setCategory(INIT_CATEGORY);
            setIsEditing(false);
        }
        setIsOpenForm(!isOpenForm);
    }

    const handleAdd = (e) => {
        const { name, value } = e.target;
        setCategory({
            ...category,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {            
            setLoading(true);
            
            
            const url = `${process.env.REACT_APP_SERVER_URL}/api/SaveProductcategory`;
            
            if (!AreAllFieldsFilled(category)) {
                CustomToaster(false, 'Please fill in all fields');
                setLoading(false);
                return;
            }

            const payload = {
                description: category.description,
                isActive: true,
                pictureFilename: '',
                productCategoryID: isEditing ? editCategory.productCategoryID : 0,
                productCategoryName: category.productCategoryName
            }
            const res = await InsertApi(token, url, payload);
            console.log(res)
            if(Object.keys(res).length > 0){
                if(isEditing){
                    CustomToaster(true, 'Product Category updated successfully');
                }else{
                    CustomToaster(true, 'Product Category added successfully');
                }
                fetchCategories();
                setCategory(INIT_CATEGORY);
                setIsOpenForm(false);
                setIsEditing(false);
            }else {
                CustomToaster(false, 'Failed to add Product Category');
            }
        } catch (error) {
            CustomToaster(false, 'Failed to add/edit Product Category');
        }
    };


    const handleEdit = (item) => {
        setCategory({
            productCategoryName: item.productCategoryName,
            description: item.description,
        });
        setEditCategory(item);
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleDelete = async(id) => {
        try {
            setLoading(true);
            const res = await DeleteApi(token, 'api/DeleteProductcategory', id);
            if(res.data == 1){
                fetchCategories();
                CustomToaster(true, 'Product deleted successfully');
            }else{
                CustomToaster(false, 'Failed to delete product');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            CustomToaster(false, 'Failed to delete product');
        }
    };

    return (
        <NestedLayout>
            {isOpenForm && (
                <CreatCategory isEdit={isEditing} data={category} handleModal={handleForm} handleSubmit={handleSubmit} handleChange={handleAdd}/>
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                        Product Categories
                    </h1>
                    <div className='flex items-center justify-between px-2'>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <FaSearch className='w-4 h-4 text-gray-500' />
                            </div>
                            <input type="text" onChange={(e => handleSearch(e))} className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded w-80 bg-gray-50" placeholder="Search Here" />
                        </div>

                        <button onClick={handleForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                            ADD<FaPlus className='ms-2' />
                        </button>                  
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Category Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Description
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Edit
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={4} className='py-5'>
                                    <TableLoader/>
                                </td>
                            </tr>
                        ) : (
                            filteredData.length <= 0 ? (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={4} className="w-full text-center px-6 py-4">
                                        No data to display
                                    </td>
                                </tr>
                            ) : (
                                filteredData.map((data, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-6 py-4">
                                            {data.productCategoryName}
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.description}
                                        </td>
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleEdit(data)}>
                                                <RiPencilFill />
                                            </button>
                                        </td> 
                                        <td className="px-6 py-4">
                                            <button type='button' onClick={() => handleDelete(data.id)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )
                        )}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td colSpan={4} className="text-lg px-6 py-4">
                                {filteredData.length} Total Entries
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </NestedLayout>
    )
}
