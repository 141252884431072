import { FaMinus, FaPlus } from "react-icons/fa";

export default function CarTable({cartList, setCartList}) {
    const handleCartQty = (value, type) => {
        const updatedCartList = cartList.map((item) => {
            if (item.description === value) {
                const updatedQuantity = type ? item.quantity + 1 : item.quantity - 1;
                return {
                    ...item, // Spread existing item properties
                    quantity: updatedQuantity,
                    total: (updatedQuantity * parseFloat(item.unitPrice)).toFixed(2) // Ensure unitPrice is a number
                };
            }
            return item; // Return other items unchanged
        });
    
        setCartList(updatedCartList);
    };
    return(
        <div className="relative overflow-x-auto my-10">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Item#
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Description
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Quantity
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Unit Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cartList.length === 0 ? (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td colSpan="5" className="px-6 py-4 text-center">No item in cart</td>
                        </tr>
                    ) : (
                        cartList && cartList.map((item, index) => (
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-6 py-4">
                                    {item.description}
                                </td>
                                <td className="px-6 py-4">
                                    <div>
                                        <button type='button' onClick={() => handleCartQty(item.description, false)}>
                                            <FaMinus />
                                        </button>
                                        <span className="px-2 text-black">{item.quantity}</span>
                                        <button type='button' onClick={() => handleCartQty(item.description, true)}>
                                            <FaPlus />
                                        </button>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    {item.unitPrice}
                                </td>
                                <td className="px-6 py-4">
                                    {item.total}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>

    )
}