import React from 'react';
import NestedLayout from '../../../layout/nested-layout';
import { GiCrossMark } from 'react-icons/gi';
import { cartData } from '../../../components/shared/data';
import { useDispatch } from 'react-redux';
import { addItem } from '../../../store/cart-slice';

function DetailModal({ handleModal, subscriptionTitle }) {
  return (
    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center">
      <div className="relative p-4 w-full max-w-lg max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="bg-primary flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {subscriptionTitle}
            </h3>
            <button
              type="button"
              onClick={() => handleModal(0)}
              className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <GiCrossMark className="text-xl" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            <p>The most flexible subscription</p>
          </div>
          <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
            <button
              type="button"
              onClick={() => handleModal(0)}
              className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-pink rounded-lg border border-gray-200 hover:bg-pink-600"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function OnlineShop() {
  const [isOpenDetail, setIsOpenDetail] = React.useState(0);
  const [currentTitle, setCurrentTitle] = React.useState('');
  const dispatch = useDispatch();


  const handleModal = (id, title = '') => {
    setIsOpenDetail(id);
    setCurrentTitle(title);
  };

  const handleAddToCart = (cart) => {
    dispatch(addItem(cart));
    console.log(cart);
  };
  return (
    <NestedLayout>
      {isOpenDetail !== 0 && (
        <DetailModal handleModal={handleModal} subscriptionTitle={currentTitle} />
      )}
      <section className="grid xl:grid-cols-4 lg:grid-cols-3 xs:grid-cols-2 grid-cols-1  gap-3">
        {cartData.map((item) => (
          <div key={item.id} className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="flex justify-center mb-2">
              <span className="text-xl">€</span>
              <h1 className="text-5xl font-bold">{item.price}</h1>
            </div>
            <p className="mb-3 text-pink-600 text-xl font-semibold">{item.name}</p>
            <p className="text-gray-600">
              Package type: <span className="text-black font-bold">{item.type}</span>
            </p>
            <div className="mt-3 text-lg space-y-2">
              <button type="button" onClick={() => handleModal(item.id, item.name)} className="w-full bg-primary py-1 font-semibold text-white rounded px-2">
                Detail
              </button>
              <button type="button" onClick={() => handleAddToCart(item)} className="w-full bg-pink rounded px-4 py-1 font-semibold text-white uppercase">
                Add to Cart
              </button>
            </div>
          </div>
        ))}
      </section>
    </NestedLayout>
  );
}
