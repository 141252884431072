import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomToaster from '../shared/customToaster';
import axios from 'axios';
import { GetCookie } from '../../utils/cookie';
import { useCookies } from 'react-cookie';

const INIT_USER={
    userName: '',
    firstname: '',
    surname: '',
    gender: 'Male',
    email: '',
    phone: '',
    password: '',
    roles: [0]
}

export default function RegisterForm() {
    const [user, setUser] = useState(INIT_USER);
    const [loading, setLoading] = useState(false);
    const [cookie] = useCookies();

    const cookieData = GetCookie(cookie);

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    };

    const handleGenderChange = (e) => {
        setUser({
            ...user,
            gender: e.target.value
        });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        // Validation
        const { userName, surname, gender, email, phone, password } = user;

        if (!userName || !surname || !email || !phone || !password) {
            CustomToaster(false, 'Please fill in all fields');
            return;
        }

        if (password.length < 8) {
            CustomToaster(false, 'Password must be at least 6 characters long');
            return;
        }

        try {
            setLoading(true);
            const url = `${process.env.REACT_APP_SERVER_URL}/api/SaveUser`;
            const res = await axios.post(url, user, {
                headers: {
                    Authorization: `Bearer ${cookieData.token}`
                }
            });
            console.log(res);
            if(res.status == 200){
                CustomToaster(true, 'User added successfully');
            }
            setUser(INIT_USER);
            setLoading(false);
            CustomToaster(true, 'Account created successfully');
        } catch (error) {
            setLoading(false);
            console.error(error);
            CustomToaster(false, 'An error occurred, please try again');
        }

        console.log(user);
    };

    return (
        <form className="space-y-4 md:space-y-1" onSubmit={handleSubmit}>
            <div>
                <label htmlFor="name" className="block mb-1 text-sm font-medium text-primary dark:text-white">Name:</label>
                <input type="name" name="userName" id="name" value={user.userName} onChange={handleChange} className="bg-gray-50 border-2 border-primary text-gray-900 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
            </div>
            <div className='pb-2'>
                <label htmlFor="surname" className="block mb-1 text-sm font-medium text-primary dark:text-white">Surname:</label>
                <input type="surname" name="surname" id="surname" value={user.surname} onChange={handleChange} className="bg-gray-50 border-2 border-primary text-gray-900 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            </div>

            <div className="flex items-center space-x-2">
                <label className="relative flex items-center cursor-pointer">
                    <input className="sr-only peer" onChange={handleGenderChange} checked={user.gender} defaultChecked name="futuristic-radio" type="radio" />
                    <div
                    className="w-4 h-4 bg-transparent border-2 border-primary rounded-full peer-checked:bg-primary peer-checked:border-primary transition duration-300 ease-in-out"
                    ></div>
                    <span className="ml-2 text-primary">Male</span>
                </label>
                <label className="relative flex items-center cursor-pointer">
                    <input className="sr-only peer" onChange={handleGenderChange} name="futuristic-radio" type="radio" />
                    <div
                    className="w-4 h-4 bg-transparent border-2 border-primary rounded-full peer-checked:bg-primary peer-checked:border-primary transition duration-300 ease-in-out"
                    ></div>
                    <span className="ml-2 text-primary">Female</span>
                </label>
            </div>

            <div className="pt-2">
                <label htmlFor="email" className="block mb-1 text-sm font-medium text-primary dark:text-white">Email address:</label>
                <input type="email" name="email" id="email" value={user.email} onChange={handleChange} className="bg-gray-50 border-2 border-primary text-gray-900 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
            </div>
            <div>
                <label htmlFor="phone" className="block mb-1 text-sm font-medium text-primary dark:text-white">Phone number:</label>
                <input type="text" name="phone" id="phone" value={user.phone} onChange={handleChange} className="bg-gray-50 border-2 border-primary text-gray-900 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            </div>
            <div>
                <label htmlFor="password" className="block mb-1 text-sm font-medium text-primary dark:text-white">Password:</label>
                <input type="password" name="password" id="password" value={user.password} onChange={handleChange} placeholder='at least 8 characters' className="bg-gray-50 border-2 border-primary text-gray-900 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
            </div>
            <button type="submit" disabled={loading} style={{marginTop: '10px'}} className="w-full font-bold text-white bg-btnColor hover:bg-warning hover:text-btnColor px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                Create Your Account
            </button>
            
            <p className="text-sm font-light text-primary text-center pt-2 dark:text-gray-400">
                Already have an Account? <Link to="/login" className="text-md font-bold text-black underline uppercase">Login</Link>
            </p>
        </form>
    )
}
