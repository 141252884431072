import React from 'react'
import Cart from './chectout/add-to-cart'
import Profile from './profile'
import Language from './language'
import { Link } from 'react-router-dom'
import { TbMenuDeep } from 'react-icons/tb'

export default function Navbar({sidebarOpen, handleSidebar}) {
  return (
    <nav className='flex justify-between items-center bg-primary rounded lg:px-10 px-5 mb-5'>
        <div className='lg:hidden flex text-white items-center space-x-2'>
          <button type='button' onClick={handleSidebar}>
            <TbMenuDeep className='text-3xl'/>
          </button>
          <Link to='/account/securehome' className='font-medium text-xl'>Home</Link>
        </div>
        <Link to='/account/securehome' className='lg:block hidden font-medium text-xl text-white'>Home</Link>
        <div className='flex items-center space-x-3'>
          <Profile />
          <Cart />
          <Language />
        </div>
    </nav>
  )
}
