import React, { useEffect, useState } from 'react'
import NestedLayout from '../../layout/nested-layout'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Page } from '../../utils/pagination';
import { GetCookie } from '../../utils/cookie';
import CustomToaster from '../../components/shared/customToaster';
import { GiCrossMark } from 'react-icons/gi';
import AvailablePackage from '../../json/available-packages.json'
import TableLoader from '../../components/shared/table-loader';

function DetailModal({ handleModal, selectedPkg }) {
    return (
      <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center">
        <div className="relative p-4 w-full max-w-lg max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="bg-primary flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {selectedPkg.packageName}
              </h3>
              <button
                type="button"
                onClick={() => handleModal(0)}
                className="text-black bg-gray-300 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <GiCrossMark className="text-xl" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
              <p>{selectedPkg?.packageDtls[0]?.productDescription}</p>
            </div>
            <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
              <button
                type="button"
                onClick={() => handleModal(0)}
                className="py-2.5 px-5 me-3 text-sm font-medium text-white focus:outline-none bg-pink rounded-lg border border-gray-200 hover:bg-pink-600"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
} 


export default function SecureHome() {
    const [packageList, setPackageList] = useState([]);
    const [isOpenDetail, setIsOpenDetail] = React.useState(0);
    const [selectedPkg, setSelectedPkg] = useState({});
    const [loading, setLoading] = useState(false);
    const [cookie] = useCookies();
    const cookieData = GetCookie(cookie);

    const fetchPkg = async () => {
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/GetUserPackages?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${cookieData}`
                }
            });
            const resData = res?.data;
            if(res.status === 200) {
                console.log(resData);
                setPackageList(resData);
            } 
        } catch (error) {
            console.error("Error fetching packages data: ", error);
            CustomToaster(false, "Error fetching packages data");
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchPkg();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [cookieData]);

    const handleModal = (id) => {
        const selected = AvailablePackage.find((item) => item.packageID === id);
        if(selected) {
            setSelectedPkg(selected);
        }
        setIsOpenDetail(id);
    };
    return (
        <NestedLayout>
            {loading ? (
                    <TableLoader/>
                ) : (
                <>
                    {isOpenDetail !== 0 && (
                        <DetailModal handleModal={handleModal} selectedPkg={selectedPkg} />
                    )}
                    <section className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-3">
                        {packageList && packageList.map((item) => (
                            <div key={item.id} className="w-full bg-white border border-gray-200 rounded shadow">
                                <div className="text-center mb-2 py-2 bg-primary rounded-bl-2xl rounded-br-2xl">
                                    <h1 className="text-3xl font-bold text-secondary">{item.packageName}</h1>
                                </div>
                                <div className='p-6 text-lg'>
                                    <p className="text-gray-600">
                                        Quantity: <span className="text-gray-600 text-sm font-semibold">{item.quantity}</span>
                                    </p>
                                    <p className="text-gray-600">
                                        Duration: <span className="text-gray-600 text-sm font-semibold">{item.unitNumber}</span>
                                    </p>
                                    <p className="text-gray-600">
                                        Validation: <span className="text-gray-600 text-sm font-semibold">
                                        {new Date(item.validFrom).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })} - {new Date(item.validTo).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            })}
                                        </span>
                                    </p>
                                </div>
                                <hr />
                                <div className='px-5 py-2'>
                                    <button type="button" onClick={() => handleModal(item.packageID)} className="w-full bg-primary hover:bg-secondary py-1 font-semibold text-white rounded px-2 transition-all ease-in-out delay-150 duration-150">
                                        Detail
                                    </button>
                                </div>
                            </div>
                        ))}
                    </section>
                </>
                )    
            }
        </NestedLayout>
    )
}
