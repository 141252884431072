import React, { useEffect, useRef, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { useSelector } from 'react-redux';
import { PayPalButtons } from "@paypal/react-paypal-js";
import CarTable from '../../../components/dashboard/chectout/cartTable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GetCookie } from '../../../utils/cookie';
import { useCookies } from 'react-cookie';
import CustomToaster from '../../../components/shared/customToaster';
import Invoice from '../../../components/dashboard/chectout/invoices';

const INIT_CART_TABLE = {
    description: '',
    quantity: 0,
    price: 0,
    total: 0,
}

export default function Cart() {
    const [cartList, setCartList] = useState([INIT_CART_TABLE]);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [payPalConfig, setPayPalConfig] = useState({});
    const [isPaid, setIsPaid] = useState(false);
    const [cookie] = useCookies();

    const cookieData = GetCookie(cookie);
    const navigate = useNavigate();
    const addToCartData = useSelector((state) => state.cart.items);
    
    const paymentClick = async (transactionId, orderId) => {
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/CreateOrder`;
            const payload = {
                cartList,
                transactionId,
                orderId
            };
            const res = await axios.post(url, payload, {
                headers: {
                    Authorization: `Bearer ${cookieData.token}` // Ensure cookieData is available
                }
            });
    
            if (res.status === 200) {
                CustomToaster(true, `An order with ID ${res.data.orderID} was created successfully.`);
                navigate('/order-success', { state: { orderId: res.data.orderID } });
            }
        } catch (err) {
            alert('An error occurred');
        }
    };    

    useEffect(() => {
        const aggregatedCart = addToCartData.reduce((acc, item) => {
            const existingItem = acc.find((cartItem) => cartItem.description === item.name);
            if (existingItem) {
                existingItem.quantity += 1;
                existingItem.total = (existingItem.quantity * item.price).toFixed(2); 
            } else {
                acc.push({
                    description: item.name,
                    quantity: 1,
                    unitPrice: item.price,
                    total: item.price
                });
            }
            return acc;
        }, []);

        console.log(addToCartData);

        setCartList(aggregatedCart);
    }, [addToCartData]);

    useEffect(() => {
        const newSubTotal = cartList.reduce((acc, item) => acc + parseFloat(item.total), 0);
        setSubTotal(newSubTotal);

        if(discount === 0) {
            setTotalCost(newSubTotal);
        }else{
            const newTotalCost = ((newSubTotal - discount)/newSubTotal) * 100;
            setTotalCost(newTotalCost);
        }
    }, [cartList, discount]);

    return (
        <NestedLayout>
            <section className='p-10'>
                <div className='p-5 bg-gray-50 shadow-sm rounded'>

                    <div className='flex justify-between'>
                        <span></span>
                        <h1 className='text-4xl font-bold'>Check-out</h1>
                        
                        <Invoice cartList={cartList} totalCost={totalCost} subTotal={subTotal} discount={discount} />
                    </div>

                    <h1 className='text-xl font-medium text-gray-700'>XCRM</h1>
                    <div className='grid grid-cols-2 gap-5 mt-5'>
                        <div className='pe-10'>
                            <div className='flex items-center'>
                                <h1 className='text-lg font-medium'>INVOICE FROM:</h1>
                                <p className='ps-3'>MaterialWrap</p>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center'>
                                <h1 className='text-lg font-medium'>INVOICE TO:</h1>
                                <p className='ps-3'>Dribbble Inc.</p>
                            </div>
                        </div>
                    </div>

                    <CarTable cartList={cartList} setCartList={setCartList} />

                    <div className='grid grid-cols-2 my-5'>
                        <div>
                            <h1 className='text-xl'>XCRM (c) 2018</h1>
                        </div>
                        <div className='text-sm text-gray-800'>
                            <div className='flex items-center'>
                                <span className=''>SUB TOTAL:</span>
                                <span>€{subTotal}</span>
                            </div>
                            <div className='flex items-center'>
                                <span>DISCOUNT:</span>
                                <span>€{discount.toFixed(2)}</span>
                            </div>
                            <div className='flex items-center'>
                                <span className='text-lg font-medium'>TOTAL DUE:</span>
                                <span className='ps-2 text-gray-600'>€{totalCost.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-start space-x-4'>

                        <PayPalButtons
                            fundingSource="paypal"
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [{
                                    amount: {
                                        value: "50.00", // Your payment amount
                                    },
                                    }],
                                });
                            }}
                            onApprove={async (data, actions) => {
                                const details = await actions.order.capture();
                                alert(`Transaction completed by ${details.payer.name.given_name}`);
                                setIsPaid(true);
                            }}
                            onError={(err) => {
                            console.error(err);
                            }}
                        />
                        <button type='button' onClick={paymentClick} disabled={!isPaid} className={`${!isPaid ? 'bg-gray-700' : 'bg-pink'} text-white h-10 px-10 py-2 rounded uppercase`}>Bank Deposit</button>
                    </div>

                </div>
            </section>
        </NestedLayout>
    )
}
