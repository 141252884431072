import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import CreateCompany from '../../../components/forms/create-company';
import { useCookies } from 'react-cookie';
import { GetCookie } from '../../../utils/cookie';
import axios from 'axios';
import CustomToaster from '../../../components/shared/customToaster';
import { Page } from '../../../utils/pagination';
import { LuGalleryVerticalEnd } from 'react-icons/lu';
import TableLoader from '../../../components/shared/table-loader';
import GalleryModal from '../../../components/dashboard/company/gallery';
import { SmoothDelay } from '../../../components/shared/delay';
import { AreAllFieldsFilled } from '../../../utils/form-validation';
import { GetApi, InsertApi, DeleteApi } from '../../../api/services';
import { isEmail, isPostalCode } from 'validator';

const INIT_COMPANY = {
    companyID: 0,
    companyName: '',
    contactName: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
    phone: '',
    fax: '',
    homePage: '',
    email: '',
    lat: '',
    lng: '',
    productCategoryID: 0,
}

export default function Company() {
    const [company, setCompany] = useState(INIT_COMPANY);
    const [companyList, setCompanyDataList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [search, setSearch] = useState('');
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [galleryData, setGalleryData] = useState(null);
    const [cookie] = useCookies();

    const token = GetCookie(cookie);

    const fetchCategories = async () => {
        try {
            const url = `/api/GetProductcategories?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const resData = await GetApi(token, url);
            if(Array.isArray(resData)){
                setCategoryList(resData);
                setCompany({
                    ...company,
                    productCategoryID: resData[0].productCategoryID
                })
            } 
        } catch (error) {
            console.error("Error fetching category data: ", error);
            CustomToaster(false, "Error fetching category data");
        }
    };

    const fetchCompany = async () => {
        try {
            const url = `/api/GetCompanies?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(token, url);
            const resData = res?.data;
            if(Array.isArray(resData)){
                setCompanyDataList(resData);
                const data = resData.filter((data) =>
                    data.contactName.toLowerCase().includes(search.toLowerCase())
                );
                setFilteredData(data);
            } 
        } catch (error) {
            console.error("Error fetching user data: ", error);
            CustomToaster(false, "Error fetching company data");
        }
    };
    const handleFetchData = async () => {
        setLoading(true);
        await fetchCategories();
        await fetchCompany();
        await SmoothDelay(500);
        setLoading(false);
    };

    useEffect(() => {
        handleFetchData();
    }, [token]);
    
    useEffect(() => {
        const data = companyList.filter((data) =>
            data.contactName.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(data);
    }, [search]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleCompanyForm = () => {
        if(isOpenForm){
            setCompany(INIT_COMPANY);
        }
        setIsOpenForm(!isOpenForm);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompany({
            ...company,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try{
            console.log(company);
            if(!isEmail(company.email)) {
                CustomToaster(false, 'Invalid email address');
                return;
            }

            if (!AreAllFieldsFilled(company)) {
                CustomToaster(false, 'Please fill in all fields');
                return;
            }

            setLoading(true);
            const url = `${process.env.REACT_APP_SERVER_URL}/api/SaveCompany`;
            const payload = {
                ...company,
                region: '',
                pictureFilename: '',
                pictureUri: '',
            }
            const res = await InsertApi(token, url, payload);
            console.log(res);
            if(res.status == 200){
                if (!isEditing) {
                    CustomToaster(true, 'Company added successfully');
                } else {
                    CustomToaster(true, 'Company edited successfully');
                }
            }
            fetchCompany();
            setIsEditing(false);
            setCompany(INIT_COMPANY);
            setIsOpenForm(false);
            setLoading(false);
        }catch(error){
            setLoading(false);
            console.log(error);
            CustomToaster('error', 'Error', 'Failed to add country');
        }
    }

    const handleEdit = (item) => {
        setCompany({
            companyID: item.companyID,
            companyName: item.companyName,
            contactName: item.contactName,
            address: item.address,
            city: item.city,
            postalCode: item.postalCode,
            country: item.country,
            phone: item.phone,
            fax: item.fax,
            homePage: item.homePage,
            email: item.email,
            lat: item.lat,
            lng: item.lng,
            productCategoryID: item.productCategoryID,
        });
        setIsOpenForm(true);
        setIsEditing(true);
    };

    const handleDelete = async(id) => {
        try {
            const res = await DeleteApi(token, 'api/DeleteCompany', id);
            if(res?.data == 1){
                fetchCompany();
                CustomToaster(true, 'Company deleted successfully');
            }else {
                CustomToaster(false, 'Failed to delete Company');
            }
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to delete Company');
        }
    };

    const fetchCompanyDetails = async (data) => {
        try {
            const url = `/api/GetCompanyDetails`;
            const resData = await InsertApi(token, url, data);
            if(Object.keys(resData).length > 0){
                setGalleryData(resData);
            } else{
                setIsGalleryOpen(false);
            }
        } catch (error) {
            console.error("Error fetching user data: ", error);
            CustomToaster(false, "Error fetching company data");
        }
    }; 

    const handleGallery = (data) => {
        setIsGalleryOpen(!isGalleryOpen);
        if(!isGalleryOpen){
            fetchCompanyDetails(data);
        }
    }

    return (
        <NestedLayout>
            {isGalleryOpen && (
                <GalleryModal token={token} company={galleryData} setIsGalleryOpen={setIsGalleryOpen} />
            )}
            {isOpenForm && (
                <CreateCompany company={company} categoryList={categoryList} isEditing={isEditing} setCompany={setCompany} handleModal={handleCompanyForm} handleSubmit={handleSubmit} handleCompanyChange={handleChange}/>
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
                    Companies in database
                </h1>
                <div className="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white">
                    <div className="relative">
                        <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <FaSearch className='w-4 h-4 text-gray-500' />
                        </div>
                        <input type="text" onChange={(e => handleSearch(e))} className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded w-80 bg-gray-50" placeholder="Search Here" />
                    </div>

                    <button onClick={handleCompanyForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                        ADD<FaPlus className='ms-2' />
                    </button>                  
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Company Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Contact Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Phone
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Edit
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Gallary
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                            <tr className="bg-white border-b hover:bg-gray-50">
                                <td colSpan={7} className="px-6 py-4">
                                    <TableLoader/>
                                </td>
                            </tr>
                        ) : (
                            filteredData.length <= 0 ? (
                                <tr className="bg-white border-b hover:bg-gray-50">
                                    <td colSpan={7} className="px-6 py-4 text-center">
                                        No data found
                                    </td>
                                </tr>
                            ) : 
                            (filteredData.map((data, index) => (
                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="px-6 py-4">
                                        {data.companyName}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data.contactName}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data.email}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data.phone}
                                    </td>
                                    <td className="px-6 py-4">
                                        <button type='button' onClick={() => handleEdit(data)}>
                                            <RiPencilFill/>
                                        </button>
                                    </td>
                                    <td className="px-6 py-4">
                                        <button type='button' onClick={() => handleGallery(data)}>
                                            <LuGalleryVerticalEnd/>
                                        </button>
                                    </td>
                                    <td className="px-6 py-4">
                                        <button type='button' onClick={() => handleDelete(data.companyID)}>
                                            <FaTrash/>
                                        </button>
                                    </td>
                                </tr>
                            )))
                        )}
                    </tbody>
                    <tfoot>
                        <tr className="bg-white border-b hover:bg-gray-50">
                            <td colSpan={7} className="px-6 py-4">
                                Show {filteredData.length === 0 ? '0' : `1 to ${filteredData.length}`} entries
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </NestedLayout>
    )
}
