export const CreateCustomPin = (logoUrl) => {
    //   <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 256 256">
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(1.81 1.81)">
        <path d="M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z" 
              style="fill: black;" />
        <image href="${logoUrl}" x="30" y="10" height="55px" width="30px" />
      </g>
    </svg>
  `;
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
};