import React from 'react';
import Layout from '../../layout/index';
import Logo2 from '../../assets/logo/black-logo.png';
import LoginForm from '../../components/auth/login-form';
import { ResponsiveDesc } from '../../components/common/description';

export default function login() {
    return (
        <Layout customClass1="2xl:h-[180vh] xl:h-[185vh] h-[180vh] 3xl:w-[600px] 2xl:w-[500px] xl:w-[350px] lg:w-[350px] w-[300px]" customClass2="2xl:h-[180vh] xl:h-[185vh] h-[180vh] 3xl:w-[625px] 2xl:w-[525px] xl:w-[375px] lg:w-[375px] w-[325px]" customClass3=" 2xl:h-[180vh] xl:h-[185vh] h-[180vh] 3xl:w-[650px] 2xl:w-[550px] xl:w-[400px] lg:w-[400px] w-[350px]">
            <section id="login-bg-img" className="bg-cover bg-center 2xl:h-[150vh] xl:h-[150vh] lg:min-h-screen h-full pb-20 relative -mt-16 flex xl:flex-col lg:flex-row flex-col items-center xl:justify-center lg:justify-end justify-center px-6 py-8 pt-20">
                <div className="relative z-20 w-full shadow dark:border md:mt-20 2xl:max-w-md sm:max-w-sm xl:p-0">
                    <hr className='h-[80px] -mb-10 bg-warning opacity-80' id="custom-header-style" />
                    <div className="bg-white p-6 space-y-4 md:space-y-6 sm:p-8" id="custom-login-div">
                        <div className='text-center'>
                            <img src={Logo2} className='w-24 mx-auto object-cover' alt="" />
                            <h1 className="text-xl leading-tight tracking-tight text-primary md:text-2xl dark:text-white">
                                Sign in to your account
                            </h1>
                            <h1 className="text-xl font-bold uppercase leading-tight tracking-tight text-primary md:text-2xl dark:text-white">
                                Login
                            </h1>
                        </div>
                        <LoginForm/>
                    </div>
                    <hr className='h-[30px] bg-warning opacity-80 ' />
                </div>
                {/* <div className='lg:hidden sm:flex justify-between items-center space-y-4 w-full mt-10'>
                    <div className='sm:w-[400px] w-full lg:bg-transparent bg-black rounded-lg lg:p-0 p-5'>
                        <h2 className='text-white lg:text-4xl text-4xl font-bold'>Fit and Cultured</h2>
                        <p className='text-primary'>
                            Συνδύασε τη γυμναστική σου 
                            με τη διασκέδαση. Επέλεξε το
                            πακέτο που σε ενδιαφέρει και
                            κέρδισε έκπτωσεις σε πολιτιστικούς
                            χώρους και δρόμενα.
                        </p>
                        <button className='mt-3 bg-primary rounded text-white px-4 uppercase'>Learn More &rsaquo; &rsaquo;</button>
                    </div>
                    <div className='bg-black rounded-lg lg:p-0 p-5'>
                        <h2 className='text-white lg:text-4xl text-4xl font-bold'>Get 24h</h2>
                        <h2 className='text-white lg:text-4xl text-4xl font-bold mt-2'>FREE TRIAL</h2>
                        <button className='mt-3 bg-primary rounded text-white px-4 uppercase'>Join Now &rsaquo; &rsaquo;</button>
                    </div>
                </div> */}
            </section>
            <ResponsiveDesc/>
            <div className='lg:absolute lg:block hidden z-20 3xl:left-32 left-10'>
                <div className='3xl:-mt-[550px] 2xl:-mt-[550px] xl:-mt-[350px] lg:-mt-[350px] lg:left-auto lg:relative xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]'>
                    <h2 className='text-white 2xl:text-6xl lg:text-4xl text-4xl font-bold mt-5'>Get 24h</h2>
                    <h2 className='text-white 2xl:text-6xl lg:text-4xl text-4xl font-bold mt-2'>FREE TRIAL</h2>
                    <button className='custom-btns mt-3 bg-primary text-white px-4 uppercase'>Join Now &rsaquo; &rsaquo;</button>
                </div>
                <div className='3xl:w-[230px] 2xl:w-[250px] xl:w-[200px] w-[200px] 3xl:-mt-[700px] 2xl:-mt-[700px] xl:-mt-[500px] lg:-mt-[600px] lg:bg-transparent bg-black rounded-lg lg:p-0 p-5 xl:me-[200px] md:me-[100px] sm:me-[50px] ms-[30px]'>
                    <h2 className='text-white 3xl:text-5xl lg:text-4xl text-4xl mt-5'>Fit and Cultured</h2>
                    <p className='text-primary'>
                        Συνδύασε τη γυμναστική σου 
                        με τη διασκέδαση. Επέλεξε το
                        πακέτο που σε ενδιαφέρει και
                        κέρδισε έκπτωσεις σε πολιτιστικούς
                        χώρους και δρόμενα.
                    </p>
                    <button className='custom-btns mt-3 bg-primary text-white px-4 uppercase'>Learn More &rsaquo; &rsaquo;</button>
                </div>
            </div>
        </Layout>
    )
}
