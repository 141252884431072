import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.user = action.payload;
        },
        updateUser: (state, action) => {
            state.user = { ...state.user, ...action.payload };
        },
        deleteUser: (state, action) => {
            if (state.user?.id === action.payload) {
                state.user = null;
            }
        },
        clearUser: (state) => {
            state.user = null;
        },
    },
});

export const { addUser, updateUser, deleteUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
