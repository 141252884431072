import React, { useState } from 'react'
import Logo1 from '../../assets/logo/logo1.png';
import Logo2 from '../../assets/logo/logo2.png';
import { Link, useLocation } from 'react-router-dom';
import { FaGooglePlusG, FaSearch } from "react-icons/fa";
import { GiCrossMark, GiHamburgerMenu } from "react-icons/gi";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function LargeScreenNavbar({url}){
    const [searchText, setSearchText] = useState('');

    return(
        <nav className="bg-[#0a2229] opacity-85 xl:px-20 lg:px-10">
            <div className="max-w-screen-3xl 3xl:px-20 px-4 py-3 mx-auto">
                <div className="flex items-center justify-between">
                    <div className="flex justify-center items-center left-20 top-7 me-24 2xl:w-72 w-48">
                        {/* <img src={Logo1} className='w-7 me-2' alt="Logo" />
                        <img src={Logo2} className='w-16' alt="" /> */}
                    </div>
                    <div className='flex items-center justify-between w-full'>
                        <div className='flex'>
                            <div className="flex">
                                <a href="#" className="bg-[#010f17] me-1 p-2 rounded-full">
                                    <FaGooglePlusG className='w-5 h-5 text-[#21373c] hover:text-primary'/>
                                </a>
                                <a href="#" className="bg-[#010f17] me-1 p-2 rounded-full">
                                    <FaFacebookF className="w-5 h-5 text-[#21373c] hover:text-primary cursor-pointer" />
                                </a>
                                <a href="#" className="bg-[#010f17] me-1 p-2 rounded-full">
                                    <FaTwitter className="w-5 h-5 text-[#21373c] hover:text-primary cursor-pointer" />
                                </a>
                                <a href="#" className="bg-[#010f17] p-2 rounded-full">
                                    <FaInstagram className="w-5 h-5 text-[#21373c] hover:text-primary cursor-pointer" />
                                </a>
                            </div>
                            <div className="relative flex items-center ms-4">
                                <input type="search" onChange={(e) => setSearchText(e.target.value)} className="block xl:w-40 w-32 px-3 text-sm placeholder:text-black placeholder:font-medium outline-none" placeholder="Search" />
                                {searchText.length > 0 ? null : (
                                    <div className="absolute inset-y-0 end-0 flex items-center pe-3">
                                        <FaSearch className='text-sm' />
                                    </div>
                                )}
                            </div>
                        </div>
                        <ul className="flex flex-row font-[Myriad Pro] font-bold mt-0 xl:space-x-4 space-x-2 rtl:space-x-reverse text-sm">
                            <li>
                                <Link to="/" className={`${url == "/" ? "text-primary" : "text-white"} pb-1 hover:text-primary uppercase transition-all duration-300 delay-300 ease-in-out`}>
                                    HOME
                                    <span className={`${url == "/" ? 'block': 'hidden'} flex w-2 h-1.5 me-3 bg-primary rounded-full`}></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/map" className={`${url == "/map" ? "text-primary" : "text-white"} pb-1 hover:text-primary uppercase transition-all duration-300 delay-300 ease-in-out`}>
                                    MAP
                                    <span className={`${url == "/map" ? 'block': 'hidden'} flex w-2 h-1.5 me-3 bg-primary rounded-full`}></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/products" className={`${url == "/products" ? "text-primary" : "text-white"} pb-1 hover:text-primary uppercase transition-all duration-300 delay-300 ease-in-out`}>
                                    PRODUCTS
                                    <span className={`${url == "/products" ? 'block': 'hidden'} flex w-2 h-1.5 me-3 bg-primary rounded-full`}></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact" className={`${url == "/contact" ? "text-primary" : "text-white"} pb-1 hover:text-primary uppercase transition-all duration-300 delay-300 ease-in-out`}>
                                    CONTACT
                                    <span className={`${url == "/contact" ? 'block': 'hidden'} flex w-2 h-1.5 me-3 bg-primary rounded-full`}></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/login" className={`${url == "/login" ? "text-primary" : "text-white"} pb-1 hover:text-primary uppercase transition-all duration-300 delay-300 ease-in-out`}>
                                    LOGIN
                                    <span className={`${url == "/login" ? 'block': 'hidden'} flex w-2 h-1.5 me-3 bg-primary rounded-full`}></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/about" className={`${url == "/about" ? "text-primary" : "text-white"} pb-1 hover:text-primary uppercase transition-all duration-300 delay-300 ease-in-out`}>
                                    ABOUT
                                    <span className={`${url == "/about" ? 'block': 'hidden'} flex w-2 h-1.5 me-3 bg-primary rounded-full`}></span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

function SmallScreenNavbar({url}){
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }
    return (
        <nav className="bg-success md:px-10 relative">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto xs:p-4 p-2">
                <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={Logo1} className='xs:w-8 w-6 xs:me-2 me-0' alt="Logo" />
                    <img src={Logo2} className='xs:w-20 w-14' alt="" />
                </Link>
                <button type="button" onClick={handleMenu} className="bg-primary inline-flex items-center xs:p-2 p-1 xs:w-10 w-8 xs:h-10 h-8 justify-center lg:hidden">
                    {isMenuOpen ? <GiCrossMark className='w-5 h-5 text-white' /> : <GiHamburgerMenu className='w-5 h-5 text-white' />}
                </button>
                {isMenuOpen && (
                    <div className="w-full lg:w-auto absolute sm:top-20 top-10 left-0">
                        <div className='md:px-10 md:py-5 sm:p-5 xs:px-5 px-2 pt-5'>
                            <ul className="font-medium flex flex-col p-4 border border-gray-100 rounded bg-gray-50 space-y-2">
                                <li>
                                    <Link to="/" className={`${url == "/" ? "bg-primary text-white" : "text-succes"} block p-2 hover:bg-primary hover:text-white rounded uppercase transition-all duration-300 delay-300 ease-in-out`}>HOME</Link>
                                </li>
                                <li>
                                    <Link to="/map" className={`${url == "/map" ? "bg-primary text-white" : "text-succes"} block p-2 hover:bg-primary hover:text-white rounded uppercase transition-all duration-300 delay-300 ease-in-out`}>MAP</Link>
                                </li>
                                <li>
                                    <Link to="/products" className={`${url == "/products" ? "bg-primary text-white" : "text-succes"} block p-2 hover:bg-primary hover:text-white rounded uppercase transition-all duration-300 delay-300 ease-in-out`}>PRODUCTS</Link>
                                </li>
                                <li>
                                    <Link to="/contact" className={`${url == "/contact" ? "bg-primary text-white" : "text-succes"} block p-2 hover:bg-primary hover:text-white rounded uppercase transition-all duration-300 delay-300 ease-in-out`}>CONTACT</Link>
                                </li>
                                <li>
                                    <Link to="/login" className={`${url == "/login" ? "bg-primary text-white" : "text-succes"} block p-2 hover:bg-primary hover:text-white rounded uppercase transition-all duration-300 delay-300 ease-in-out`}>LOGIN</Link>
                                </li>
                                <li>
                                    <Link to="/about" className={`${url == "/about" ? "bg-primary text-white" : "text-succes"} block p-2 hover:bg-primary hover:text-white rounded uppercase transition-all duration-300 delay-300 ease-in-out`}>ABOUT</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    )
}


export default function NavHeader() {
    const url = useLocation().pathname;
  return (
    <div className='relative lg:z-10 z-50'>
        <div className='lg:block hidden'>
            <LargeScreenNavbar url={url}/>
        </div>
        <div className='lg:hidden block'>
            <SmallScreenNavbar url={url}/>
        </div>
    </div>
  )
}
