import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus, FaSearch } from 'react-icons/fa'
import { GetCookie } from '../../../utils/cookie';
import { useCookies } from 'react-cookie';
import CustomToaster from '../../../components/shared/customToaster';
import axios from 'axios';
import { Page } from '../../../utils/pagination';
import TableLoader from '../../../components/shared/table-loader';
import { GetApi } from '../../../api/services';
import { SmoothDelay } from '../../../components/shared/delay';

const INIT_SEARCH = {
  dateFrom: null,
  dateTo: null,
  couponCode: '',
  checkResult: 0,
  couponUserId: '',
  companyId: '',
}

export const CheckResultEnum = {
  0: 'Success',
  1: 'BadEncryption',
  2: 'BadFormat',
  3: 'NotValidCoupon',
  4: 'NotActivatedCoupon',
  5: 'ExpiredCoupon',
  6: 'WrongPackage',
  7: 'NotActiveUser',
  8: 'ExpiredQr',
};


export default function ShowCouponCheckPoints() {
  const [couponList, setCouponList] = useState([]);
  const [search, setSearch] = useState(INIT_SEARCH);
  const [loading, setLoading] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);

  const [cookie] = useCookies();
  const token = GetCookie(cookie);

  const fetchCouponPoints = async () => {
    try {
      setLoading(true);
      const url = '';
      if(firstFetch){
        url = `/api/GetAllCheckPoints?dateFrom=${Date.now()}&dateTo=${Date.now()}&couponCode=${search.couponCode}&checkResult=${search.checkResult}&couponUserId=${search.couponUserId}&companyId=${search.companyId}&pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
      }else{
        url = `/api/GetAllCheckPoints?dateFrom=${search.dateFrom}&dateTo=${search.dateTo}&couponCode=${search.couponCode}&checkResult=${search.checkResult}&couponUserId=${search.couponUserId}&companyId=${search.companyId}&pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
      }
      const res = await GetApi(token, url);
      if(Array.isArray(res)){
        setCouponList(res);
      } 
    } catch (error) {
      if(firstFetch){
        CustomToaster(false, "Error fetching coupon data");
      }else{
        CustomToaster(false, "No data found");
      }
    }finally{
      await SmoothDelay();
      setFirstFetch(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCouponPoints();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value
    });
  }

  const handleSearch = async() => {
    try{

      if(search.couponCode == '' || search.dateFrom == null || search.dateTo == null){
        if(search.couponCode == '' && (search.dateFrom == null || search.dateTo == null)){
          CustomToaster(false, "Please enter a search dates and coupon code");
        }else{
          CustomToaster(false, "Please enter a coupon code");
        }
        return;
      }
      await fetchCouponPoints();
    }catch(error){
      CustomToaster(false, "Error fetching coupon data");
    }
  }

  function getCheckResultDescription(status) {
    return CheckResultEnum[status];
  }
  return (
    <NestedLayout>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex-column flex-wrap md:flex-row space-y-4 md:space-y-2 px-2 py-4 bg-white dark:bg-gray-900">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-5">
              All Coupon Check Points
            </h1>
            <div className='flex items-center justify-between lg:space-x-4 space-x-2'>
              <div className='w-full'>
                <label htmlFor="dateFrom" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date From</label>
                <input type="date" id="dateFrom" onChange={handleChange} name='dateFrom' className="block w-full p-2 text-gray-900 border border-primary rounded bg-gray-50 text-sm focus:outline-none"/>
              </div>
              <div className='w-full'>
                <label htmlFor="dateTo" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date To</label>
                <input type="date" id="dateTo" onChange={handleChange} name='dateTo' className="block w-full p-2 text-gray-900 border border-primary rounded bg-gray-50 text-sm focus:outline-none"/>
              </div>
              <div className='w-full'>
                <label htmlFor="couponCode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Coupon Code</label>
                <input type="text" id="couponCode" onChange={handleChange} name='couponCode' className="block w-full p-2 text-gray-900 border border-primary rounded bg-gray-50 text-sm focus:outline-none"/>
              </div>

              <div className='h-full'>
                <br />
                <button type="button" onClick={handleSearch} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-2">
                    Search<FaSearch className='ms-2' />
                </button>                  
              </div>
            </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-5">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Check Data
                    </th>
                    <th scope="col" className="px-6 py-3">
                        UserName
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Company Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Coupon Code
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Check Result
                    </th>
                </tr>
            </thead>
            <tbody>
                {loading ? (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td colSpan={5} className="w-full text-center px-6 py-5">
                            <TableLoader/>
                        </td>
                    </tr>
                  ) : (
                    couponList.length <= 0 ? (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <td colSpan={4} className="w-full text-center px-6 py-4">
                              No data to display
                          </td>
                      </tr>
                  ) : (
                      couponList.map((data, index) => (
                          <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <td className="px-6 py-4">
                                {new Date(data.dateCreated).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                })}
                              </td>
                              <td className="px-6 py-4">
                                  {data.username}
                              </td>
                              <td className="px-6 py-4">
                                  {data.companyName}
                              </td>
                              <td className="px-6 py-4">
                                  {data.couponCode}
                              </td>
                              <td className="px-6 py-4">
                                  {getCheckResultDescription(data.checkResult)}
                              </td>
                          </tr>
                      ))
                    )
                  )}
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td colSpan={4} className="text-lg px-6 py-4">
                        {couponList.length} Total
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </NestedLayout>
  )
}
