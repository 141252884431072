export const dropdownData = [
    {id: 1, name: 'Supplier 1'},
    {id: 2, name: 'Supplier 2'},
    {id: 3, name: 'Supplier 3'},
    {id: 4, name: 'Supplier 4'},
];

export const packageData = [
    {id: 1, name: 'Package/Option'},
    {id: 2, name: 'Gym subscription 1 - Month'},
    {id: 3, name: 'Gym subscription 2 - Year'},
]


export const cartData = [
    {id: 1, name: 'Gym subscription 1', price: "20.00", type: 'Month'},
    {id: 2, name: 'Gym subscription 2', price: "100.00", type: 'Year'},
]


export const transferCouponData = [
    {id: 1, name: 'Gym subscription 1 - Month'},
    {id: 2, name: 'Gym subscription 2 - Year'},
]
