import React, { useEffect, useRef } from 'react'
import { FaChevronDown } from 'react-icons/fa'

const demoData = [
    {id: 1, lang: 'English'},
    {id: 2, lang: 'Urdu'},
    {id: 3, lang: 'Arabic'},
    {id: 4, lang: 'Spanish'},
]

export default function Dropdown() {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    }

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div ref={dropdownRef} className="relative inline-block text-left font-normal">
            <div>
                <button type="button" onClick={handleToggle} className="inline-flex items-center w-full bg-white px-3 py-0.5 text-sm font-semibold text-gray-900 shadow-sm">
                    Choose Language 
                    <FaChevronDown className='w-2.5 h-3 ms-3' />
                </button>
            </div>
            {isOpen && (
                <div className="absolute right-0 z-50 mt-2 w-56 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1" role="none">
                        {demoData.map((item) => (
                            <button key={item.id} type="button" className="block px-4 py-2 text-sm text-gray-700 hover:bg-secondary hover:text-white text-start w-full transition-all ease-in-out delay-150 duration-150">
                                {item.lang}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
