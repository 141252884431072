import React, { useEffect, useRef, useState } from "react";
import NestedLayout from '../../../layout/nested-layout'
import { BiChevronDown } from "react-icons/bi";
import {packageData} from '../../../components/shared/data'
import { useCookies } from 'react-cookie'
import { GetCookie } from '../../../utils/cookie'
import { Page } from '../../../utils/pagination'
import axios from 'axios'
import CustomToaster from '../../../components/shared/customToaster'
import TableLoader from "../../../components/shared/table-loader";
import { GetApi, InsertApi } from "../../../api/services";
import { SmoothDelay } from "../../../components/shared/delay";

const INIT_APPROVE_ORDER = {
    OrderID: 0,
    Comments: '',
}

const INIT_ALL_ORDER = {
    dateFrom: Date.now(),
    dateTo: Date.now(),
    orderNumber: '',
    status: 0,
    userId: 0,
}

function UserDropdown({user, setFetchAllOrder}) {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState('Resaller to transfer');
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef();
    
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const toggleDropdownOption = (value) => {
        try{
            setData(value);
            setIsOpen(false);
            setSearchTerm("");
            setFetchAllOrder({
                ...INIT_ALL_ORDER,
                userId: user.find(u => u.username === value).userId
            });
        }catch(error){
            console.error("Error selecting user: ", error);
            CustomToaster(false, "Error selecting user");
        }
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    }

    const filteredUsers = user.filter((u) =>
        u.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) { setIsOpen(false); }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div ref={dropdownRef}>
            <button onClick={toggleDropdown} className="flex justify-between items-center w-full text-black border-b-2 border-gray-800 bg-transparent hover:bg-gray-50 font-medium text-sm py-2.5 text-center" type="button">
                <span>{data}</span> 
                <BiChevronDown/>
            </button>
            {isOpen && (
                <div id="dropdown" className="z-10 absolute bg-white divide-y divide-gray-100 rounded shadow w-full">
                    <input type="text" onInput={handleSearch} className="bg-gray-50 border-b border-gray-600 text-gray-900 text-sm rounded-ss rounded-se block w-full p-2.5" placeholder="Search" required />
                    <ul className="py-2 text-sm text-gray-700">
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((item, index) => (
                                <li key={index}>
                                    <button onClick={() => toggleDropdownOption(item.username)} className="block text-start w-full px-4 py-2 hover:bg-gray-100">
                                        {item.username} - {item.email}
                                    </button>
                                </li>
                            ))
                        ) : (
                            <li>
                                <p className="ps-2 w-full">No Result</p>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}

function OrderDropdown({ allOrders, selectedValue, setSelectedValue, onSelect }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleDropdownOption = (item) => {
        setSelectedValue(item);
        setIsOpen(false);
        onSelect(item);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const formatDate = (date) => {
        if (!date || isNaN(new Date(date))) {
            return "Invalid Date";
        }
        return new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }).format(new Date(date));
    };
    
    const getTotalAmount = (order) => {
        return order.ordersDtls.reduce((total, item) => total + item.price, 0);
    };
      
    return (
        <div ref={dropdownRef} className="relative">
            <button
                onClick={toggleDropdown}
                className="flex justify-between items-center w-full text-gray-900 border-b-2 border-gray-900 font-medium text-sm py-2.5 text-center"
                type="button"
            >
                <span>
                    {selectedValue == "" ? "Select Order" : (`Date: ${formatDate(selectedValue.orderDate)} Amount: ${getTotalAmount(selectedValue).toFixed(2)}`)}
                </span>
                <BiChevronDown className="text-xl ms-2" />
            </button>
            {isOpen && (
                <div className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full">
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                        {allOrders.map((item, index) => (
                            <li key={index}>
                                <button onClick={() => handleDropdownOption(item)} className="block w-full text-start px-4 py-2 hover:bg-gray-100">
                                    Date: {formatDate(item.orderDate)} Amount: {getTotalAmount(item).toFixed(2)}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

const OrderStatusEnum = {
    0: 'Pending',
    1: 'Completed',
    2: 'Rejected'
}

export default function TransferProduct() {
    const [selectedOrder, setSelectedOrder] = useState("");
    const [approveOrder, setApproveOrder] = useState(INIT_APPROVE_ORDER);
    const [fetchAllOrder, setFetchAllOrder] = useState(INIT_ALL_ORDER);
    const [userOrders, setUserOrders] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [filterUser, setFilterUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cookie] = useCookies();
    const token = GetCookie(cookie);
    
    const fetchUserOrders = async () => {
        try {
            const url = `/api/GetUsersWithOrders`;
            const res = await GetApi(token, url);
            if (Array.isArray(res)) {
                const currentUser = res.find(
                    (user) => user.username === token.username
                );
    
                setUserOrders(res);    
                if (currentUser) {
                    setFilterUser([currentUser]);
                    setFetchAllOrder((prevState) => ({
                        ...prevState,
                        userId: currentUser.userId,
                    }));
                    return currentUser.userId;
                }
            }
        } catch (error) {
            CustomToaster(false, "Error fetching user data");
        }
    };

    const fetchAllOrders = async (userId) => {
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/GetAllOrders?dateFrom=${fetchAllOrder.dateFrom}&dateTo=${fetchAllOrder.dateTo}&orderNumber=${fetchAllOrder.orderNumber}&status=${fetchAllOrder.status}&userId=${userId}`;
            const res = await GetApi(token, url);
            const resData = res?.data;
            if (Array.isArray(resData)) {
                setAllOrders(resData);
            }else{
                CustomToaster(false, "Failed to fetch order data");
            }
        } catch (error) {
            CustomToaster(false, "Error fetching order data");
        }
    };

    const handleFetch = async () => {
        setLoading(true);
        const userId = await fetchUserOrders();
        if (userId) {
            setFetchAllOrder((prevState) => ({
                ...prevState,
                userId: userId,
            }));
            await fetchAllOrders(userId);
        }
        await SmoothDelay();
        setLoading(false);
    };

    useEffect(() => {
        handleFetch();
    }, [token]);

    const handleSelect = (value) => {
        console.log("Selected:", value);
        setApproveOrder((prevState) => ({
            ...prevState,
            OrderID: value.orderID,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setApproveOrder({ ...approveOrder, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (approveOrder.OrderID == 0 || approveOrder.Comments == "") {
            CustomToaster(false, 'Please fill all fields');
            return;
        }
    
        setLoading(true);
    
        try {
            const url = `/api/ApprovePendingOrder`;
            const res = await InsertApi(token, url, approveOrder);
            if (res.data === 1) {
                CustomToaster(true, 'Order approve successfully');
                setApproveOrder(INIT_APPROVE_ORDER);
                handleFetch(); 
            } else {
                if(res.data != 0){
                    
                }else {
                    CustomToaster(false, 'Failed to approve order!');
                }
            }
        } catch (error) {
            console.error(error);
            const msg = error?.response?.data?.message || 'Failed to approve order!';
            CustomToaster(false, msg);
        } finally {
            setLoading(false);
        }
    };
      
    return (
        <NestedLayout>
            <form action="" onSubmit={handleSubmit}>
                <div className='bg-gray-50 py-10 px-5 rounded'>
                    <h1 className='text-gray-700 text-center text-4xl font-bold'>Transfer packages to supplier</h1>
                    {loading ? (
                        <div className='flex justify-center items-center h-96'>
                            <TableLoader/>
                        </div>
                    ) : (
                        <>
                            <div className='grid grid-cols-12 gap-2 mt-5'>
                                <div className='col-span-2 flex items-center'>
                                    <h1 className='text-gray-500 font-semibold'>Resaller/user:</h1>
                                </div>
                                <div className='col-span-10 relative'>
                                    <UserDropdown user={filterUser} setFetchAllOrder={setFetchAllOrder} />
                                </div>
                            </div>
                            <div className='grid grid-cols-12 gap-2 mt-5'>
                                <div className='col-span-2 flex items-center'>
                                    <h1 className='text-gray-500 font-semibold'>Order:</h1>
                                </div>
                                <div className='col-span-10'>
                                    <OrderDropdown allOrders={allOrders} selectedValue={selectedOrder} setSelectedValue={setSelectedOrder} onSelect={handleSelect}/>
                                </div>
                            </div>
                            <div className='grid grid-cols-12 gap-2 mt-5'>
                                <div className='col-span-2 flex items-center'>
                                    <h1 className='text-gray-500 font-semibold'>Comment:</h1>
                                </div>
                                <div className='col-span-10'>
                                    <textarea id="message" rows="4" name="Comments" value={approveOrder.Comments} onChange={handleChange} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded border-[2px] border-black focus:ring-black focus:border-black" placeholder="Your message..."></textarea>
                                </div>
                            </div>
                            <button type='submit' className='mt-5 block mx-auto bg-pink text-white rounded px-5 py-2 uppercase'>Approve</button>
                        </>
                    )}
                </div>
            </form>
        </NestedLayout>
    )
}
