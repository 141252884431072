import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown } from "react-icons/fa";

const languageData = [
    { id: 1, name: 'English' },
    { id: 2, name: 'Ελληνικά' },
    { id: 3, name: 'German' },
    { id: 4, name: 'French' },
    { id: 5, name: 'Italian' },
]

export default function Language(){
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('English');

    const handleLanguage = (name) => {
        setSelectedLanguage(name);
        setIsOpen(false);
    }

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return(
        <div ref={dropdownRef} className='relative'>
            <button id="dropdownDefaultButton" onClick={() => setIsOpen(!isOpen)} className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                {selectedLanguage} 
                <FaChevronDown className="text-xs ms-2" />
            </button>
            {isOpen && (
                <div id="dropdown" className="absolute top-10 z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-[auto]">
                    <ul className="py-2 text-md font-semibold text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                        {languageData.map((data) => (
                            <li key={data.id} className='px-2'>
                                <button onClick={() => handleLanguage(data.name)} className="flex items-center px-4 py-1 hover:bg-primary hover:text-white rounded w-full">
                                    {data.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}
