import React, { useEffect, useState } from 'react'
import NestedLayout from '../../../layout/nested-layout'
import { FaPlus } from "react-icons/fa6";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch, FaTrash } from "react-icons/fa";
import CreateUser from '../../../components/forms/create-user';
import { ClearCookie, GetCookie, GetCookieUser } from '../../../utils/cookie';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import TableLoader from '../../../components/shared/table-loader';
import { Page } from '../../../utils/pagination';
import CustomToaster from '../../../components/shared/customToaster';
import { AreAllFieldsFilled } from '../../../utils/form-validation';
import { SmoothDelay } from '../../../components/shared/delay';
import { GetApi, InsertApi, DeleteApi } from '../../../api/services';

const INIT_USER = {
    userId: 0,
    firstname: '',
    surname: '',
    username: '',
    email: '',
    gender: 'Male',
    birthDate: '',
    mobilePhone: '',
    userlevel: 'User',
    companyID: '',
    isActive: true,
    defaultDiscount: 0,
    picture: '',
    isMainAdministrator: false,
}

export default function User() {
    const [user, setUser] = useState(INIT_USER);
    const [userData, setUserData] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [search, setSearch] = useState('');
    const [isOpenUserForm, setIsOpenUserForm] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [selectCompany, setSelectCompany] = useState('');
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [cookie] = useCookies();

    const cookieData = GetCookie(cookie);

    const fetchUsers = async () => {
        try {
            const url = '/api/GetUsers';
            const res = await GetApi(cookieData, url);
            const userData = res?.data;
            if(Array.isArray(userData)){
                setUserData(userData);
                const data = userData.filter((data) =>
                    data.username.toLowerCase().includes(search.toLowerCase())
                );
                setFilteredData(data);
            } 
        } catch (error) {
            console.error("Error fetching user data: ", error);
            CustomToaster(false, "Error fetching user data");
        }
    };

    const fetchCompany = async () => {
        try {
            const url = `/api/GetCompanies?pageNumber=${Page.pageNumber}&pageSize=${Page.pageSize}`;
            const res = await GetApi(cookieData, url);
            const resData = res?.data;
            if(Array.isArray(resData)){
                setCompanyList(resData);
                setUser({
                    ...user,
                    companyID: resData[0].companyID
                })
            } 
        } catch (error) {
            console.error("Error fetching user data: ", error);
            CustomToaster(false, "Error fetching company data");
        }
    };
    const handleFetchData = async () => {
        setLoading(true);
        await fetchUsers(); 
        await fetchCompany();
        await SmoothDelay(500);
        setLoading(false);
    };
    
    useEffect(() => {
        handleFetchData();
    }, [cookieData]);

    useEffect(() => {
        const data = userData?.filter((data) =>
            data.username.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(data);
    }, [search]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleUserForm = () => {
        if(isOpenUserForm){
            setUser(INIT_USER);
            setIsEditing(false);
        }
        setIsOpenUserForm(!isOpenUserForm);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'companyName'){
            setSelectCompany(value);
            const company = companyList.find((company) => company.companyName === value);
            if(!company){
                CustomToaster(false, "Company not found");
            }
            setUser({
                ...user,
                companyID: company.companyID
            });
            return;
        }
        setUser({
            ...user,
            [name]: value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            setLoading(true);
            if (!AreAllFieldsFilled(user)) {
                CustomToaster(false, 'Please fill in all fields');
                setLoading(false);
                return;
            }

            const url = `${process.env.REACT_APP_SERVER_URL}/api/SaveUser`;
            const res = await InsertApi(cookieData, url, user);
            if(res.status == 200){
                if (!isEditing) {
                    CustomToaster(true, 'User added successfully');
                } else {
                    CustomToaster(true, 'User edited successfully');
                }
            }
            handleFetchData();
            setUser(INIT_USER);
            setIsOpenUserForm(false);
            setIsEditing(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            CustomToaster(false, 'Failed to add/edit user');
        }
    }

    const handleEdit = (item) => {
        const companyName = companyList.find((company) => company.companyID === item.companyID);
        if(companyName){
            setSelectCompany(companyName.companyName);
        }
        setUser({
            userId: item.userId,
            firstname: item.firstname,
            surname: item.surname,
            username: item.username,
            email: item.email,
            gender: item.gender == null ? 'Male' : item.gender,
            birthDate: item.birthDate,
            mobilePhone: item.mobilePhone,
            userlevel: item.userLevel,
            companyID: item.companyID,
            isActive: item.isActive,
            defaultDiscount: item.defaultDiscount,
            picture: item.picture,
            isMainAdministrator: item.isMainAdministrator
        });
        setIsOpenUserForm(true);
        setIsEditing(true);
    };

    const handleDelete = async(id) => {
        try {
            const res = await DeleteApi(cookieData, 'api/DeleteUser', id);
            if(res?.data == 1){
                fetchUsers();
                fetchCompany();
                CustomToaster(true, 'User deleted successfully');
            }else {
                CustomToaster(false, 'Failed to delete user');
            }
        } catch (error) {
            console.log(error);
            CustomToaster(false, 'Failed to delete user');
        }
    };
    return (
        <NestedLayout>
            {isOpenUserForm && (
                <CreateUser user={user} companies={companyList} isEditing={isEditing} handleModal={handleUserForm} handleSubmit={handleSubmit} selectCompany={selectCompany} handleUserChange={handleChange} />
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex items-center justify-between space-y-4 md:space-y-2 px-2 py-4 bg-white">
                    <div className="relative">
                        <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <FaSearch className='w-4 h-4 text-gray-500' />
                        </div>
                        <input type="text" onChange={(e => handleSearch(e))} className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded w-80 bg-gray-50 focus:outline-none" placeholder="Search Here" />
                    </div>

                    <button onClick={handleUserForm} className="inline-flex items-center text-white font-semibold bg-primary hover:bg-secondary rounded text-sm px-3 py-1.5" type="button">
                        ADD<FaPlus className='ms-2' />
                    </button>                  
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                User Image
                            </th>
                            <th scope="col" className="px-6 py-3">
                                UserName
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Firstname
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Surname
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3">
                                User Level
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Mobile Number
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Edit
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                                <tr className="bg-white border-b hover:bg-gray-50">
                                    <td className="px-6 py-4" colSpan={8}>
                                        <TableLoader/>
                                    </td>
                                </tr>
                            ) : (
                                filteredData.map((data, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                            <img src={data.picture} className='w-10 h-10 rounded-full object-cover' alt="image" />
                                        </th>
                                        <td className="px-6 py-4">
                                            {data.username}
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.firstname}
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.surname}
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.email}
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.userLevel}
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.mobilePhone}
                                        </td>
                                        <td className="px-6 py-4">
                                            <button type="button" onClick={() => handleEdit(data)}>
                                                <RiPencilFill/>
                                            </button>
                                        </td>
                                        <td className="px-6 py-4">
                                            <button type="button"  onClick={() => handleDelete(data.userId)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={9} className="px-6 py-4">
                                <p className="text-sm text-gray-500">Showing 1 to {userData.length} entries</p>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </NestedLayout>
    )
}
